import * as React from 'react';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import debounce from 'lodash/debounce';

import {
  GenderEnum,
  useSearchAddressesLazyQuery,
  SearchAddressesQuery,
  useGetSpotBySiretLazyQuery,
} from '@graphql';

interface FormFieldProps {
  formik: FormikProps<any>;
}

interface FormFieldsProps {
  activeStep: number;
  formik: FormikProps<any>;
}

interface SignupFormFieldsProps extends FormFieldsProps {
  type: 'consumer' | 'spot' | 'artist';
  activeStep: number;
  formik: FormikProps<any>;
}

interface AddressAutocompleteProps {
  formik: FormikProps<any>;
}

function AddressAutocomplete(props: AddressAutocompleteProps) {
  const { formik } = props;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<SearchAddressesQuery['searchAddresses'] | []>([]);
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation('field');

  const [fetchAddresses, { data }] = useSearchAddressesLazyQuery({
    onCompleted: (data) => {
      setOptions(data.searchAddresses);
      setLoading(false);
    },
  });

  const debouncedFetchAddresses = React.useMemo(
    () =>
      debounce((search: string) => {
        fetchAddresses({ variables: { search } });
      }, 500),
    [fetchAddresses]
  );

  const handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
    if (value.trim().length >= 3) {
      setLoading(true);
      debouncedFetchAddresses(value);
    }
  };

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.label ?? ''}
      options={options}
      loading={loading}
      noOptionsText="commencer à taper pour rechercher une addresse"
      onChange={formik.handleChange}
      value={formik.values.address || ''}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Addresse"
          variant="outlined"
          fullWidth
          name="address"
          onBlur={formik.handleBlur}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address ? String(formik.errors.address) : ''}
          InputProps={{
            ...params.InputProps,
            endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
          }}
        />
      )}
    />
  );
}

const SiretAutocomplete = React.memo((props: AddressAutocompleteProps) => {
  const { formik } = props;
  const { t } = useTranslation('field');

  const [fetchSpot, { loading }] = useGetSpotBySiretLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ getSpotBySiret }) => {
      if (getSpotBySiret.isExists) {
        formik.setErrors({ ...formik.errors, siret: 'Établissemnt déjà enregistré' });
      } else {
        formik.setFieldTouched('accountName', true);
        formik.setFieldTouched('address', true);
        formik.setFieldValue('accountName', getSpotBySiret.name, true);
        formik.setFieldValue('address', getSpotBySiret.address, true);
      }
    },
  });

  React.useEffect(() => {
    if (formik.values.siret.replace(/\s/g, '').length === 14) {
      fetchSpot({ variables: { siret: formik.values.siret } });
    } else {
      formik.setFieldTouched('accountName', false);
      formik.setFieldTouched('address', false);
      formik.setFieldValue('accountName', '', true);
      formik.setFieldValue('address', '', true);
    }
  }, [formik.values.siret]);

  return (
    <TextField
      required
      fullWidth
      type="text"
      id="register-siret"
      name="siret"
      label={t('siret.label')}
      value={formik.values.siret}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched.siret && Boolean(formik.errors.siret)}
      helperText={formik.touched.siret && formik.errors.siret ? String(formik.errors.siret) : null}
      slotProps={{
        input: {
          endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
          maxLength: 14,
        },
      }}
    />
  );
});

const Email = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('email')}
      required
      fullWidth
      type="email"
      id="register-email"
      name="email"
      label={t('email.label')}
      autoComplete="email"
      error={formik.touched.email && Boolean(formik.errors.email)}
      helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : null}
    />
  );
});

const Password = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('password')}
      required
      fullWidth
      type="password"
      id="register-password"
      name="password"
      label={t('password.label')}
      placeholder={t('password.placeholder')}
      autoComplete="new-password"
      error={formik.touched.password && Boolean(formik.errors.password)}
      helperText={formik.touched.password && formik.errors.password ? String(formik.errors.password) : null}
    />
  );
});

const PasswordConfirmation = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('passwordConfirmation')}
      required
      fullWidth
      type="password"
      id="register-passwordConfirmation"
      name="passwordConfirmation"
      label={t('passwordConfirmation.label')}
      placeholder={t('passwordConfirmation.placeholder')}
      autoComplete="new-password"
      error={formik.touched.passwordConfirmation && Boolean(formik.errors.passwordConfirmation)}
      helperText={
        formik.touched.passwordConfirmation && formik.errors.passwordConfirmation
          ? String(formik.errors.passwordConfirmation)
          : undefined
      }
    />
  );
});

export const Firstname = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('firstname')}
      required
      fullWidth
      type="text"
      label={t('firstname.label')}
      id="register-firstname"
      name="firstname"
      autoComplete="given-name"
      error={formik.touched.firstname && Boolean(formik.errors.firstname)}
      helperText={
        formik.touched.firstname && formik.errors.firstname ? String(formik.errors.firstname) : null
      }
    />
  );
});

export const Lastname = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('lastname')}
      required
      fullWidth
      type="text"
      label={t('lastname.label')}
      id="register-lastname"
      name="lastname"
      autoComplete="family-name"
      error={formik.touched.lastname && Boolean(formik.errors.lastname)}
      helperText={formik.touched.lastname && formik.errors.lastname ? String(formik.errors.lastname) : null}
    />
  );
});

export const Gender = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <FormControl required fullWidth variant="outlined">
      <InputLabel id="gender-select-label">{t('gender.label')}</InputLabel>
      <Select
        {...formik.getFieldProps('gender')}
        fullWidth
        labelId="gender-select-label"
        name="gender"
        autoComplete="sex"
        label={t('gender.label')}
      >
        <MenuItem value="">
          <em>{t('word.select', { ns: 'common' })}</em>
        </MenuItem>
        <MenuItem value={GenderEnum.Masculine}>{t('gender.man', { ns: 'common' })}</MenuItem>
        <MenuItem value={GenderEnum.Feminine}>{t('gender.woman', { ns: 'common' })}</MenuItem>
        <MenuItem value={GenderEnum.NonBinary}>{t('gender.nonBinary', { ns: 'common' })}</MenuItem>
        <MenuItem value={GenderEnum.GenderFluid}>{t('gender.genderFluid', { ns: 'common' })}</MenuItem>
        <MenuItem value={GenderEnum.PreferNotToSay}>{t('gender.preferNotToSay', { ns: 'common' })}</MenuItem>
        <MenuItem value={GenderEnum.Other}>{t('gender.other', { ns: 'common' })}</MenuItem>
      </Select>
    </FormControl>
  );
});

export const Birthdate = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <DatePicker
      label={t('birthdate.label')}
      value={moment(formik.values.birthdate)}
      shouldDisableDate={(date) => date.isAfter(moment().subtract(18, 'years').subtract(3, 'days'))}
      onChange={(newBirthdate) => {
        if (newBirthdate) {
          formik.setFieldValue('birthdate', newBirthdate);
          formik.setFieldTouched('birthdate', true);
        }
      }}
      views={['year', 'month', 'day']}
      slotProps={{
        textField: {
          fullWidth: true,
          id: 'register-birthdate',
          name: 'birthdate',
          variant: 'outlined',
          autoComplete: 'bday',
          error: formik.touched.birthdate && Boolean(formik.errors.birthdate),
          helperText:
            formik.touched.birthdate && formik.errors.birthdate ? String(formik.errors.birthdate) : null,
        },
      }}
    />
  );
});

const AccountName = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('accountName')}
      required
      fullWidth
      variant="filled"
      slotProps={{ input: { readOnly: true } }}
      type="text"
      id="register-account-name"
      name="accountName"
      label={t('accountName.label')}
      error={formik.touched.accountName && Boolean(formik.errors.accountName)}
      helperText={
        formik.touched.accountName && formik.errors.accountName
          ? String(formik.errors.accountName)
          : "Tu pourras le changer après l'inscription"
      }
    />
  );
});

const Address = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('address')}
      required
      fullWidth
      variant="filled"
      slotProps={{ input: { readOnly: true } }}
      type="text"
      id="register-address"
      name="address"
      label={t('address.label')}
      error={formik.touched.address && Boolean(formik.errors.address)}
      helperText={formik.touched.address && formik.errors.address ? String(formik.errors.address) : null}
    />
  );
});

const MobilePhone = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('mobilePhone')}
      required
      fullWidth
      type="text"
      id="register-mobile-phone"
      name="mobilePhone"
      label={t('mobilePhone.label')}
      error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
      helperText={
        formik.touched.mobilePhone && formik.errors.mobilePhone ? String(formik.errors.mobilePhone) : null
      }
    />
  );
});

const MasterisedArts = React.memo(({ formik }: FormFieldProps) => {
  const { t } = useTranslation('field');

  return (
    <TextField
      {...formik.getFieldProps('masterisedArts')}
      required
      fullWidth
      type="text"
      id="register-masterisedArts"
      name="masterisedArts"
      label={t('masterisedArts.label')}
      error={formik.touched.masterisedArts && Boolean(formik.errors.masterisedArts)}
      helperText={
        formik.touched.masterisedArts && formik.errors.masterisedArts
          ? String(formik.errors.masterisedArts)
          : null
      }
    />
  );
});

export default function SignupFormFields(props: SignupFormFieldsProps) {
  const { type, activeStep, formik } = props;
  const theme = useTheme();
  const { t } = useTranslation('field');

  return (
    <Stack justifyContent="center" alignItems="center" width="100%" height="100%">
      <Stack spacing={3} width="100%" maxWidth="600px">
        {type === 'consumer' && activeStep === 0 && (
          <Stack spacing={2}>
            <Email formik={formik} />
            <Password formik={formik} />
            <PasswordConfirmation formik={formik} />
          </Stack>
        )}
        {type === 'consumer' && activeStep === 1 && (
          <Stack spacing={2}>
            <Firstname formik={formik} />
            <Lastname formik={formik} />
            <Gender formik={formik} />
            <Birthdate formik={formik} />
          </Stack>
        )}
        {type === 'consumer' && activeStep === 2 && (
          <Stack
            spacing={2}
            width="100%"
            divider={<Divider sx={{ backgroundColor: theme.palette.grey[800] }} />}
          >
            <Alert icon={false} severity={formik.isValid ? 'success' : 'error'}>
              {formik.isValid ? 'Vérifie tes informations' : 'Un ou plusieurs champs sont à revérifier'}
            </Alert>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.email ? 'error' : 'inherit'}>
                {t(`email.label`)}
              </Typography>
              <Typography variant="body1" color={formik.errors.email ? 'error' : 'inherit'}>
                {formik.values.email}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t(`password.label`)}</Typography>
              <Typography variant="body1">{'•'.repeat(formik.values.password.length)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t(`firstname.label`)}</Typography>
              <Typography variant="body1">{formik.values.firstname}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t(`lastname.label`)}</Typography>
              <Typography variant="body1">{formik.values.lastname}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t(`gender.label`)}</Typography>
              <Typography variant="body1">
                {t(`gender.${formik.values.gender}`, { ns: 'common' })?.toLowerCase()}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">{t(`birthdate.label`)}</Typography>
              <Typography variant="body1">{moment(formik.values.birthdate).format('L')}</Typography>
            </Stack>
          </Stack>
        )}
        {type === 'spot' && activeStep === 0 && (
          <Stack spacing={2}>
            <Email formik={formik} />
            <Password formik={formik} />
            <PasswordConfirmation formik={formik} />
          </Stack>
        )}
        {type === 'spot' && activeStep === 1 && (
          <Stack spacing={2}>
            <Firstname formik={formik} />
            <Lastname formik={formik} />
            <MobilePhone formik={formik} />
            <Gender formik={formik} />
            <Birthdate formik={formik} />
          </Stack>
        )}
        {type === 'spot' && activeStep === 2 && (
          <Stack spacing={2}>
            <SiretAutocomplete formik={formik} />
            <AccountName formik={formik} />
            <Address formik={formik} />
          </Stack>
        )}
        {type === 'spot' && activeStep === 3 && (
          <Stack
            spacing={1}
            width="100%"
            divider={<Divider sx={{ backgroundColor: theme.palette.grey[800] }} />}
          >
            <Alert icon={false} severity={formik.isValid ? 'success' : 'error'}>
              {formik.isValid ? 'Vérifie tes informations' : 'Un ou plusieurs champs sont à revérifier'}
            </Alert>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.siret ? 'error' : 'inherit'}>
                SIRET
              </Typography>
              <Typography variant="body1" color={formik.errors.siret ? 'error' : 'inherit'}>
                {formik.values.siret}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.accountName ? 'error' : 'inherit'}>
                Nom
              </Typography>
              <Typography variant="body1" color={formik.errors.accountName ? 'error' : 'inherit'}>
                {formik.values.accountName}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.address ? 'error' : 'inherit'}>
                Addresse
              </Typography>
              <Typography variant="body1" color={formik.errors.address ? 'error' : 'inherit'}>
                {formik.values.address}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.email ? 'error' : 'inherit'}>
                Email
              </Typography>
              <Typography variant="body1" color={formik.errors.email ? 'error' : 'inherit'}>
                {formik.values.email}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1">Mot de passe</Typography>
              <Typography variant="body1">{'•'.repeat(formik.values.password.length)}</Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.firstname ? 'error' : 'inherit'}>
                Prénom
              </Typography>
              <Typography variant="body1" color={formik.errors.firstname ? 'error' : 'inherit'}>
                {formik.values.firstname}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.lastname ? 'error' : 'inherit'}>
                Nom de famille
              </Typography>
              <Typography variant="body1" color={formik.errors.lastname ? 'error' : 'inherit'}>
                {formik.values.lastname}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.mobilePhone ? 'error' : 'inherit'}>
                Téléphone portable
              </Typography>
              <Typography variant="body1" color={formik.errors.mobilePhone ? 'error' : 'inherit'}>
                {formik.values.mobilePhone}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.gender ? 'error' : 'inherit'}>
                Genre
              </Typography>
              <Typography variant="body1" color={formik.errors.gender ? 'error' : 'inherit'}>
                {formik.values.gender}
              </Typography>
            </Stack>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body1" color={formik.errors.birthdate ? 'error' : 'inherit'}>
                Date de naissance
              </Typography>
              <Typography variant="body1" color={formik.errors.birthdate ? 'error' : 'inherit'}>
                {moment(formik.values.birthdate).format('YYYY/MM/DD')}
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
