import { Moment } from 'moment';

import {
  // QUERIES
  GetConsumerDetailedQuery,
  ListHomeMakersQuery,
  GetSessionQuery,
  Maker,
} from '@graphql';

import consumerReducer from './consumerReducer';
import conversationReducer from './conversationReducer';
import eventFormReducer from './eventFormReducer';
import userReducer from './userReducer';

export interface EventForm {
  id?: string;
  title?: string | null;
  description?: string | null;
  from?: Date | Moment | null;
  to?: Date | Moment | null;
  cover?: string | null;
  category?: {
    id: string;
    name: string;
  } | null;
};

export interface State {
  consumer: GetConsumerDetailedQuery['consumer'] | null;
  maker: ListHomeMakersQuery['homeMakers'][0]['makers'][0] | null;
  makers: ListHomeMakersQuery['homeMakers'] | [];
  eventForm: EventForm | null | undefined;
  user: GetSessionQuery['session'] | null;
  conversationId: string | null;
  isOpenDialog: boolean;
}

export type AppContextType = {
  state: State;
  dispatch: React.Dispatch<Action>;
};

export const initialState: State = {
  // CONSUMER
  consumer: null,
  // EVENT FORM
  eventForm: undefined,
  // MAKER
  maker: null,
  makers: [],
  // USER
  user: null,
  // CONVERSATION
  conversationId: null,
  isOpenDialog: false,
};

export type Action =
  // DIALOG
  | { type: 'OPEN_DIALOG'; }
  | { type: 'CLOSE_DIALOG'; }
  // CONSUMER
  | { type: 'SET_CONSUMER'; payload: GetConsumerDetailedQuery['consumer'] }
  | { type: 'UNSET_CONSUMER' }
  | {
      type: 'ADD_FRIEND';
      payload: {
        userAccountId: string;
        friendAccountId: string;
        avatar: string;
        name: string;
        slug: string;
      };
    }
  | { type: 'CANCEL_FRIEND'; payload: { accountId: string } }
  | { type: 'ACCEPT_FRIEND'; payload: { accountId: string } }
  | { type: 'REJECT_FRIEND'; payload: { accountId: string } }
  | { type: 'REMOVE_FRIEND'; payload: { accountId: string } }
  // EVENT FORM
  | { type: 'SET_EVENT_FORM'; payload: EventForm | null }
  | { type: 'UNSET_EVENT_FORM' }
  // MAKER
  | { type: 'SET_MAKER'; payload: Maker }
  | { type: 'SET_MAKERS'; payload: ListHomeMakersQuery['homeMakers'] }
  // | { type: 'FOLLOW_MAKER' }
  // | { type: 'UNFOLLOW_MAKER' }
  | { type: 'UNSET_MAKER' }
  // USER
  | { type: 'SET_USER'; payload: GetSessionQuery['session'] }
  | { type: 'UNSET_USER' }
  | { type: 'SET_CURRENT_USER_ACCOUNT'; payload: { accountId: string } }
  // CONVERSATION
  | { type: 'SET_CONVERSATION'; payload: { conversationId: string } }
  | { type: 'UNSET_CONVERSATION' };

export const reducer = (state: State, action: Action) => {
  switch (action.type) {
    // DIALOG
    case 'OPEN_DIALOG': {
      return {
        ...state,
        isOpenDialog: true,
      };
    }
    case 'CLOSE_DIALOG': {
      return {
        ...state,
        isOpenDialog: false,
      };
    }
    // CONSUMER
    case 'SET_CONSUMER':
    case 'ADD_FRIEND':
    case 'CANCEL_FRIEND':
    case 'ACCEPT_FRIEND':
    case 'REJECT_FRIEND':
    case 'REMOVE_FRIEND':
    case 'UNSET_CONSUMER':
      return consumerReducer(state, action);
    // CONVERSATION
    case 'SET_CONVERSATION':
    case 'UNSET_CONVERSATION':
      return conversationReducer(state, action);
    // EVENT FORM
    case 'SET_EVENT_FORM':
    case 'UNSET_EVENT_FORM':
      return eventFormReducer(state, action);
    // USER
    case 'SET_USER':
    case 'SET_CURRENT_USER_ACCOUNT':
    case 'UNSET_USER':
      return userReducer(state, action);
    default:
      return state;
  }
};
