import * as yup from "yup";

import { useTranslation } from "react-i18next";

export function useValidation() {
  const { t } = useTranslation("field");

  const validationSchema = yup.object({
    email: yup.string().email().required().label(t("email.validation.label")),
    password: yup.string().min(8).required().label(t("password.validation.label")),
  });

  return validationSchema;
}
