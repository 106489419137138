import { TImageType, TImageSubType, TImageSizeType } from "types";
import { fakerFR as faker } from "@faker-js/faker";

type TGetImage = {
  ressource: string | null;
  id: string;
  type: TImageType;
  subType: TImageSubType;
  size?: TImageSizeType;
};

export const buildImageUri = ({ ressource, id, type, subType, size = "xl" }: TGetImage) => {
  if (!ressource) {
    return faker.image.urlPicsumPhotos({
      width: 900,
      height: 500,
    });
  } else {
    return ressource;
  }
};

export const reBuildImageUri = (uri: string, size: TImageSizeType) => {
  const sizeRegex = /\/(xs|sm|md|xl)\//;
  return uri.replace(sizeRegex, `/${size}/`);
};

export const getImageId = (uri: string) => {
  const match = uri.match(/\/(?<id>[^/]+)\.jpg$/);
  return match?.groups?.id || null;
};
