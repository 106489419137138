import * as React from 'react';
import Stack from '@mui/material/Stack';

import { GetConsumerDetailedQuery } from '@graphql';
import IDCard from './IDCard';
import Stats from './stats';

interface SpotPreviewProps {
  consumer: GetConsumerDetailedQuery['consumer'];
}

function SpotPreview(props: SpotPreviewProps) {
  const { id, description } = props.consumer;

  return (
    <Stack spacing={5} p={3}>
      <IDCard />
      <Stats />
    </Stack>
  );
}

export default React.memo(SpotPreview);
