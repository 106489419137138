import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { useUpdateUserMutation } from '@graphql';
import { useToastError } from '@hooks';

export function Gender() {
  const theme = useTheme();
  const { user, accounts, refetch } = useSession();
  const [account] = accounts;
  const { t } = useTranslation('common');
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();

  if (!user) {
    return null;
  }

  const [updateUser] = useUpdateUserMutation();

  const formik = useFormik({
    initialValues: { gender: user.gender },
    validationSchema: yup.object({
      gender: yup.string(),
    }),
    onSubmit: (values) => {
      updateUser({
        variables: {
          input: values,
        },
        onCompleted() {
          formik.resetForm({ values: { gender: values.gender } });
          refetch({ fetchPolicy: 'network-only' });
          setIsEditing(false);
        },
        onError: toastError,
      });
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography fontWeight="bold">{t('gender.label', { ns: 'field' })}</Typography>
          <Stack spacing={1} direction="row">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel', { ns: 'cta' })}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate', { ns: 'cta' })}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit', { ns: 'cta' })}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack>
          {isEditing ? (
            <CssFormControl fullWidth size="small">
              <Select
                id="gender"
                name="gender"
                variant="filled"
                value={formik.values.gender}
                label="Langue"
                onChange={formik.handleChange}
                color="primary"
                MenuProps={{
                  elevation: 5,
                  sx: {
                    '& .MuiMenu-list': {
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.background.default,
                    },
                    '& .MuiMenu-list li:hover': {
                      color: 'white',
                      backgroundColor: alpha(theme.palette.text.secondary, 0.3),
                    },
                    '&& .Mui-selected': {
                      color: theme.palette.text.primary,
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    },
                    '&& .Mui-selected:hover': {
                      color: theme.palette.text.primary,
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    },
                  },
                }}
              >
                <MenuItem value={'man'}>{t(`word.man`, { ns: 'common' })}</MenuItem>
                <MenuItem value={'woman'}>{t(`word.woman`, { ns: 'common' })}</MenuItem>
              </Select>
            </CssFormControl>
          ) : (
            <Typography color="text.secondary">{t(`word.${user.gender}`)}</Typography>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

const CssFormControl = styled(FormControl)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.default, // Couleur au survol
  '& .MuiInput-underline:after': {
    borderBottomColor: theme.palette.primary.main, // Changer la couleur du bord blanc
  },
  '& .MuiFilledInput-underline:after': {
    borderBottomColor: theme.palette.primary.main,
  },
  '& .MuiFilledInput-root': {
    backgroundColor: 'black', // Changer la couleur du fond
    '&:hover': {
      backgroundColor: theme.palette.background.default, // Couleur au survol
    },
    '&.Mui-focused': {
      backgroundColor: theme.palette.background.default, // Couleur quand focus
    },
  },
}));
