import * as React from 'react';
import { useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

interface AddAccountProps {
  // handleOpenDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

export default function NotificationSettings(props: AddAccountProps) {
  // const { handleOpenDialog } = props;
  const theme = useTheme();
  const { t } = useTranslation('account');

  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <theme.icons.addPerson fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('menu.add account')} />
      </ListItemButton>
    </ListItem>
  );
}
