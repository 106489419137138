import * as React from 'react';
import { useTheme } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import moment from 'moment';

import { useGetAccountCountsQuery, GetAccountCountsQuery } from '@graphql';
import Doughnut from './Doughnut';
import CountSlots from './CountSlots';
import EmptyState from './EmptyState';

const SPACING = 2;

export function AccountTypeCounts() {
  const [dateRange, setDateRange] = React.useState<{ from: Date; to: Date } | null>(null);
  const [counts, setCounts] = React.useState<GetAccountCountsQuery['accountCounts'] | null>(null);
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();

  const thisDayDateRante = {
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
  };

  const thisWeekDateRante = {
    from: moment().startOf('week').toDate(),
    to: moment().endOf('week').toDate(),
  };

  const thisMonthDateRante = {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
  };

  const thisQuerterDateRante = {
    from: moment().startOf('quarter').toDate(),
    to: moment().endOf('quarter').toDate(),
  };

  const thisYearDateRante = {
    from: moment().startOf('year').toDate(),
    to: moment().endOf('year').toDate(),
  };

  const dateRanges = [
    thisDayDateRante,
    thisWeekDateRante,
    thisMonthDateRante,
    thisQuerterDateRante,
    thisYearDateRante,
    null,
  ];

  const consumerColor = theme.palette.secondary.main;
  const spotColor = theme.palette.primary.main;
  const artistColor = theme.palette.error.main;

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  useGetAccountCountsQuery({
    variables: { dateRange },
    onCompleted({ accountCounts }) {
      setCounts(accountCounts);
    },
  });

  React.useEffect(() => {
    setDateRange(dateRanges[tab]);
  }, [tab]);

  React.useEffect(() => {
    const isNoCurrentCounts = !counts?.current.spot && !counts?.current.consumer && !counts?.current.artist;
    setIsEmpty(!counts?.current || isNoCurrentCounts);
  }, [counts]);

  return (
    <Stack
      width="100%"
      p={SPACING}
      spacing={SPACING}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <Stack
        spacing={SPACING}
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold">Comptes</Typography>
        {isEmpty ? (
          <EmptyState />
        ) : (
          <Stack maxWidth="20%">
            <Doughnut counts={counts} />
          </Stack>
        )}
      </Stack>

      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={tab}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="inherit"
            variant="scrollable"
          >
            <Tab label="Jour" />
            <Tab label="Semaine" />
            <Tab label="Mois" />
            <Tab label="Trimestre" />
            <Tab label="Année" />
            <Tab label="Toujours" />
          </Tabs>
        </Box>
      </Box>

      <Stack
        width="100%"
        direction="row"
        divider={
          <Divider flexItem orientation="vertical" sx={{ backgroundColor: theme.palette.grey[800] }} />
        }
      >
        <CountSlots
          title="Consumer"
          count={{ current: counts?.current.consumer, previous: counts?.previous?.consumer }}
          color={consumerColor}
        />
        <CountSlots
          title="Spot"
          count={{ current: counts?.current.spot, previous: counts?.previous?.spot }}
          color={spotColor}
        />
        <CountSlots
          title="Artist"
          count={{ current: counts?.current.artist, previous: counts?.previous?.artist }}
          color={artistColor}
        />
      </Stack>
    </Stack>
  );
}
