import { alpha, Theme } from '@mui/material';

const useStyles = (theme: Theme) => ({
  tabs: {
    indicator: {
      color: 'white',
      height: '4px',
    },
  },
  tab: {
    minWidth: 100,
    borderBottom: '3px solid #343434',
    '&.Mui-selected': {
      color: 'white',
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },
  },
});

export default useStyles;
