import React from "react";
import { useRouteError } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import { useToastError } from '@hooks';

export default function ErrorBoundary() {
  const theme = useTheme();
  const navigate = useNavigate();
  let error = useRouteError();
  const toastError = useToastError();

  const styles = {
    paper: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: theme.borderRadius,
      boxShadow: theme.boxShadow,
    },
  };

  React.useEffect(() => {
    if (error) {
      toastError(error);
    }
    return () => {
      error = null;
    };
  }, [error]);

  function CountdownTimer() {
    const [seconds, setSeconds] = React.useState(5);

    React.useEffect(() => {
      if (seconds > 0) {
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      } else {
        navigate('/', { replace: true });
      }
    }, [seconds]);

    return <div>{seconds > 1 ? <span>{seconds} secondes</span> : <span>redirection... </span>}</div>;
  }

  return (
    <Stack height="100%" alignItems={'center'} justifyContent={'center'}>
      <Paper sx={styles.paper} elevation={theme.elevation}>
        <Typography color="primary" variant="h3">
          OOps
        </Typography>
        <Typography variant="h6">Impossibe de charger les évènements</Typography>
        <Divider variant="middle" />
        <Typography mt={3} variant="body2">
          Tu vas être redirigé vers la page d'accueil dans
        </Typography>
        <Typography variant="body2">{CountdownTimer()}</Typography>
      </Paper>
    </Stack>
  );
}
