import * as React from 'react';
import { useTheme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GetEventDetailedQuery } from '@graphql';

interface TicketsProps {
  tickets: GetEventDetailedQuery['event']['tickets'];
  ticketsRef: React.RefObject<HTMLDivElement>;
}

export default function Tickets(props: TicketsProps) {
  const { tickets, ticketsRef } = props;
  const theme = useTheme();

  // http://festayre-local/spots?type=event&q=01737211-9d31-4339-b3d3-e619210b78d1
  if (!tickets.length) {
    return null;
  }

  return (
    <Stack spacing={1} ref={ticketsRef}>
      <Typography variant="h6">Tickets</Typography>
      {tickets.map((ticket) => (
        <Stack
          key={ticket.id}
          p={3}
          spacing={1}
          sx={{
            backgroundColor: alpha('#000000', 0.5),
            borderRadius: theme.borderRadius,
            border: '1px dashed grey',
          }}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body1" fontWeight="bold" color="textSecondary">
              {ticket.label}
            </Typography>
            <Stack px={1} sx={{ backgroundColor: 'white', borderRadius: theme.borderRadius }}>
              <Typography variant="body1" fontWeight="bold" color="primary">
                {ticket.price}€
              </Typography>
            </Stack>
          </Stack>
          <Typography variant="body2 " color="textSecondary">
            {ticket.description}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
}
