import { State, Action } from '..';

const consumerReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CONSUMER':
      return {
        ...state,
        consumer: { ...action.payload },
      };

    case 'ADD_FRIEND': {
      if (!state.user?.accounts) {
        return state;
      }

      const { userAccountId, friendAccountId, avatar, name, slug } = action.payload;
      const newAccounts = state.user?.accounts.map((account) => {
        if (account.id === userAccountId && account.__typename === 'Consumer') {
          return {
            ...account,
            friends: {
              ...account.friends,
              sent: account.friends.sent.concat([
                {
                  __typename: 'Consumer',
                  id: friendAccountId,
                  name,
                  slug,
                  avatar,
                },
              ]),
            },
          };
        }
        return account;
      });

      return {
        ...state,
        user: {
          ...state.user,
          accounts: newAccounts,
        },
      };
    }

    case 'CANCEL_FRIEND': {
      if (!state.user?.accounts) {
        return state;
      }

      const { accountId } = action.payload;
      const userAccountId = state.user?.accounts?.[0].id;

      return {
        ...state,
        user: {
          ...state.user,
          accounts: state.user?.accounts.map((account) => {
            if (account.id === userAccountId && account.__typename === 'Consumer') {
              return {
                ...account,
                friends: {
                  ...account.friends,
                  sent: account.friends.sent.filter(({ id }) => id !== accountId),
                },
              };
            }
            return account;
          }),
        },
      };
    }

    case 'ACCEPT_FRIEND': {
      const { accountId } = action.payload;

      if (!state.consumer) {
        return state;
      }

      // Find the friend to accept
      const friendToAccept = state.consumer.friends.pending.find(({ id }) => id === accountId);

      if (!friendToAccept) {
        return state; // If the friend isn't found, return the current state
      }

      // Move the friend from pending to accepted
      const updatedPending = state.consumer.friends.pending.filter(({ id }) => id !== accountId);
      const updatedAccepted = [...state.consumer.friends.accepted, friendToAccept];

      return {
        ...state,
        consumer: {
          ...state.consumer,
          friends: {
            ...state.consumer.friends,
            accepted: updatedAccepted,
            pending: updatedPending,
          },
        },
      };
    }

    case 'REJECT_FRIEND': {
      const { accountId } = action.payload;

      if (!state.consumer) {
        return state;
      }

      return {
        ...state,
        consumer: {
          ...state.consumer,
          friends: {
            ...state.consumer.friends,
            pending: state.consumer.friends.pending.filter(({ id }) => id !== accountId),
          },
        },
      };
    }

    case 'REMOVE_FRIEND': {
      if (!state.user?.accounts) {
        return state;
      }

      const { accountId } = action.payload;
      const userAccountId = state.user?.accounts?.[0].id;

      return {
        ...state,
        user: {
          ...state.user,
          accounts: state.user?.accounts.map((account) => {
            if (account.id === userAccountId && account.__typename === 'Consumer') {
              return {
                ...account,
                friends: {
                  ...account.friends,
                  accepted: account.friends.sent.filter(({ id }) => id !== accountId),
                },
              };
            }
            return account;
          }),
        },
      };
    }

    case 'UNSET_CONSUMER':
      return { ...state, consumer: null };

    default:
      return state;
  }
};

export default consumerReducer;
