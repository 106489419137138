import { truncateText } from '@utils';
import {
  MyEventsQuery,
  EventStatusEnum,
  ListMyConsumerBookingsQuery,
  ListMySpotBookingsQuery,
  BookingStatusEnum,
} from '@graphql';

type Item = ListMyConsumerBookingsQuery['myBookings'][0]
  | ListMySpotBookingsQuery['myBookings'][0]
  | MyEventsQuery['myEvents'][0];

export function getEventBgColor(status: EventStatusEnum) {
  switch (status) {
    case EventStatusEnum.Published:
      return 'green';
    case EventStatusEnum.Postponed:
      return 'orange';
    case EventStatusEnum.Removed:
    case EventStatusEnum.Deleted:
    case EventStatusEnum.Canceled:
      return 'red';
    default:
      return 'grey';
  }
}

export function getBookingBgColor(status: BookingStatusEnum) {
  switch (status) {
    case BookingStatusEnum.Pending:
      return 'orange';
    case BookingStatusEnum.Accepted:
      return 'green';
    case BookingStatusEnum.Rejected:
      return 'red';
    case BookingStatusEnum.Canceled:
      return 'red';
    default:
      return 'grey';
  }
}

export function getItemStart(item: Item) {
  switch (item.__typename) {
    case 'Event':
      return new Date(item.from).toISOString();
    case 'Booking':
      return new Date(item.startTime).toISOString();
    default:
      return '';
  }
}

export function getItemColor(item: Item) {
  switch (item.__typename) {
    case 'Event':
      return getEventBgColor(item.status);
    case 'Booking':
      return getBookingBgColor(item.status);
    default:
      return '';
  }
}

export function getItemTitle(item: Item) {
  switch (item.__typename) {
    case 'Event':
      return `${truncateText(item.title, 10)}`;
    case 'Booking': {
      let clientName = '';

      if (item.client.__typename === 'BookingClient') {
        clientName = item.client.firstname;

        if (item.client.lastname) {
          clientName += ` ${item.client.lastname}`;
        }
      } else if (item.client.__typename === 'Consumer') {
        clientName = item.client.user.firstname + ' ' + item.client.user.lastname;
      }

      return `${truncateText(clientName as string, 10)} x${item.participants}p`;
    }
    default:
      return '';
  }
}
