import * as React from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import { styled, useTheme, alpha } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import {
  DataGrid,
  GridColDef,
  GridRowModesModel,
  GridActionsCellItem,
  GridEventListener,
  GridRowEditStopReasons,
  gridClasses,
  GridValueOptionsParams,
  GridRowModes,
} from '@mui/x-data-grid';
import moment from 'moment';
import { frFR, esES } from '@mui/x-data-grid/locales';

import {
  EventStatusEnum,
  MyEventsQuery,
  useMyEventsQuery,
  useUpdateEventStatusMutation,
  DateRangeInput,
} from '@graphql';
import { useAppContext } from '@context';
import WeekPicker from '../week-picker';
import StripedDataGrid from '../StripedDataGrid';

export default function EventDataGrid() {
  const theme = useTheme();
  const { state, dispatch } = useAppContext();
  const [dateRange, setDateRange] = React.useState<DateRangeInput | null>(null);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
  const { t, i18n } = useTranslation('cta');

  const { data, loading } = useMyEventsQuery({
    fetchPolicy: 'cache-first',
  });

  const handleDateRangeChange = (dateRange: { from: string; to: string }) => {
    setDateRange(dateRange);
  };

  const rows = (data?.myEvents || [])
    // .filter((event) => {
    //   switch (tab) {
    //     case 'upcoming':
    //       return event.status === EventStatusEnum.Published && moment(event.from).isAfter(moment());
    //     case 'passed':
    //       return event.status === EventStatusEnum.Published && moment(event.to).isBefore(moment());
    //     case 'draft':
    //       return event.status === EventStatusEnum.Draft;
    //     case 'trash':
    //       return event.status === EventStatusEnum.Deleted;
    //     default:
    //       return false;
    //   }
    // })
    .map((event) => ({
      id: event.id,
      title: event.title,
      day: event.from,
      date: event.from,
      to: event.to,
      status: event.status,
      likes: event.likers.count,
    }));

  const [updateEventStatus] = useUpdateEventStatusMutation({
    onCompleted: ({ updateEventStatus }) => {
      toast(t(`event.toast.status.success.${updateEventStatus.status}`, { ns: 'field' }), {
        type: 'success',
      });
    },
    onError() {
      toast(t('event.toast.status.error', { ns: 'field' }), { type: 'error' });
    },
  });

  const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (event: Partial<MyEventsQuery['myEvents'][0]> & { date?: string }) => () => {
    dispatch({
      type: 'SET_EVENT_FORM',
      payload: {
        id: event.id,
        title: event.title,
        description: event.description,
        from: moment(event.date),
        to: moment(event.to),
        cover: null,
      },
    });
  };

  const handleActionClick = (id: string, status: EventStatusEnum) => () => {
    updateEventStatus({ variables: { id, status } });
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const getLocale = () => {
    switch (i18n.language) {
      case 'fr':
        return frFR.components.MuiDataGrid.defaultProps.localeText;
      case 'es':
        return esES.components.MuiDataGrid.defaultProps.localeText;
      default:
        return undefined; // Par défaut, le DataGrid utilisera l'anglais
    }
  };

  const PublishAction = (id: string) => (
    <Tooltip title={t('publish')}>
      <GridActionsCellItem
        icon={<theme.icons.publish />}
        label="Publish"
        className="textPrimary"
        onClick={handleActionClick(id, EventStatusEnum.Published)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.success.main,
          },
        }}
      />
    </Tooltip>
  );
  const EditAction = (event: Partial<MyEventsQuery['myEvents'][0]>) => (
    <Tooltip title={t('edit')}>
      <GridActionsCellItem
        icon={<theme.icons.edit />}
        label="Edit"
        className="textPrimary"
        onClick={handleEditClick(event)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: 'white',
          },
        }}
      />
    </Tooltip>
  );
  const DeleteAction = (id: string) => (
    <Tooltip title={t('delete')}>
      <GridActionsCellItem
        icon={<theme.icons.delete />}
        label="Delete"
        className="textPrimary"
        onClick={handleActionClick(id, EventStatusEnum.Deleted)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.error.main,
          },
        }}
      />
    </Tooltip>
  );
  const DeleteForeverAction = (id: string) => (
    <Tooltip title={t('remove forever')}>
      <GridActionsCellItem
        icon={<theme.icons.deleteForever />}
        label="Remove"
        className="textPrimary"
        onClick={handleActionClick(id, EventStatusEnum.Removed)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.error.main,
          },
        }}
      />
    </Tooltip>
  );
  const RestoreAction = (id: string) => (
    <Tooltip title={t('restore')}>
      <GridActionsCellItem
        icon={<theme.icons.restore />}
        label="Restore"
        className="textPrimary"
        onClick={handleActionClick(id, EventStatusEnum.Draft)}
        color="inherit"
        sx={{
          color: theme.palette.text.secondary,
          '&:hover': {
            color: theme.palette.info.main,
          },
        }}
      />
    </Tooltip>
  );

  const columns: GridColDef[] = [
    { field: 'id', headerClassName: 'f6r-app-theme--header', headerName: 'ID', width: 90, hideable: true },
    {
      field: 'title',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Title', { ns: 'event' }),
      width: 300,
      flex: 1,
      editable: true,
    },
    {
      field: 'date',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Date', { ns: 'event' }),
      width: 200,
      type: 'date',
      editable: false,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${moment(value).format('DD MMMM YYYY')}`;
      },
    },
    {
      field: 'day',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.Day', { ns: 'event' }),
      width: 100,
      type: 'string',
      editable: false,
      filterable: false,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${moment(value).format('dddd')}`;
      },
    },
    {
      field: 'to',
      headerClassName: 'f6r-app-theme--header',
      headerName: t('dataGrid.At', { ns: 'event' }),
      width: 110,
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      filterable: false,
      valueFormatter: (value?: number) => {
        if (value == null) {
          return '';
        }
        return `${moment(value).format('hh:mm')}`;
      },
    },
    {
      field: 'likes',
      headerClassName: 'f6r-app-theme--header',
      headerName: 'Likes',
      width: 110,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      editable: false,
      filterable: false,
    },
    {
      field: 'actions',
      type: 'actions',
      headerClassName: 'f6r-app-theme--header-actions',
      headerName: t('dataGrid.Actions', { ns: 'event' }),
      width: 150,
      editable: false,
      sortable: false,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      cellClassName: 'actions',
      getActions: ({ id, row }) => {
        const { status } = row;

        switch (status) {
          case EventStatusEnum.Published:
          case EventStatusEnum.Postponed:
            return [EditAction(row)];
          case EventStatusEnum.Draft:
            return [PublishAction(row.id), EditAction(row), DeleteAction(row.id)];
          case EventStatusEnum.Removed:
          case EventStatusEnum.Deleted:
            return [RestoreAction(row.id), DeleteForeverAction(row.id)];
          case EventStatusEnum.Canceled:
            return [];
          default:
            return [];
        }
      },
    },
  ];

  return (
    <Stack spacing={3}>
      <WeekPicker handleDateRangeChange={handleDateRangeChange} />
      <Paper elevation={10} sx={{ height: '100%', width: '100%' }}>
        <StripedDataGrid
          loading={loading}
          rows={rows}
          columns={columns}
          getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          checkboxSelection
          disableRowSelectionOnClick
          localeText={getLocale()}
          autoHeight
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: theme.palette.grey[700],
          }}
        />
      </Paper>
    </Stack>
  );
}
