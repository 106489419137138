import Stack from '@mui/material/Stack';

export function SupportPage({ page }: { page?: string }) {
  return (
    <Stack>
      <h1>Support</h1>
      <p>Support page</p>
    </Stack>
  );
}
