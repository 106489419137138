import * as yup from 'yup';
import moment from 'moment';
import { TFunction } from 'i18next';

import { GenderEnum } from '@graphql';

export default function getValidationSchema(t: TFunction) {
  return yup.object({
    firstname: yup.string().min(2).max(255).required().label('Prénom'),
    lastname: yup.string().min(2).max(255).required().label('Nom de famille'),
    gender: yup.string().oneOf(Object.values(GenderEnum)).required(),
    birthdate: yup
      .date()
      .min(moment().subtract(130, 'years').toDate())
      .max(moment().subtract(10, 'years').toDate())
      .required('Birthdate is required')
      .label('Date de naissance'),
  })
}