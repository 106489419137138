import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { SpotConfiguration as SpotConfigurationTypes, Spot } from '@graphql';
import CloseSpaces from './CloseSpaces';
import Capacity from './Capacity';
import AverageTicket from './AverageTicket';

interface SpotConfigurationProps {
  handleSwiperTouchMove: (isActive: boolean) => void;
}

export type SpotConfigurationValues = Omit<SpotConfigurationTypes, '__typename' | 'id' | 'updatedAt'>;

export function SpotConfiguration(props: SpotConfigurationProps) {
  const { handleSwiperTouchMove } = props;
  const { account } = useSession();
  const { t } = useTranslation('cta');

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('spotConfiguration.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotConfiguration.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>

      <Stack
        spacing={5}
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        <CloseSpaces handleSwiperTouchMove={handleSwiperTouchMove} account={account as Spot} />
        <Capacity handleSwiperTouchMove={handleSwiperTouchMove} account={account as Spot} />
        <AverageTicket handleSwiperTouchMove={handleSwiperTouchMove} account={account as Spot} />
      </Stack>
    </Stack>
  );
}