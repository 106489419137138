import * as Device from 'react-device-detect';

import { GetSpotDetailedQuery } from '@graphql';
import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import TabletHeader from './TabletHeader';

interface HeaderProps {
  covers: GetSpotDetailedQuery['spot']['covers'];
  defaultImage: GetSpotDetailedQuery['spot']['configuration']['defaultImage'];
  handleTabChange: Function;
  handleBack: (isBack: boolean | undefined) => void;
  activeTab: number;
}

function Header(props: HeaderProps) {
  if (Device.isMobileOnly) {
    return <MobileHeader {...props} />;
  } else if (Device.isTablet) {
    return <TabletHeader {...props} />;
  } else {
    return <DesktopHeader {...props} />;
  }
}

export default Header;
