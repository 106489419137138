import moment, { Moment } from 'moment';

import { EventForm } from '@context';

const getMinimalFromDate = (from?: Moment | Date | null) => {
  const minimalFrom = moment().set({
    hour: moment().add(1, 'hour').hour(),
    minute: 0,
    seconds: 0,
    milliseconds: 0,
  });
  return !from ? minimalFrom : moment(from).isBefore() ? minimalFrom : moment(from);
};

export default function initValues(event?: EventForm | null) {
  const from = getMinimalFromDate();
  const to = moment(from).add(1, 'hour');

  return {
    title: event?.title ?? '',
    description: event?.description ?? null,
    from: event?.from ? moment(event?.from) : from,
    to: event?.to ? moment(event?.to) : to,
    cover: null,
    images: [],
  };
}
