import * as React from 'react';
import DialogContent from '@mui/material/DialogContent';
import _ from 'lodash';

import { useSession, useToastError } from '@hooks';
import {
  useGetConversationQuery,
  useOnMessageSentSubscription,
  useResetUnreadCountMutation,
  GetConversationsQuery,
  GetConversationsDocument,
  GetConversationQuery,
} from '@graphql';
import { Message } from './Message';

export function Conversation(props: { conversationId: string }) {
  const { conversationId } = props;
  const { user } = useSession();
  const toastError = useToastError();
  const messagesContainerRef = React.useRef<HTMLDivElement>(null);
  const [conversation, setConversation] = React.useState<GetConversationQuery['conversation'] | null>(null);

  const [resetUnreadCount] = useResetUnreadCountMutation({
    variables: { conversationId },
    onError: toastError,
  });

  const { updateQuery, client } = useGetConversationQuery({
    variables: { conversationId },
    onCompleted: ({ conversation }) => {
      if (conversation) {
        setConversation({
          ...conversation,
          messages: _.orderBy(conversation.messages, ['createdAt'], ['desc']),
        });

        resetUnreadCount({
          onCompleted: () => {
            client.cache.updateQuery({ query: GetConversationsDocument }, (cachedData) => {
              if (!cachedData) return cachedData;

              return {
                conversations: cachedData.conversations.map(
                  (conversation: GetConversationsQuery['conversations'][0]) =>
                    conversation.id === conversationId ? { ...conversation, unreadCount: 0 } : conversation
                ),
              };
            });
          },
        });
      }
    },
  });

  useOnMessageSentSubscription({
    variables: { conversationId },
    onError: toastError,
    onData: (data) => {
      if (!data.data.data?.messageSent) return;

      const newMessage = data.data.data?.messageSent;

      updateQuery((prev) => {
        if (!prev.conversation) return prev;

        setConversation({
          ...prev.conversation,
          messages: _.orderBy([...prev.conversation.messages, newMessage], ['createdAt'], ['desc']),
        });

        resetUnreadCount({
          onCompleted: () => {
            setConversation((prev) => {
              return !prev ? prev : { ...prev, unreadCount: 0 };
            });

            client.cache.updateQuery({ query: GetConversationsDocument }, (cachedData) => {
              if (!cachedData) return cachedData;

              return {
                conversations: cachedData.conversations.map(
                  (conversation: GetConversationsQuery['conversations'][0]) =>
                    conversation.id === conversationId
                      ? { ...conversation, unreadCount: 0, lastMessage: newMessage }
                      : conversation
                ),
              };
            });
          },
        });

        return {
          ...prev,
          conversation: {
            ...prev.conversation,
            messages: [...prev.conversation.messages, newMessage],
          },
        };
      });
    },
  });

  const handleScroll = () => {
    if (messagesContainerRef.current?.scrollHeight === 0) {
      messagesContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    handleScroll();
  }, [conversation?.messages]);

  if (!user || !conversationId || !conversation) {
    return null;
  }

  return (
    <DialogContent
      dividers
      ref={messagesContainerRef}
      sx={{
        gap: 3,
        display: 'flex',
        flexDirection: 'column-reverse',
        minHeight: 'calc(100vh - 300px)',
        backgroundColor: 'background.default',
      }}
    >
      {conversation.messages.map((message) => (
        <Message key={message.id} message={message} members={conversation.members} />
      ))}
    </DialogContent>
  );
}
