import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

import { ShareMenu } from '../../common';
import useStyles from './styles';
import { HeaderProps } from './types';

function DesktopHeader(props: HeaderProps) {
  const { covers, defaultImage, handleTabChange, activeTab, handleBack } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles(theme);
  const { t } = useTranslation('cta');
  const aspectRatio = isMobile ? '4/3' : '16/9';

  return (
    <>
      {/* SLIDER */}
      <Swiper
        className="mySwiper"
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true, type: 'progressbar' }}
        navigation={true}
        loop={covers.length > 1}
        style={{ width: '100%', aspectRatio }}
      >
        {covers.length > 0 &&
          covers.map((cover) => (
            <SwiperSlide key={cover.id} style={{ width: '100%' }}>
              <img
                src={cover.url}
                alt="img"
                style={{ width: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio }}
              />
            </SwiperSlide>
          ))}
        {covers.length === 0 && (
          <SwiperSlide style={{ width: '100%' }}>
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
              <img
                src={'spot/' + (defaultImage || 'restaurant.png')}
                alt="Default cover"
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                  aspectRatio,
                  filter: 'grayscale(100%)',
                  opacity: 0.5,
                }}
              />
            </Stack>
          </SwiperSlide>
        )}
      </Swiper>

      {/* HEADER */}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        p={1}
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
        }}
      >
        <Stack direction="row" onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <theme.icons.back />
          <Typography> {t('back')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems={'center'} pr={1}>
          <ShareMenu />
        </Stack>
      </Stack>

      {/* TABS */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="tabs-spot-menu"
        variant="fullWidth"
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
          bottom: 0,
        }}
        TabIndicatorProps={{
          sx: styles.tabs,
        }}
      >
        {/* <Tab icon={<theme.icons.home sx={isMobile ? { fontSize: '0.9rem'} : {} } />} sx={styles.tab} /> */}
        <Tab icon={<theme.icons.home sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
        <Tab icon={<theme.icons.restaurant sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
        <Tab icon={<theme.icons.event sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
        <Tab icon={<theme.icons.feedback sx={isMobile ? { fontSize: '1rem' } : {}} />} sx={styles.tab} />
      </Tabs>
    </>
  );
}

function Covers(props: HeaderProps & { aspectRatio: string }) {
  const { covers, defaultImage, aspectRatio } = props;
  if (covers.length > 0) {
    return covers.map((cover) => (
      <SwiperSlide key={cover.id} style={{ width: '100%' }}>
        <img
          src={cover.url}
          alt="img"
          style={{ width: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio }}
        />
      </SwiperSlide>
    ));
  } else if (defaultImage) {
    return (
      <SwiperSlide style={{ width: '100%' }}>
        <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
          <img
            src={'spot/' + defaultImage}
            alt="Default cover"
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio,
              filter: 'grayscale(100%)',
              opacity: 0.5,
            }}
          />
        </Stack>
      </SwiperSlide>
    );
  } else {
    return (
      <SwiperSlide style={{ width: '100%' }}>
        <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
          {/* <theme.icons.noImage /> */}
          FESTAYRE
        </Stack>
      </SwiperSlide>
    );
  }
}

export default DesktopHeader;
