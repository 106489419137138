import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import GoogleIcon from '@mui/icons-material/Google';

export function GoogleConnect() {
  const { t } = useTranslation('authentication');

  const handleGoogleLogin = () => {
    window.location.href = `${import.meta.env.VITE_OAUTH_API_URI}/google`;
  };

  return (
    <Button
      fullWidth
      variant="contained"
      size="small"
      onClick={handleGoogleLogin}
      sx={{
        bgcolor: 'white',
        color: 'black',
        '&:hover': {
          bgcolor: 'white',
        },
      }}
      startIcon={<GoogleIcon />}
    >
      {t('button.Connect with', { provider: 'Google' })}
    </Button>
  );
}
