import { useTheme } from '@mui/material';
import { Doughnut } from 'react-chartjs-2';

import { GetAccountCountsQuery } from '@graphql';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend);

interface AccountDoughnutProps {
  counts: GetAccountCountsQuery['accountCounts'] | null;
}

export default function AccountDoughnut(props: AccountDoughnutProps) {
  const { counts } = props;
  const theme = useTheme();

  const consumerColor = theme.palette.secondary.main;
  const spotColor = theme.palette.primary.main;
  const artistColor = theme.palette.error.main;

  const data = {
    labels: ['Consumer', 'Spot', 'Artist'],
    datasets: [
      {
        label: 'Comptes',
        data: [counts?.current.consumer, counts?.current.spot, counts?.current.artist],
        backgroundColor: [consumerColor, spotColor, artistColor],
        borderColor: theme.palette.background.paper,
        borderWidth: 2,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right' as const,
        labels: {
          color: theme.palette.text.primary,
        },
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context: any) {
            return `${context.label}: ${context.raw}%`;
          },
        },
      },
    },
  };

  return counts ? <Doughnut data={data} options={options} /> : null;
}
