import * as React from 'react';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { GetSpotDetailedQuery } from '@graphql';
import { formatPriceRange } from '@utils';
import Features from './Features';
import IDCard from './IDCard';
import { Description, FAQ, FindUs, Tiles } from '../../common';

interface SpotPreviewProps {
  spot: GetSpotDetailedQuery['spot'];
}

function SpotPreview(props: SpotPreviewProps) {
  const { spot } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack spacing={isMobile ? 3 : 5}>
      <IDCard spot={spot} />
      <Stack spacing={1}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.SpotType types={spot.type} />
          <Tiles.SpotPrice
            value={10}
            currency="€"
            kind={formatPriceRange(spot.configuration.averageTicket)}
          />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Atmosphere atmosphere={spot.atmosphere} />
          <Tiles.MusicalGenre type={spot.configuration.musicalGenre} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Capacity max={spot.configuration.capacity} />
          <Tiles.CloseSpace value={spot.configuration.closeSpaces ?? 0} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Address label={spot.address.label} />
          <Tiles.Schedules opening={spot.opening} closing={spot.closing} />
        </Stack>
      </Stack>
      <Description description={spot.description} />
      <Features spot={spot} />
      <FindUs placeId={spot.address.placeId} />
      <FAQ FAQs={spot.FAQs} />
    </Stack>
  );
}

export default React.memo(SpotPreview);
