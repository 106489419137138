import React from "react";
import Avatar from '@mui/material/Avatar';

import { useScreenSize } from '@hooks';
import { buildImageUri, reBuildImageUri } from '@utils';
import { AvatarCustomProps } from './types';

export function AvatarCustom(props: AvatarCustomProps) {
  const { ressource, id, type, sx, ...rest } = props;
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const screenSize = useScreenSize();
  const [size, setSize] = React.useState(screenSize);

  // Handle the image size
  React.useEffect(() => {
    if (ressource) {
      const imageUri = buildImageUri({
        ressource,
        id,
        type,
        subType: 'avatar',
        size,
      });

      setImageSrc(imageUri);
    }
  }, [ressource, id, type, size]);

  React.useEffect(() => {
    if (screenSize !== size) {
      setSize(screenSize);

      if (imageSrc) {
        const newImageUri = reBuildImageUri(imageSrc, screenSize);
        setImageSrc(newImageUri);
      }
    }
  }, [ressource, imageSrc, screenSize, size]);

  return (
    <Avatar
      src={imageSrc}
      sx={sx}
      imgProps={{
        style: {
          borderRadius: '50%',
        },
      }}
      {...rest}
    />
  );
}
