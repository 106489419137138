import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation, Link, Outlet } from 'react-router-dom';

import { useSession } from '@hooks';
import CreateBookingFormDialog from './forms/create-booking-form';

const ME_PATH_PREFIX = '/me';

export default function MeHeader() {
  const { account } = useSession();
  const { pathname } = useLocation();
  const [feature, setFeature] = React.useState<string | null>(null);
  const [tab, setTab] = React.useState<string | null>(null);
  const [basePath, setBasePath] = React.useState<string | null>(null);

  const handleChange = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  // initialise the feature and view based on the pathname
  React.useEffect(() => {
    if (account?.__typename === 'Consumer') {
      const [view = null] = pathname.replace(ME_PATH_PREFIX, '').split('/').filter(Boolean);
      setFeature('');
      setTab(view);
      setBasePath(ME_PATH_PREFIX);
    } else if (account?.__typename === 'Spot') {
      const [feature = null, view = null] = pathname.replace(ME_PATH_PREFIX, '').split('/').filter(Boolean);
      setFeature(feature);
      setTab(view);
      setBasePath(`${ME_PATH_PREFIX}/${feature}`);
    } else {
      setFeature(null);
      setTab(null);
      setBasePath(null);
    }
  }, [account, pathname]);

  const allTabs = [
    {
      label: 'Calendrier',
      to: `${basePath}/calendar`,
      value: 'calendar',
    },
    {
      label: 'Tableau',
      to: `${basePath}/table`,
      value: 'table',
    },
  ].concat(
    account?.__typename === 'Spot'
      ? [
          {
            label: 'Statistiques',
            to: `${basePath}/stats`,
            value: 'stats',
          },
        ]
      : []
  );

  if (tab === null || !basePath || !account || (account?.__typename !== 'Consumer' && !feature) || !tab) {
    return null;
  }

  return (
    <>
      <Stack spacing={3} p={account?.__typename === 'Consumer' ? 5 : 0}>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={tab}>
            <Stack direction="row" spacing={3} width="100%">
              <Stack direction="row" spacing={3} width="100%">
                {account?.__typename === 'Spot' && feature && Boolean(feature?.length) && (
                  <Typography variant="h4" color="text.primary">
                    {feature.charAt(0).toUpperCase() + feature.slice(1)}
                  </Typography>
                )}
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    textColor="inherit"
                    indicatorColor="primary"
                    aria-label="spot-booking-tabs"
                    onChange={handleChange}
                  >
                    {allTabs.map((tab) => (
                      <Tab key={tab.to} component={Link} to={tab.to} label={tab.label} value={tab.value} />
                    ))}
                  </TabList>
                </Box>
              </Stack>
              {account?.__typename === 'Spot' && <CreateBookingFormDialog />}
            </Stack>
            <Stack sx={{ overflow: 'auto', m: 0, p: 0 }}>
              {allTabs.map((tabData) => (
                <TabPanel key={tabData.to} value={tabData.value} sx={{ mt: 3, p: 0 }}>
                  <Outlet />
                </TabPanel>
              ))}
            </Stack>
          </TabContext>
        </Box>
      </Stack>
    </>
  );
}
