import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';

import { useToastError } from '@hooks';
import { useUpdateAccountMutation, useIsSlugAvailableLazyQuery, Account } from '@graphql';
import { TextField } from '@components';
import { debounce } from '@utils';

interface NameUserProps {
  account: Account;
}

export function NameUser(props: NameUserProps) {
  const { account } = props;
  const { t } = useTranslation('cta');
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const toastError = useToastError();

  const [updateAccount] = useUpdateAccountMutation();
  const [checkSlug, { loading }] = useIsSlugAvailableLazyQuery();

  const formik = useFormik({
    initialValues: { slug: account?.slug as string },
    validationSchema: yup.object({
      slug: yup
        .string()
        .matches(/^[a-z0-9_-]+$/, t('slug.validation.matches', { ns: 'field' }))
        .min(3, t('slug.validation.min', { ns: 'field' }))
        .max(30, t('slug.validation.max', { ns: 'field' }))
        .required(t('slug.validation.required', { ns: 'field' }))
        .test('checkSlugUnique', t('slug.validation.unique', { ns: 'field' }), async function (value) {
          if (!value) {
            return false;
          }

          setIsLoading(true);
          const res = await checkSlugUnique(value);
          return await checkSlugUnique(value);
        }),
    }),
    onSubmit: (values) => {
      updateAccount({
        variables: {
          input: values,
        },
        onCompleted() {
          formik.resetForm({ values });
          setIsEditing(false);
        },
        onError: toastError,
      });
    },
  });

  const checkSlugUnique = React.useCallback(
    debounce(async (slug: string | undefined) => {
      try {
        if (!slug) {
          return false;
        }
        const { data } = await checkSlug({ variables: { slug } });
        return !!data?.isSlugAvailable;
      } catch (error) {
        import.meta.env.MODE !== 'production' && console.error('Error checking slug availability:', error);
        return false;
      } finally {
        setIsLoading(false);
      }
    }, 1000),
    []
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    formik.resetForm();
    // formik.resetForm({ values: { slug: account.slug } });
    setIsEditing(false);
  };

  if (!account) {
    return null;
  }

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold">{t('slug.label', { ns: 'field' })}</Typography>
            <Typography variant="caption" color="text.secondary">
              {t('slug.caption', { ns: 'field' })}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="flex-start">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid || isLoading}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack spacing={1}>
          <Stack>
            {isEditing ? (
              <TextField
                required
                fullWidth
                size="small"
                type="slug"
                name="slug"
                value={formik.values.slug}
                onChange={(e) => {
                  const lowercasedValue = e.target.value.toLowerCase();
                  formik.setFieldValue('slug', lowercasedValue);
                  formik.setTouched({ slug: true });
                }}
                error={Boolean(formik.touched.slug && formik.errors.slug)}
                helperText={formik.touched.slug && formik.errors.slug}
                InputProps={{
                  endAdornment: isLoading ? (
                    <InputAdornment position="end">
                      <CircularProgress size={20} color="info" />
                    </InputAdornment>
                  ) : null,
                }}
              ></TextField>
            ) : (
              <Typography pb={1}>{'@' + account.slug}</Typography>
            )}
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">URL</Typography>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="caption" color="text.secondary">
                https://festayre.fr/
              </Typography>
              <Typography variant="caption">{isEditing ? formik.values.slug : account.slug}</Typography>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="caption">{t('slug.caption2', { ns: 'field' })}</Typography>
            <Typography variant="caption">{`@${isEditing ? formik.values.slug : account.slug}`}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}
