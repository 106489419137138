import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';

import { APP_TITLE } from '@utils';
import { useAppContext } from '@context';
import {
  useGetSpotDetailedLazyQuery,
  useGetEventDetailedLazyQuery,
  useGetConsumerDetailedLazyQuery,
} from '@graphql';

export default function useAppTitle() {
  const { state } = useAppContext();
  let location = useLocation();
  const [appTitle, setAppTitle] = React.useState<string>(APP_TITLE);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleDialogAppTitle = (title: string) => {
    const dialogType = location.pathname.split('/').pop()?.slice(0, -1);
    setAppTitle(`${title} | ${APP_TITLE}'s ${dialogType}`);
  };

  const [getConsummer] = useGetConsumerDetailedLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ consumer }) => {
      handleDialogAppTitle(consumer.name);
    },
  });

  const [getEvent] = useGetEventDetailedLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ event }) => {
      handleDialogAppTitle(event.title);
    },
  });

  const [getSpot] = useGetSpotDetailedLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted: ({ spot }) => {
      handleDialogAppTitle(spot.name);
    },
  });

  React.useEffect(() => {
    const tab = location.pathname.split('/').pop();
    const type = searchParams.get('type');
    const idOrSlug = searchParams.get('q');

    if (idOrSlug) {
      if (type === 'consumer') {
        getConsummer({ variables: { slug: idOrSlug } });
      } else if (type === 'spot') {
        getSpot({ variables: { slug: idOrSlug } });
      } else if (type === 'event') {
        getEvent({ variables: { id: idOrSlug } });
      }
    } else {
      setAppTitle(`${APP_TITLE} ${tab}`);
    }
  }, [searchParams, state, location]);

  return appTitle;
}
