import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@context';
import { useSession } from '@hooks';
import FriendRequestButton from './buttons/FriendRequestButton';
import { AvatarCustom } from '@components';

export default function IDCard() {
  const { state } = useAppContext();

  if (!state.consumer) {
    return null;
  }

  const { id, name, events = [], following = [], friends } = state.consumer;
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('cta');

  if (!state.consumer) {
    return null;
  }

  return (
    <>
      <Stack spacing={3} direction="row" width="100%">
        <Stack spacing={3} direction="row" width="100%" justifyContent="space-between" alignItems="center">
          <AvatarCustom
            ressource={account?.avatar?.url || null}
            id={account?.id || ''}
            type="account"
            alt={account?.avatar?.url ? name + '-avatar' : ''}
            sx={{ width: 100, height: 100 }}
          />
          <Stack spacing={3} direction="row" width="100%" justifyContent="space-evenly">
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(events.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`event${events.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(following.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`spot${following.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(following.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`idole${following.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount((friends?.accepted || []).length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {t('word.friend', { count: friends?.accepted?.length, ns: 'common' })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {account?.id !== id && (
          <Stack spacing={1} width="30%">
            <FriendRequestButton />
            <Button variant="contained" color="uncolored" fullWidth startIcon={<theme.icons.send />}>
              {t('write')}
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack spacing={3} direction="row" width="100%">
        <Typography variant="h4" color="textPrimary">
          {name}
        </Typography>
      </Stack>
    </>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}
