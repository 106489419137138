import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { ListHomeMakersQuery } from '@graphql';
import MakerSlide from './MakerSlide';

interface SpotSliderProps {
  makers: ListHomeMakersQuery['homeMakers'][0]['makers'];
  title: string;
}

const MakerSlider = (props: SpotSliderProps) => {
  const { title, makers } = props;

  return (
    <Stack spacing={3} width="100%" justifyContent="space-between">
      <Typography pl={{ sm: 2, md: 10 }} variant="h5" fontWeight={'bold'}>
        {title}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: '-150px 0',
          }}
        >
          <Stack
            spacing={2}
            direction="row"
            sx={{
              overflowX: 'scroll',
              scrollBehavior: 'smooth',
              padding: '150px 25px 150px 75px',
              '&::-webkit-scrollbar': {
                display: 'none',
              },
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              '& > :nth-of-type(1)': {
                '&:hover .container': {
                  transformOrigin: 'center left',
                },
              },
              '& > :nth-last-of-type(1)': {
                '&:hover .container': {
                  transformOrigin: 'center right',
                },
              },
            }}
          >
            {makers.map((maker) => (
              <MakerSlide key={maker.id} maker={maker} />
            ))}
          </Stack>
        </Box>
      </Box>
    </Stack>
  );
};

export default MakerSlider;
