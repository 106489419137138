import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { TFunction } from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import {
  BookingOccasionEnum,
  SpotSchedulesQuery,
  SpotSchedulesOpening,
  SpotSchedulesClosing,
  GetBookingQuery,
  BookingStatusEnum,
  useUpdateBookingMutation,
} from '@graphql';

interface EditBookingFormProps {
  handleEdit: () => void;
  spot: SpotSchedulesQuery['spotSchedules'];
  booking: GetBookingQuery['booking'];
}

interface initDateTimeParams {
  opening: SpotSchedulesQuery['spotSchedules']['opening'];
  closing: SpotSchedulesQuery['spotSchedules']['closing'];
}

export default function EditBookingForm(props: EditBookingFormProps) {
  const { booking, spot, handleEdit } = props;
  const theme = useTheme();
  const { t } = useTranslation('booking');

  const [updateBooking] = useUpdateBookingMutation();

  const defaultValues =
    booking.origin === 'SPOT' && booking.client.__typename === 'BookingClient'
      ? {
          startTime: moment(booking.startTime),
          participants: booking.participants.toString(),
          occasion: booking.occasion ?? '',
          status: booking.status,
          firstname: booking.client.firstname,
          lastname: booking.client.lastname ?? null,
          phone: booking.client.clientPhone,
          email: booking.client.email ?? null,
        }
      : {
          status: booking.status,
        };

  const validationSchema =
    booking.origin === 'SPOT'
      ? yup.object({
          startTime: yup.date().min(moment()).required(),
          participants: yup.number().min(1).required(),
          occasion: yup.string().oneOf(['', ...Object.values(BookingOccasionEnum)]),
          status: yup
            .string()
            .oneOf(['', ...Object.values(BookingStatusEnum)])
            .required(),
          firstname: yup.string().required(),
          lastname: yup.string().nullable(),
          phone: yup
            .string()
            .matches(/^[0-9+\s()-]+$/, t('mobilePhone.validation.matches', { ns: 'field' }))
            .test('is-french-phone', 'Numéro de téléphone invalide', (value) => {
              if (!value) return false;
              const phoneNumber = parsePhoneNumberFromString(value, 'FR');
              return phoneNumber ? phoneNumber.isValid() : false;
            })
            .required(),
          email: yup.string().email().nullable(),
        })
      : yup.object({
          status: yup
            .string()
            .oneOf(['', ...Object.values(BookingStatusEnum)])
            .required(),
        });

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // only update the values that are different from the default ones
      const validValues = Object.assign(
        {},
        !moment(defaultValues.startTime).isSame(values.startTime) && {
          startTime: moment(values.startTime).toISOString(),
        },
        values.status !== defaultValues.status && { status: values.status },
        values.participants !== defaultValues.participants && { participants: values.participants },
        values.occasion !== defaultValues.occasion && { occasion: values.occasion }
      );

      // update client values only if the booking origin is SPOT
      if (booking.origin === 'SPOT' && booking.client.__typename === 'BookingClient') {
        const validClient = Object.assign(
          {},
          values.firstname !== defaultValues.firstname && { firstname: values.firstname },
          values.lastname !== defaultValues.lastname && { lastname: values.lastname },
          values.email !== defaultValues.email && { email: values.email },
          values.phone !== defaultValues.phone && { phone: values.phone }
        );

        if (Object.keys(validClient).length) {
          validValues.client = { id: booking.client.id, ...validClient };
        }
      }

      updateBooking({
        variables: { input: { id: booking.id, ...validValues } },
        onCompleted() {
          handleEdit();
          toast.success(t('update.success'));
        },
        onError() {
          toast.error(t('update.error'));
        },
      });
    },
  });

  const StatusSelect = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="status-select-label">Status</InputLabel>
        <Select
          labelId="status-select-label"
          id="status-select"
          value={formik.values.status}
          name="status"
          label="Status"
          onChange={formik.handleChange}
        >
          {getAvailableStatuses(booking).map((status) => (
            <MenuItem key={status} value={status}>
              {t(`status.${status}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={2} width="100%" divider={<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />}>
        <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
          <Typography>{t('dataGrid.Creator')}</Typography>
          <Typography>{t(`origin.${booking.origin}`)}</Typography>
        </Stack>
        {booking.origin === 'CONSUMER' ? (
          <Stack
            spacing={2}
            width="100%"
            divider={<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />}
          >
            <StatusSelect />
            <Alert severity="info">Les champs ci-dessous sont modifiables par le client uniquement</Alert>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Client')}</Typography>
              <Typography>{getClientName(booking)}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Date')}</Typography>
              <Typography>{moment(booking.startTime).format('dddd LL')}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Hour')}</Typography>
              <Typography>{getHour(booking)}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Participants')}</Typography>
              <Typography>{booking.participants}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Phone')}</Typography>
              <Typography>{getClientPhone(booking, t)}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Age')}</Typography>
              <Typography>{getClientAge(booking, t)}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Gender')}</Typography>
              <Typography>{getClientGender(booking, t)}</Typography>
            </Stack>
            <Stack justifyContent="space-between" width="100%" sx={{ flexDirection: 'row' }}>
              <Typography>{t('dataGrid.Occasion')}</Typography>
              <Typography>
                {booking.occasion ? t(`occasion.${booking.occasion}`) : t(`word.null`, { ns: 'common' })}
              </Typography>
            </Stack>
          </Stack>
        ) : (
          <Stack
            spacing={2}
            width="100%"
            divider={<Divider sx={{ backgroundColor: theme.palette.grey[600] }} />}
          >
            <StatusSelect />
            <DateTimePicker
              label="Date & heure d'arrivée"
              disablePast
              value={formik.values.startTime}
              onChange={(value) => {
                formik.setFieldValue('startTime', value);
              }}
              shouldDisableDate={(date) => shouldDisableDate(date, spot.opening, spot.closing)}
              shouldDisableTime={(date, view) => shouldDisableTime(date, view, spot.opening)}
              slotProps={{
                textField: {
                  required: true,
                  error: formik.touched.startTime && Boolean(formik.errors.startTime),
                  helperText:
                    formik.touched.startTime && formik.errors.startTime
                      ? String(formik.errors.startTime)
                      : null,
                },
              }}
              sx={{ width: '100%' }}
            />
            <TextField
              {...formik.getFieldProps('participants')}
              required
              fullWidth
              variant="outlined"
              slotProps={{ input: { min: 1 } }}
              type="number"
              id="book-participants"
              name="participants"
              label={t('participants.label', { ns: 'field' })}
              onChange={(e) => {
                const value = Math.max(1, parseInt(e.target.value, 10));
                formik.setFieldValue('participants', isNaN(value) ? '' : value);
              }}
              error={formik.touched.participants && Boolean(formik.errors.participants)}
              helperText={
                formik.touched.participants && formik.errors.participants
                  ? String(formik.errors.participants)
                  : null
              }
            />
            <FormControl fullWidth>
              <InputLabel id="occasion-select-label">
                {t('occasion select.label', { ns: 'field' })}
              </InputLabel>
              <Select
                {...formik.getFieldProps('occasion')}
                labelId="occasion-select-label"
                name="occasion"
                label={t('occasion select.label', { ns: 'field' })}
                autoComplete="off"
                displayEmpty
                defaultValue=""
              >
                <MenuItem autoFocus value="">
                  <em>{t('occasion select.placeholder', { ns: 'field' })}</em>
                </MenuItem>
                {Object.entries(BookingOccasionEnum).map(([key, value]) => (
                  <MenuItem key={key} value={value}>
                    {t(`suitable.${key.charAt(0).toLowerCase() + key.slice(1)}`, {
                      ns: 'spot',
                    })}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="occasion-helper-text">
                {t('word.Optional', { ns: 'common' })}
              </FormHelperText>
            </FormControl>
            <TextField
              {...formik.getFieldProps('firstname')}
              required
              fullWidth
              variant="outlined"
              slotProps={{ input: { min: 1 } }}
              name="firstname"
              label={t('firstname.label', { ns: 'field' })}
              onChange={formik.handleChange}
              error={formik.touched.firstname && Boolean(formik.errors.firstname)}
              helperText={
                formik.touched.firstname && formik.errors.firstname ? String(formik.errors.firstname) : null
              }
            />
            <TextField
              {...formik.getFieldProps('lastname')}
              fullWidth
              variant="outlined"
              slotProps={{ input: { min: 1 } }}
              name="lastname"
              label={t('lastname.label', { ns: 'field' })}
              onChange={formik.handleChange}
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={
                formik.touched.lastname && formik.errors.lastname ? String(formik.errors.lastname) : null
              }
            />
            <TextField
              {...formik.getFieldProps('phone')}
              fullWidth
              variant="outlined"
              slotProps={{ input: { min: 1 } }}
              name="phone"
              type="tel"
              label={t('mobilePhone.label', { ns: 'field' })}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone ? String(formik.errors.phone) : null}
            />
            <TextField
              {...formik.getFieldProps('email')}
              fullWidth
              variant="outlined"
              slotProps={{ input: { min: 1 } }}
              name="email"
              label={t('email.label', { ns: 'field' })}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email ? String(formik.errors.email) : null}
            />
          </Stack>
        )}
      </Stack>
      <Stack spacing={3} mt={5} direction="row">
        <Button variant="contained" color="uncolored" onClick={handleEdit} fullWidth>
          {t('cancel', { ns: 'cta' })}
        </Button>
        <Button
          disabled={!formik.dirty || !formik.isValid}
          variant="contained"
          color="success"
          fullWidth
          type="submit"
        >
          {t('save', { ns: 'cta' })}
        </Button>
      </Stack>
    </form>
  );
}

const getAvailableStatuses = (booking: GetBookingQuery['booking']) => {
  let availableStatuses: BookingStatusEnum[] = [booking.status];

  if (booking.status === BookingStatusEnum.Pending) {
    availableStatuses.push(BookingStatusEnum.Accepted, BookingStatusEnum.Rejected);
  } else if (booking.status === BookingStatusEnum.Accepted) {
    availableStatuses.push(BookingStatusEnum.Canceled);
  } else if ([BookingStatusEnum.Rejected, BookingStatusEnum.Canceled].includes(booking.status)) {
    availableStatuses.push(BookingStatusEnum.Accepted);
  }

  return availableStatuses;
};

function getClientName(booking: GetBookingQuery['booking']) {
  let clientName = '';

  if (booking.client.__typename === 'BookingClient') {
    clientName = booking.client.firstname;

    if (booking.client.lastname) {
      clientName += ` ${booking.client.lastname}`;
    }
  } else if (booking.client.__typename === 'Consumer') {
    clientName = booking.client.user.firstname + ' ' + booking.client.user.lastname;
  }

  return clientName;
}

function getClientAge(booking: GetBookingQuery['booking'], t: TFunction) {
  return booking.client.__typename === 'Consumer' && booking.client.user.birthdate
    ? moment(booking.client.user.birthdate).fromNow(true)
    : t(`word.null`, { ns: 'common' });
}

function getClientPhone(booking: GetBookingQuery['booking'], t: TFunction) {
  let phone = '';

  if (booking.client.__typename === 'BookingClient') {
    phone = booking.client.clientPhone;
  } else if (booking.client.__typename === 'Consumer') {
    phone = booking.client.phone ?? '';
  }

  return phone.replace(/(\d{2})(?=\d)/g, '$1 ');
}

function getClientGender(booking: GetBookingQuery['booking'], t: TFunction) {
  return booking.client.__typename === 'Consumer'
    ? t(`gender.${booking.client?.user.gender}`, { ns: 'common' })
    : t(`word.null`, { ns: 'common' });
}

const shouldDisableDate = (
  date: Moment,
  opening: Partial<SpotSchedulesOpening>[],
  closing: Partial<SpotSchedulesClosing>[]
) => {
  const currentDate = moment();
  const dayOfWeek = moment(date).day();
  const daySchedules = opening[dayOfWeek];

  // if none schedule for this day, disable the date
  if (!daySchedules) {
    return true;
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss');
  const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss');

  // if the closing time is before the opening time, it means the closing is the next day
  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  // Check if the current date falls within a closing range
  const isClosed = closing.some(({ from, to }) => moment(date).isBetween(from, to));
  if (isClosed) {
    return true;
  }

  // Check that the current date is between openTime and closingTime
  if (date.isSame(currentDate, 'day')) {
    const isValidDateRange = currentDate.isBefore(openTime) || currentDate.isBetween(openTime, closingTime);

    return !isValidDateRange;
  }

  return !daySchedules.openTime;
};

function getHour(booking: GetBookingQuery['booking']) {
  const nearbyTime = moment(booking.startTime).fromNow();
  let date = moment(booking.startTime).format('HH:mm ');

  if (moment().isSame(booking.startTime, 'day')) {
    date += ` (${nearbyTime})`;
  }

  return date;
}

const shouldDisableTime = (
  timeValue: Moment,
  view: 'hours' | 'minutes' | 'seconds',
  opening: Partial<SpotSchedulesOpening>[]
) => {
  const dayOfWeek = timeValue.day();
  const daySchedules = opening[dayOfWeek];

  if (!daySchedules) {
    return true;
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
    year: timeValue.year(),
    month: timeValue.month(),
    date: timeValue.date(),
  });

  const closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
    year: timeValue.year(),
    month: timeValue.month(),
    date: timeValue.date(),
  });

  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  const breakInTime = daySchedules.breakIn
    ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
        year: timeValue.year(),
        month: timeValue.month(),
        date: timeValue.date(),
      })
    : null;

  const breakOutTime = daySchedules.breakOut
    ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
        year: timeValue.year(),
        month: timeValue.month(),
        date: timeValue.date(),
      })
    : null;

  if (view === 'hours') {
    const isOpenTime = timeValue.isBetween(openTime, closingTime, null, '[)');

    // Vérification de la fermeture du jour précédent
    const previousDayOfWeek = (dayOfWeek - 1 + 7) % 7; // Trouver le jour précédent (modulo 7)
    const previousDaySchedule = opening[previousDayOfWeek];
    let isOpenFromPreviousDay = false;

    if (previousDaySchedule) {
      const prevClosingTime = moment(previousDaySchedule.closingTime, 'HH:mm:ss').set({
        year: timeValue.year(),
        month: timeValue.month(),
        date: timeValue.date() - 1, // Passer au jour précédent
      });

      const prevOpenTime = moment(previousDaySchedule.openTime, 'HH:mm:ss').set({
        year: timeValue.year(),
        month: timeValue.month(),
        date: timeValue.date() - 1,
      });

      if (prevClosingTime.isBefore(prevOpenTime)) {
        prevClosingTime.add(1, 'days'); // Si la fermeture est après minuit, ajuster la date
      }

      // Si l'heure actuelle est entre minuit et la fermeture du jour précédent
      if (timeValue.isBetween(moment('00:00:00', 'HH:mm:ss'), prevClosingTime, null, '[)')) {
        isOpenFromPreviousDay = true;
      }
    }

    if (!breakInTime && !breakOutTime) {
      return !isOpenTime && !isOpenFromPreviousDay;
    }

    const isDuringBreak = !!(
      breakInTime &&
      breakOutTime &&
      timeValue.isBetween(breakInTime, breakOutTime, null, '[)')
    );

    // Retourner "true" si en dehors des heures d'ouverture OU si en pause
    return (!isOpenTime && !isOpenFromPreviousDay) || isDuringBreak;
  }

  return false;
};

function initDateTime({ opening, closing }: initDateTimeParams, date: Moment = moment()): Moment {
  const dayOfWeek = date.day(); // Jour courant (0 = dimanche, 1 = lundi, ..., 6 = samedi)
  const daySchedules = opening[dayOfWeek]; // Récupérer les horaires pour le jour courant

  // Si aucun horaire pour aujourd'hui, passer au jour suivant (récursivement)
  if (!daySchedules || !daySchedules.openTime) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initDateTime({ opening, closing }, nextDay);
  }

  const openTime = moment(daySchedules.openTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  let closingTime = moment(daySchedules.closingTime, 'HH:mm:ss').set({
    year: date.year(),
    month: date.month(),
    date: date.date(),
  });

  // Ajuster l'heure de fermeture si elle est au jour suivant (fermeture après minuit)
  if (closingTime.isBefore(openTime)) {
    closingTime.add(1, 'days');
  }

  // Vérification des pauses (breakIn et breakOut)
  const breakInTime = daySchedules.breakIn
    ? moment(daySchedules.breakIn, 'HH:mm:ss').set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
      })
    : null;

  const breakOutTime = daySchedules.breakOut
    ? moment(daySchedules.breakOut, 'HH:mm:ss').set({
        year: date.year(),
        month: date.month(),
        date: date.date(),
      })
    : null;

  // Vérification des périodes de fermeture (spot.closing)
  const isClosed = closing.some(({ from, to }) => date.isBetween(moment(from), moment(to)));

  // Si actuellement fermé ou après l'heure de fermeture, passer au jour suivant
  if (isClosed || date.isAfter(closingTime)) {
    // Passer au jour suivant
    const nextDay = date.clone().add(1, 'days');
    return initDateTime({ opening, closing }, nextDay);
  }

  // Si l'heure actuelle est dans la pause (entre breakIn et breakOut)
  if (breakInTime && breakOutTime && date.isBetween(breakInTime, breakOutTime)) {
    // Passer à l'heure de fin de pause (breakOut)
    return roundToNearestFiveMinutes(breakOutTime);
  }

  // Si l'établissement est actuellement ouvert et hors pause, retourner l'heure actuelle
  if (date.isBetween(openTime, closingTime)) {
    return roundToNearestFiveMinutes(date.add(1, 'hour'));
  }

  // Sinon, retourner l'heure d'ouverture du jour actuel
  return roundToNearestFiveMinutes(openTime);
}

function roundToNearestFiveMinutes(startTime: Moment): Moment {
  const minutes = startTime.minutes();
  const roundedMinutes = Math.ceil(minutes / 10) * 10;
  return startTime.minutes(roundedMinutes).seconds(0);
}
