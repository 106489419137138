import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, Link } from 'react-router-dom';

import { useSession } from '@hooks';

export default function Header() {
  const { account } = useSession();
  const { pathname } = useLocation();
  const tabNames = ['events', 'spots', 'makers', 'me'];
  const tabName = pathname.split('/')[1];
  const fallbackTab = ['events', 'spot'].includes(tabName) ? tabName : 'spots';
  const [tab, setTab] = React.useState(tabNames.includes(fallbackTab) ? fallbackTab : 'spots');

  const handleChangeTab = (event: React.SyntheticEvent, newTab: string) => {
    setTab(newTab);
  };

  return (
    <Tabs
      value={tab}
      onChange={handleChangeTab}
      textColor="inherit"
      indicatorColor="primary"
      aria-label="tabs-menu"
    >
      <Tab component={Link} label="Events" to="events" value="events" />
      <Tab component={Link} label="Spots" to="spots" value="spots" />
      {/* <Tab component={Link} label="Artists" to="makers" value="makers" /> */}
      {account?.__typename === 'Consumer' && <Tab component={Link} label="Mon agenda" to="me" value="me" />}
    </Tabs>
  );
}
