import * as React from 'react';
import Stack from '@mui/material/Stack';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';

import { GetEventDetailedQuery } from '@graphql';
import { Tiles } from '../../common';
import IDCard from './IDCard';

interface EventPreviewProps {
  event: GetEventDetailedQuery['event'];
  handleClose: () => void;
  scrollToTickets: () => void;
}

function EventPreview(props: EventPreviewProps) {
  const { event, handleClose, scrollToTickets } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  if (!event) {
    return null;
  }

  const { tickets, address, from, to, spot } = event;

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    if (spot) {
      e.preventDefault();
      handleClose();
      setSearchParams({ type: 'spot', q: spot.slug, tab: 'preview' }, { preventScrollReset: true });
    }
  };

  return (
    <Stack spacing={isMobile ? 2 : 5}>
      <IDCard event={event} scrollToTickets={scrollToTickets} />
      <Stack spacing={1}>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Date from={from} to={to} />
          <Tiles.EventPrice tickets={tickets} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          <Tiles.Schedule from={from} to={to} />
          <Tiles.Address label={spot?.address.label || address?.label} />
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} spacing={1}>
          {spot && (
            <Link to={`?type=spot&q=${spot.slug}`} style={{ width: '100%' }} onClick={handleOnClick}>
              <Tiles.Spot avatar={spot?.avatar?.url || ''} name={spot?.name || ''} />
            </Link>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default React.memo(EventPreview);
