import { gql } from '@apollo/client';
import { CONVERSATION_MEMBER_FIELDS } from './account';

export const NOTIFICATION_FRAGMENT = gql`
  fragment NotificationFields on Notification {
    __typename
    ... on BookingNotification {
      id
      type
      action
      source {
        ...ConversationMemberFields
      }
      isRead
      createdAt
    }
  }
  ${CONVERSATION_MEMBER_FIELDS}
`;