import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';

export function Birthdate() {
  const { user } = useSession();
  const { t } = useTranslation('cta');

  return (
    <Stack width="100%">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography fontWeight="bold">{t('birthdate.label', { ns: 'field' })}</Typography>
        <Button variant="contained" color="uncolored" size="small">
          {t('edit')}
        </Button>
      </Stack>
      <Stack>
        <Typography color="text.secondary">{moment(user?.birthdate).format('dddd DD MMMM YYYY')}</Typography>
      </Stack>
    </Stack>
  );
}
