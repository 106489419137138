import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { useRegisterMobilePhoneMutation } from '@graphql';
import { showAppLoaderVar } from '@context';
import { useSession, useToastError } from '@hooks';

interface RegisterMobilePhoneProps {
  open: boolean;
  handleOnClose: (success: boolean) => void;
}

export default function RegisterMobilePhone(props: RegisterMobilePhoneProps) {
  const { open, handleOnClose } = props;
  const theme = useTheme();
  const { user } = useSession();
  const { t } = useTranslation('cta');
  const toastError = useToastError();

  const [registerMobilePhone] = useRegisterMobilePhoneMutation();

  const defaultValues = {
    mobilePhone: '',
  };

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: yup.object({
      mobilePhone: yup
        .string()
        .required()
        .test('is-valid-mobile-phone', t('registerMobilePhone.validation.test', { ns: 'field' }), (value) => {
          if (!value) return false;
          const phoneNumber = parsePhoneNumberFromString(value, 'FR');
          const isMobile = /^(\+33|0)[67]\d{8}$/.test(phoneNumber?.number || '');
          return phoneNumber?.isValid() && isMobile;
        })
        .label(t('registerMobilePhone.label', { ns: 'field' })),
    }),
    onSubmit: async (values) => {
      showAppLoaderVar(true);

      registerMobilePhone({
        variables: values,
        onCompleted() {
          handleClose(true);
        },
        onError: toastError,
      });
    },
  });

  const handleClose = (success = false) => {
    formik.resetForm({ values: defaultValues });
    showAppLoaderVar(false);
    handleOnClose(success);
  };

  if (!user) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => handleClose()}>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={5} p={5}>
          <Typography variant="h6">{t('registerMobilePhone.label', { ns: 'field' })}</Typography>
          <Alert severity="info">
            {t(
              'registerMobilePhone.alert.To be able to book, you must first register your mobile phone number.',
              { ns: 'field' }
            )}
          </Alert>
          <TextField
            name="mobilePhone"
            label={t('registerMobilePhone.label', { ns: 'field' })}
            type="tel"
            autoComplete="tel"
            value={formik.values.mobilePhone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.mobilePhone && Boolean(formik.errors.mobilePhone)}
            helperText={formik.touched.mobilePhone && formik.errors.mobilePhone}
            onKeyDown={(e) => {
              if (
                !/[0-9]/.test(e.key) &&
                e.key !== 'Backspace' &&
                e.key !== 'ArrowLeft' &&
                e.key !== 'ArrowRight'
              ) {
                e.preventDefault();
              }
            }}
            fullWidth
            required
          />
          <Stack direction="row" justifyContent="space-between">
            <Button variant="contained" color="uncolored" onClick={() => handleClose()}>
              {t('cancel')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              color={!(formik.dirty || formik.isValid) ? 'uncolored' : 'success'}
              disabled={!(formik.dirty || formik.isValid)}
            >
              {t('save')}
            </Button>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
}
