import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useSession } from '@hooks';
import { Birthdate, Firstname, Lastname, Gender, Email, Language, Password, Address } from './components';

interface handleCloseDialogrops {
  handleCloseDialog: () => void;
}

export function UserSettingsDialog(props: handleCloseDialogrops) {
  const { handleCloseDialog } = props;
  const theme = useTheme();
  const { user } = useSession();
  const { t } = useTranslation('account');
  const [tab, setTab] = React.useState('1');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  if (!user) {
    return null;
  }

  return (
    <Stack p={3}>
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="middle">
          {<theme.icons.manageAccount fontSize="large" />}
          <Typography variant="h5" fontWeight="bold" width="100%">
            {t('menu.user settings')}
          </Typography>
          <theme.icons.close onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} color="disabled" />
        </Stack>
      </DialogTitle>
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} />
      <DialogContent
        dividers
        sx={{
          minHeight: 'calc(100vh - 350px)',
          overflowY: 'auto',

          backgroundColor: 'background.default',
        }}
      >
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} mb={5}>
            <TabList indicatorColor="primary" onChange={handleChange} aria-label="user-settings-tabs">
              {[t('menu.preferences'), t('menu.personal informations'), t('menu.access')].map(
                (label, idx) => (
                  <Tab
                    key={idx}
                    sx={{
                      '&.Mui-selected': { color: 'white', fontWeight: 'bold' },
                    }}
                    label={label}
                    value={(idx + 1).toString()}
                  />
                )
              )}
            </TabList>
          </Box>
          <TabPanel value="1">
            <Stack spacing={5}>
              <Language />
            </Stack>
          </TabPanel>
          <TabPanel value="2">
            <Stack spacing={5}>
              <Firstname />
              <Lastname />
              <Gender />
              <Birthdate />
              <Address />
            </Stack>
          </TabPanel>
          <TabPanel value="3">
            <Stack spacing={5}>
              <Email />
              <Password />
            </Stack>
          </TabPanel>
        </TabContext>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(10, 3, 3, 0) }}>
        <Button
          fullWidth
          onClick={handleCloseDialog}
          color="uncolored"
          variant="contained"
          startIcon={<theme.icons.close />}
        >
          {t('word.close', { ns: 'common' })}
        </Button>
      </DialogActions>
    </Stack>
  );
}
