import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { Avatar, NameDisplay, NameUser } from '.';
import { useSession } from '@hooks';
import { Account } from '@graphql';

export function Identity() {
  const { t } = useTranslation('cta');
  const { account } = useSession();

  if (!account) {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('identity.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('identity.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={10} sx={{ height: '100%' }}>
        <Avatar account={account as Account} />
        <NameDisplay account={account as Account} />
        <NameUser account={account as Account} />
      </Stack>
    </Stack>
  );
}
