import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useSession } from '@hooks';
import Title from './Title';
import Action from './Action';

export function Request() {
  const theme = useTheme();
  const { user, account } = useSession();

  return (
    <Stack width="100%">
      <Title icon={<theme.icons.request />} title={'Sollicitations'} />
      <Action type="request" subtype="message" title="Nouveaux messages" />
      <Action type="request" subtype="mention" title="Nouvelles mentions" />
    </Stack>
  );
}
