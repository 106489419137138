import * as React from 'react';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import { useReactiveVar } from '@apollo/client';

import { useSession } from '@hooks';
import { previewBookingVar } from '@context';
import { useGetBookingLazyQuery, GetBookingQuery } from '@graphql';
import BookingTabs from './BookingTabs';

export default function BookingDisplay() {
  const theme = useTheme();
  const { account } = useSession();
  const [booking, setBooking] = React.useState<GetBookingQuery['booking'] | null>(null);
  const bookingId = useReactiveVar(previewBookingVar);

  const [getBooking, { data, loading }] = useGetBookingLazyQuery({
    onCompleted: ({ booking }) => {
      setBooking(booking);
    },
  });

  const handleClose = () => {
    previewBookingVar(null);
    setBooking(null);
  };

  React.useEffect(() => {
    if (bookingId) {
      getBooking({ variables: { id: bookingId } });
    }
  }, [bookingId]);

  if (!account || !booking) {
    return null;
  }

  return (
    <Dialog open={!!booking} onClose={handleClose} maxWidth="sm" fullWidth scroll="body">
      {loading ? (
        <Stack p={3} width="100%" alignItems="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Stack>
      ) : (
        <>
          <Stack alignItems="flex-end" mt={2} mr={2}>
            <IconButton onClick={handleClose}>
              <theme.icons.close />
            </IconButton>
          </Stack>
          <DialogContent style={{ width: '100%' }}>
            <BookingTabs booking={booking} />
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
