import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

interface SignupSpotProps {
  steps: string[];
  stepsCaptions: string[];
  activeStep: number;
  completed: { [k: number]: boolean };
  handleStep: (step: number) => void;
}

export default function SignupSpot(props: SignupSpotProps) {
  const { steps, activeStep, stepsCaptions, completed, handleStep } = props;
  const { t } = useTranslation('authentication');

  return (
    <Stack width="100%">
      <Stepper nonLinear activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step
            key={label}
            completed={completed[index]}
            sx={{
              '& .MuiStepLabel-root .Mui-completed': {
                color: 'success.light', // circle color (COMPLETED)
              },
              '& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel': {
                color: 'grey.500', // Just text label (COMPLETED)
              },
              '& .MuiStepLabel-root .Mui-active': {
                color: 'white', // circle color (ACTIVE)
              },
              '& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel': {
                color: 'common.white', // Just text label (ACTIVE)
              },
              '& .MuiStepLabel-root .Mui-active .MuiStepIcon-text': {
                fill: 'black', // circle's number (ACTIVE)
              },
            }}
          >
            <StepButton disabled={!completed[activeStep]} onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Typography textAlign="center" sx={{ mt: 2, py: 1 }}>
        {stepsCaptions[activeStep]}
      </Typography>
    </Stack>
  );
}
