import { toast } from "react-toastify";
import _ from "lodash";

export default function useToastError(): (error: any) => void {
  return (error: any) => {
    const isDev = import.meta.env.MODE === "development";

    let errorMessage = error.message;

    if (isDev) {
      if (error.name === 'ApolloError') {
        if (!_.isEmpty(error.networkError)) {
          const { statusCode } = error.networkError;
          if (statusCode < 500) {
            const [message] = error.networkError?.result?.errors || [];
            const formatedError = (
              <div>
                {errorMessage} <br />
                {message.extensions.code} <br />
                {message.message} <br />
              </div>
            );

            errorMessage = formatedError;
          }
        }
      }

      toast.error(errorMessage, {
        autoClose: isDev ? 5000 : 3000,
        closeButton: isDev,
        closeOnClick: isDev,
        pauseOnHover: isDev,
        draggable: false,
        progress: undefined,
      });
    }
  };
}
