import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import Skeleton from '@mui/material/Skeleton';
import { ListHomeMakersQuery } from '@graphql';
import MakerSlider from './MakerSlider';

interface SpotSlidersProps {
  handleInView: (index: number) => void;
  index: number;
  makersCategory: ListHomeMakersQuery['homeMakers'][0];
  visibleCarousels: number[];
}

const makersSliders = (props: SpotSlidersProps) => {
  const { handleInView, index, makersCategory, visibleCarousels } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      handleInView(index);
    }
  }, [inView, index]);

  return (
    <div ref={ref}>
      {visibleCarousels.includes(index) ? (
        <MakerSlider title={makersCategory.title} makers={makersCategory.makers} />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={300} />
      )}
    </div>
  );
};

export default makersSliders;
