import moment from 'moment';

export default function initValues(type: 'consumer' | 'spot' | 'artist') {
  switch (type) {
    case 'consumer':
      return {
        email: '',
        password: '',
        passwordConfirmation: '',
        firstname: '',
        lastname: '',
        gender: '',
        birthdate: moment('1987-05-18').toDate(),
      }
    case 'spot':
      return {
        email: '',
        password: '',
        passwordConfirmation: '',
        firstname: '',
        lastname: '',
        mobilePhone: '',
        gender: '',
        birthdate: moment('1987-05-18').toDate(),
        siret: '',
        accountName: '',
        address: '',
      }
    case 'artist':
      return {
        email: '',
        password: '',
        passwordConfirmation: '',
        firstname: '',
        lastname: '',
        gender: '',
        birthdate: moment('1987-05-18').toDate(),
        accountName: '',
        masterisedArts: '',
      }
    default:
      throw new Error('Invalid type')
  }
}