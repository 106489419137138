import * as React from 'react';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';

import AccountMenu from './menu/AccountMenu';
import AccountChange from './menu/AccountChange';
import AddAccount from './menu/AddAccount';
import AccountSettings from './menu/AccountSettings';
import NotificationSettings from './menu/NotificationSettings';
import UserSettings from './menu/UserSettings';
import LogoutButton from './menu/LogoutButton';

interface AccountDrawerProps {
  handleOpenDialog: (event: React.MouseEvent<HTMLElement>) => void;
}

export function AccountDrawer(props: AccountDrawerProps) {
  const { handleOpenDialog } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: 300,
        color: theme.palette.text.secondary,
        '& .MuiListItemButton-root': {
          color: theme.palette.text.secondary,
          '& .MuiListItemIcon-root, & .MuiListItemText-root': {
            color: theme.palette.text.secondary,
          },
          '&:hover': {
            color: theme.palette.text.primary,
            '& .MuiListItemIcon-root, & .MuiListItemText-root': {
              color: theme.palette.text.primary,
            },
          },
        },
        '& .MuiDivider-root': {
          backgroundColor: theme.palette.grey[700],
        },
      }}
      role="presentation"
    >
      <List>
        <AccountMenu />
        <Divider sx={{ backgroundColor: theme.palette.grey[700] }} variant="middle" />
        <AccountChange />
        <AccountSettings handleOpenDialog={handleOpenDialog} />
        <NotificationSettings handleOpenDialog={handleOpenDialog} />
        <AddAccount />
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[700] }} variant="middle" />
      <List>
        <UserSettings handleOpenDialog={handleOpenDialog} />
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[700] }} variant="middle" />
      <List>
        <LogoutButton />
      </List>
    </Box>
  );
}
