import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { useAppContext } from '@context';
import { GetConsumerDetailedQuery, Spot } from '@graphql';

interface ListSpotsProps {
  type: string;
}

interface ListSpotslotProps {
  spot: GetConsumerDetailedQuery['consumer']['following'][0];
}

export default function ListSpots(props: ListSpotsProps) {
  const { type } = props;
  const { account } = useSession();
  const { state } = useAppContext();
  const theme = useTheme();
  const [spots, setSpots] = React.useState<GetConsumerDetailedQuery['consumer']['following']>([]);

  React.useEffect(() => {
    if (state.consumer?.following?.length ?? 0 > 0) {
      if (state.consumer && type === 'followed') {
        setSpots(state.consumer.following.filter((followed) => followed.__typename === 'Spot'));
      }
      if (state.consumer && type === 'booked') {
        setSpots([]);
      }
    }
  }, [type]);

  const emptyStateByType = {
    followed: 'Aucun spot suivi',
    booked: 'Aucun spot déjà réservé',
  };

  return spots.length === 0 ? (
    <Stack width="100%">
      <Typography>{emptyStateByType[type as keyof typeof emptyStateByType]}</Typography>
    </Stack>
  ) : (
    <Stack width="100%" spacing={1}>
      {spots.map((spot) => (
        <ListSpotslot key={spot.id} spot={spot} />
      ))}
    </Stack>
  );
}

function ListSpotslot(props: ListSpotslotProps) {
  const { spot } = props;
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation('event');

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch({ type: 'SET_SPOT', payload: spot as Spot });
    setSearchParams({ ...searchParams, type: 'spot', q: spot.slug }, { preventScrollReset: true });
  };

  return (
    <Link to={`?type=consumer&q=${spot.id}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        spacing={3}
        p={1}
        direction="row"
        sx={{
          width: '100%',
          color: theme.palette.text.primary,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.palette.grey['900'],
          border: `1px solid ${theme.palette.grey['900']}`,
        }}
      >
        <Stack width="20%">
          <img
            src={spot.avatar || ''}
            alt="img"
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio: '1/1',
              borderRadius: theme.borderRadius * 2,
            }}
          />
        </Stack>
        <Stack spacing={1} p={1} justifyContent="space-between">
          <Typography variant="h6" fontWeight="bolder">
            {spot.name}
          </Typography>
          <Typography variant="body2">{spot.slug}</Typography>
        </Stack>
      </Stack>
    </Link>
  );
}
