import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';

interface TagsProps {
  tags: Array<{
    id: string;
    label: string;
  }>;
}

function Tags(props: TagsProps) {
  const { tags = [] } = props;
  const { t } = useTranslation('spot');

  if (!tags.length) {
    return null;
  }

  return (
    <Stack>
      <Typography variant="h6" mb={1}>
        {t('title.Tags')}
      </Typography>
      <Stack direction="row" spacing={1}>
        {tags.map(({ id, label }) => (
          <Chip key={id} label={'#' + label} variant="outlined" sx={{ cursor: 'default' }} />
        ))}
      </Stack>
    </Stack>
  );
}

export default React.memo(Tags);
