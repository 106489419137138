import { styled, alpha } from '@mui/material';
import {
  DataGrid,
  gridClasses,
} from '@mui/x-data-grid';

const ODD_OPACITY = 0.6;
const EVEN_OPACITY = ODD_OPACITY - 0.2;

export default styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-cell:hover': {
    color: 'text.primary',
  },
  // Applique un fond plus clair à la première colonne
  '& .f6r-app-theme--header, .f6r-app-theme--header-actions, .MuiDataGrid-columnHeaderCheckbox, .MuiDataGrid-cell[data-field="actions"]':
    {
      backgroundColor: 'black',
    },
  '& .MuiDataGrid-columnHeaders': {
    borderBottom: `0.5px solid ${theme.palette.grey[800]}`,
    borderTop: 0,
  },
  '& .MuiDataGrid-row, .MuiDataGrid-cell': {
    borderTop: `0.5px solid ${theme.palette.grey[800]}`,
    borderRight: 0,
    borderBottom: 0,
  },
  '& .MuiDataGrid-cellCheckbox, .MuiDataGrid-footerContainer': {
    backgroundColor: theme.palette.grey[900],
  },
  '&  .MuiDataGrid-footerContainer': {
    borderTop: `1px solid ${theme.palette.grey[700]}`,
    // borderTop: `2px solid ${theme.palette.grey[700]}`,
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: alpha('#000', EVEN_OPACITY),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, EVEN_OPACITY),
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, EVEN_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          EVEN_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            EVEN_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: alpha('#000', ODD_OPACITY),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY + theme.palette.action.selectedOpacity),
      '&:hover': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY + theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  },
}));
