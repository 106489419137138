import { Theme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactiveVar } from '@apollo/client';

import { showBackDropVar } from '@context';

export function LoadingBackDrop({ force }: { force?: boolean }) {
  const showBackDrop = useReactiveVar(showBackDropVar);

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme: Theme) => theme.zIndex.appBar - 1,
          backgroundColor: 'black',
        }}
        open={force !== undefined ? force : showBackDrop}
        transitionDuration={500}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </div>
  );
}
