import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

import { GetConsumerDetailedQuery } from '@graphql';
import useStyles from './styles';

interface HeaderProps {
  covers: GetConsumerDetailedQuery['consumer']['covers'];
  handleTabChange: Function;
  handleBack: (isBack: boolean | undefined) => void;
  activeTab: number;
}

const Header = (props: HeaderProps) => {
  const { covers, handleTabChange, activeTab, handleBack } = props;
  const theme = useTheme();
  const styles = useStyles(theme);
  const { t } = useTranslation('cta');

  return (
    <>
      {/* SLIDER */}
      <Swiper
        className="mySwiper"
        modules={[Navigation, Pagination]}
        pagination={{ clickable: true, type: 'progressbar' }}
        navigation={true}
        loop={covers.length > 0}
        style={{ width: '100%', aspectRatio: '16/9' }}
      >
        {covers.length > 0 ? (
          covers.map((cover) => (
            <SwiperSlide key={cover.id} style={{ width: '100%' }}>
              <img
                src={cover.url}
                alt="img"
                style={{ width: '100%', objectFit: 'cover', objectPosition: 'center', aspectRatio: '16/9' }}
              />
            </SwiperSlide>
          ))
        ) : (
          <SwiperSlide style={{ width: '100%' }}>
            <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
              Festayre
            </Stack>
          </SwiperSlide>
        )}
      </Swiper>

      {/* HEADER */}
      <Stack
        direction="row"
        justifyContent={'space-between'}
        p={1}
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
        }}
      >
        <Stack direction="row" onClick={handleBack} sx={{ cursor: 'pointer' }}>
          <theme.icons.back />
          <Typography> {t('back')}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} alignItems={'center'}>
          <theme.icons.share cursor="pointer" />
          <theme.icons.options cursor="pointer" />
        </Stack>
      </Stack>

      {/* TABS */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        textColor="inherit"
        indicatorColor="primary"
        aria-label="tabs-host-menu"
        variant="fullWidth"
        sx={{
          position: 'absolute',
          zIndex: 1,
          width: '100%',
          backdropFilter: 'blur(15px)',
          backgroundColor: alpha(theme.palette.background.paper, 0.5),
          bottom: 0,
        }}
        TabIndicatorProps={{
          sx: styles.tabs,
        }}
      >
        <Tab icon={<theme.icons.home />} sx={styles.tab} />
        <Tab icon={<theme.icons.event />} sx={styles.tab} />
        <Tab icon={<theme.icons.image />} sx={styles.tab} />
        <Tab icon={<theme.icons.feedback />} sx={styles.tab} />
      </Tabs>
    </>
  );
};

export default Header;
