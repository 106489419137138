import * as React from 'react';
import Stack from '@mui/material/Stack';

import { useListHomeMakersLazyQuery } from '@graphql';
import { showBackDropVar, useAppContext } from '@context';
import { MakerHighlight, MakerSliders } from './components';

const ONE_HOUR = 60 * 60 * 1000;
const MAKER_LAST_CALL_KEY = 'MAKER_LAST_CALL_KEY';

const MakersPage = () => {
  const { state, dispatch } = useAppContext();
  const { makers: makersCategories } = state;
  const [visibleCarousels, setVisibleCarousels] = React.useState<number[]>([]);

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(MAKER_LAST_CALL_KEY);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };

  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getMakers, { called, loading, refetch }] = useListHomeMakersLazyQuery({
    onCompleted: ({ homeMakers }) => {
      dispatch({ type: 'SET_MAKERS', payload: homeMakers });
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(MAKER_LAST_CALL_KEY, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (loading) {
      showBackDropVar(true);
    }
    if (makersCategories.length === 0 && !called) {
      getMakers();
    } else if (makersCategories.length > 0) {
      if (timeSinceLastCall > ONE_HOUR) {
        refetch();
        setLastCall(now);
        localStorage.setItem(MAKER_LAST_CALL_KEY, now.toString());
      }
      setTimeout(() => {
        showBackDropVar(false);
      }, 300);
    }
  }, [makersCategories, loading, called]);

  const handleInView = (index: number) => {
    setVisibleCarousels((prev) => [...new Set([...prev, index, index + 1])]);
  };

  return (
    <>
      {makersCategories.length > 0 && (
        <>
          <MakerHighlight />
          <Stack spacing={10} mt={{ xs: '35vh', sm: '60vh', md: '65vh', lg: '85vh' }} zIndex={1}>
            {makersCategories.map((makersCategory, index) => (
              <MakerSliders
                key={makersCategory.id}
                handleInView={handleInView}
                index={index}
                makersCategory={makersCategory}
                visibleCarousels={visibleCarousels}
              />
            ))}
          </Stack>
        </>
      )}
    </>
  );
};

export default MakersPage;
