import { gql } from "@apollo/client";

export const accountImageFragment = gql`
  fragment AccountImageFragment on AccountImage {
    __typename
    id
    url
    fileId
    fileName
    size
    mimeType
    createdAt
  }
`;

export const eventImageFragment = gql`
  fragment EventImageFragment on EventImage {
    __typename
    id
    url
    fileId
    fileName
    size
    mimeType
    createdAt
  }
`;
