import * as React from 'react';
import { useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';

export default function AccountChange() {
  // const { handleOpenDialog } = props;
  const theme = useTheme();
  const { user } = useSession();
  if (user?.accounts && user.accounts.length <= 1) {
    return null;
  }

  return (
    <ListItem disablePadding>
      <ListItemButton>
        <ListItemIcon>
          <theme.icons.change />
        </ListItemIcon>
        <ListItemText primary={'Changer de compte'} />
      </ListItemButton>
    </ListItem>
  );
}
