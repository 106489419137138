import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { useSession } from '@hooks';

interface TitleProps {
  icon?: React.ReactNode | null;
  title: string;
}

export default function Title(props: TitleProps) {
  const theme = useTheme();
  const { icon = null, title = 'title' } = props;

  return (
    <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
      <Stack spacing={1} direction="row" width="70%" justifyContent="flex-start" alignItems="flex-end">
        {icon}
        <Typography component="span" fontWeight="bold">
          {title}
        </Typography>
      </Stack>
      <Typography p="12px">{'Mail'}</Typography>
      <Typography p="12px">{'App'}</Typography>
    </Stack>
  );
}
