import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { loginStatusVar } from '@context';
import { useFollowMutation, useUnfollowMutation, GetSpotDetailedQuery } from '@graphql';
import { useSession, useToastError } from '@hooks';

interface FollowButton {
  spotId: string;
  isFollowing: boolean;
}

export default function FollowButton(props: FollowButton) {
  const { spotId, isFollowing } = props;
  const { user, account } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = React.useState<number | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('cta');

  const [follow] = useFollowMutation({
    variables: { accountId: spotId },
    onError: useToastError(),
    update: (cache, { data }) => {
      if (!account) {
        return;
      }

      cache.modify({
        id: cache.identify({ __typename: 'Spot', id: spotId }),
        fields: {
          isFollowing() {
            return true;
          },
          addicts(existingAddicts = []) {
            return [
              ...existingAddicts,
              {
                id: account.id,
                name: account.name,
                slug: account.slug,
                avatar: account.avatar,
                __typename: account.__typename,
              },
            ];
          },
        },
      });
    },
  });

  const [unfollow] = useUnfollowMutation({
    variables: { accountId: spotId },
    onError: useToastError(),
    update: (cache, { data }) => {
      if (!account) {
        return;
      }

      cache.modify({
        id: cache.identify({ __typename: 'Spot', id: spotId }),
        fields: {
          isFollowing() {
            return false;
          },
          addicts(existingAddicts = []) {
            return (existingAddicts as GetSpotDetailedQuery['spot']['addicts']).filter(
              ({ id }) => id !== account.id
            );
          },
        },
      });
    },
  });

  const handleFollow = () => {
    if (!user) {
      loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    } else if (isFollowing === false) {
      follow();
    }
  };

  const handleTryUnfollow = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(buttonRef.current ? buttonRef.current.offsetWidth : null);
  };

  const handleCloseUnfollow = () => {
    setAnchorEl(null);
  };

  const handleUnfollow = (event: React.MouseEvent<HTMLButtonElement>) => {
    unfollow();
    handleCloseUnfollow();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        ref={buttonRef}
        variant="contained"
        color={isFollowing ? 'uncolored' : 'primary'}
        fullWidth
        onClick={isFollowing ? handleTryUnfollow : handleFollow}
        endIcon={isFollowing ? <theme.icons.expandMore /> : null}
      >
        {isFollowing ? t('followed') : t('follow')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        elevation={3}
        open={open}
        sx={{ width: '100%', mt: 1 }}
        onClose={handleCloseUnfollow}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { width: menuWidth || '100%' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}
          color="uncolored"
          onClick={handleUnfollow}
        >
          {t('unfollow')}
        </MenuItem>
      </Menu>
    </Box>
  );
}
