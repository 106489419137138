import * as React from 'react';

import { ListHomeEventsQuery, ListHomeSpotsQuery } from '@graphql';
import { SlidersWrapper } from '@components';
import Slider from './Slider';

interface SlidersProps {
  categories: ListHomeEventsQuery['eventCategories'] | ListHomeSpotsQuery['homeSpots'];
}

export const Sliders = (props: SlidersProps) => {
  const { categories } = props;
  const [visibleCarousels, setVisibleCarousels] = React.useState<number[]>([]);

  const handleInView = (index: number) => {
    setVisibleCarousels((prev) => [...new Set([...prev, index, index + 1])]);
  };

  return (
    <SlidersWrapper>
      {categories.map((category, index) => (
        <Slider
          key={category.id}
          handleInView={handleInView}
          index={index}
          category={category}
          visibleCarousels={visibleCarousels}
        />
      ))}
    </SlidersWrapper>
  );
};
