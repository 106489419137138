import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import frLocale from '@fullcalendar/core/locales/fr';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { GetSpotDetailedQuery } from '@graphql';
import EventSlot from './EventSlot';
import event from 'locales/event';

interface SpotCalendarProps {
  events: GetSpotDetailedQuery['spot']['events'];
}

type Events = { className: string } & SpotCalendarProps['events'][0];

function SpotCalendar(props: SpotCalendarProps) {
  const { events = [] } = props;
  const { user } = useSession();
  const [eventsToDisplay, setEventsToDisplay] = React.useState<Events[]>([]);
  const [selectedDate, setSelectedDate] = React.useState<string | null>(null);
  const { t } = useTranslation('spot');

  const handleDateClick = (dateStr: string) => {
    const matchingEvents = events
      .filter((event) => new Date(event.from).toDateString() === dateStr)
      .map((event) => ({ ...event, className: 'event-full' }));

    setEventsToDisplay(matchingEvents);
    setSelectedDate(dateStr);

    // Scroll to the element with ID "selected-account-event-date"
    setTimeout(() => {
      const element = document.getElementById('selected-account-event-date');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 0); // Use a timeout to ensure the element is rendered before scrolling
  };

  // const nextEvent = events.find((event) => new Date(event.from) > new Date());
  const lastEvent = events.find((event) => new Date(event.from) < new Date());

  const locales = {
    fr: frLocale,
    es: esLocale,
    en: undefined,
  };
  const locale = user?.preferences.language
    ? locales[user?.preferences.language as 'fr' | 'es' | 'en']
    : undefined;

  return (
    <Stack spacing={5} p={3}>
      {/* {nextEvent && (
        <Stack spacing={2} width="100%">
          <Typography variant="h6">{t('event.Next event')}</Typography>
          <EventSlot direction="row" event={nextEvent} />
        </Stack>
      )} */}
      <Stack spacing={2}>
        <Typography variant="h6">Agenda</Typography>
        <Stack spacing={5} direction="row" justifyContent="space-between">
          <Stack width="50%">
            <FullCalendar
              firstDay={1} // monday
              locale={locale}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              headerToolbar={{
                left: 'prev,next',
                center: 'title',
                right: 'today',
              }}
              events={events.map((event) => ({
                ...event,
                start: new Date(event.from).toISOString(),
                className: 'event-full',
              }))}
              dayCellDidMount={(arg) => {
                const dateStr = arg.date.toDateString();
                const eventDays = events.map((event) => new Date(event.from).toDateString());

                if (eventDays.includes(dateStr)) {
                  arg.el.classList.add('event-day');
                  arg.el.style.cursor = 'pointer';
                  arg.el.addEventListener('click', () => handleDateClick(dateStr));
                }
              }}
              dayCellClassNames={(arg) => {
                let classNames = [];
                if (arg.date.getDay() === 0 || arg.date.getDay() === 6) {
                  if (arg.isOther) {
                    classNames.push('other-month weekend');
                  } else {
                    classNames.push('weekend');
                  }
                }
                const dateStr = arg.date.toDateString();
                const eventDays = events.map((event) => new Date(event.from).toDateString());
                if (eventDays.includes(dateStr)) {
                  classNames.push('event-day');
                }

                if (dateStr === selectedDate) {
                  classNames.push('selected-day');
                }

                return classNames;
              }}
              eventContent={() => <div></div>}
            />
          </Stack>
          {/* <Stack spacing={2} id="selected-account-event-date" width="50%">
            {eventsToDisplay.length > 0 ? (
              eventsToDisplay.map((event) => <EventSlot key={event.id} direction="column" event={event} />)
            ) : (
              <p>{t('event.No event today')}</p>
            )}
          </Stack> */}
        </Stack>
      </Stack>
      <Stack spacing={2} id="selected-account-event-date" width="100%">
        {eventsToDisplay.length > 0 ? (
          eventsToDisplay.map((event) => <EventSlot key={event.id} direction="row" event={event} />)
        ) : (
          <p>{t('event.No event today')}</p>
        )}
      </Stack>
      {/* {lastEvent && (
        <Stack spacing={2} width="100%">
          <Typography variant="h6">{t('event.Last event')}</Typography>
          <EventSlot direction="row" event={lastEvent} />
        </Stack>
      )} */}
    </Stack>
  );
}

export default React.memo(SpotCalendar);
