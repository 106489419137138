import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import { AvatarCustom, AvatarUploadButton } from '@components';
import { Account } from '@graphql';

interface AvatarProps {
  account: Account;
}

export function Avatar(props: AvatarProps) {
  const { account } = props;
  const theme = useTheme();

  if (!account) {
    return null;
  }

  return (
    <Stack alignItems="center">
      <AvatarCustom
        ressource={account.avatar?.url || null}
        id={account.id || ''}
        type="account"
        sx={{
          width: 100,
          height: 100,
          border: `1px solid ${theme.palette.grey[800]}`,
          padding: 0.3,
        }}
      />
      <AvatarUploadButton />
    </Stack>
  );
}
