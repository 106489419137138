import * as React from 'react';

import { useListHomeSpotsLazyQuery, ListHomeSpotsQuery } from '@graphql';
import { showBackDropVar } from '@context';
import { Sliders, Highlight } from '@components';

const FIVE_MINUTES = 1000 * 60 * 5;
const SPOTS_LAST_CALL_KEY = 'SPOTS_LAST_CALL';

const SpotsPage = () => {
  const [homeSpots, setHomeSpots] = React.useState<ListHomeSpotsQuery['homeSpots'] | null>(null);

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(SPOTS_LAST_CALL_KEY);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };

  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getSpots, { loading }] = useListHomeSpotsLazyQuery({
    onCompleted: ({ homeSpots }) => {
      setHomeSpots(homeSpots ?? null);
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(SPOTS_LAST_CALL_KEY, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (loading) {
      showBackDropVar(true);
    }

    if (!homeSpots || timeSinceLastCall > FIVE_MINUTES) {
      getSpots({
        fetchPolicy: timeSinceLastCall > FIVE_MINUTES ? 'cache-and-network' : 'cache-first',
      });
    }

    if (!loading && homeSpots) {
      showBackDropVar(false);
    }
  }, [homeSpots, lastCall]);

  if (!homeSpots) {
    return null;
  }

  return (
    <>
      <Highlight />
      <Sliders categories={homeSpots} />
    </>
  );
};

export default SpotsPage;
