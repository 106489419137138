import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useInView } from 'react-intersection-observer';
import _ from 'lodash';

import { useSession } from '@hooks';
import { AvatarCustom } from '@components';
import { GetConversationQuery } from '@graphql';

interface MessageProps {
  message: GetConversationQuery['conversation']['messages'][0];
  members: GetConversationQuery['conversation']['members'];
}

export function Message(props: MessageProps) {
  const { message, members } = props;
  const theme = useTheme();
  const { account } = useSession();

  const { ref } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  if (!account) {
    return null;
  }

  return (
    <Stack
      key={message.id}
      ref={ref}
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent={message.sender.id === account.id ? 'flex-end' : 'flex-start '}
    >
      {message.sender.id !== account.id && (
        <AvatarCustom
          ressource={message.sender.avatar?.url || null}
          id={message.sender.id}
          type="account"
          sx={{
            width: 40,
            height: 40,
          }}
        />
      )}

      <Stack spacing={0.5} maxWidth="70%">
        {members.length > 2 && (
          <Typography
            color="text.secondary"
            variant="body2"
            align={message.sender.id === account.id ? 'right' : 'left'}
          >
            {message.sender.name}
          </Typography>
        )}
        <Stack
          p={1.5}
          sx={{
            borderRadius: theme.borderRadius,
            backgroundColor:
              message.sender.id === account.id ? theme.palette.primary.main : theme.palette.grey[800],
          }}
        >
          <Typography
            variant="body2"
            align={message.sender.id === account.id ? 'right' : 'left'}
            sx={{ whiteSpace: 'pre-wrap' }}
          >
            {message.content}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
