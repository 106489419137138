import { State, Action } from '..';

const conversationReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_CONVERSATION': {
      const { conversationId } = action.payload;
      return { ...state, conversationId };
    }
    case 'UNSET_CONVERSATION':
      return { ...state, conversationId: null };

    default:
      return state;
  }
};

export default conversationReducer;
