import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useSession } from '@hooks';
import SpotBookingCalendar from './spot/BookingCalendar';
import SpotEventCalendar from './spot/EventCalendar';
import ConsumerCalendar from './consumer/Calendar';

function Calendar() {
  const { account } = useSession();
  const { pathname } = useLocation();
  const [feature, setFeature] = React.useState<string | null>(null);

  React.useEffect(() => {
    const [foundFeature = null] = pathname.replace('/me', '').split('/').filter(Boolean);
    setFeature(foundFeature);
  }, [pathname]);

  if (!account) {
    return null;
  }

  if (account.__typename === 'Consumer') {
    return <ConsumerCalendar />;
  }

  if (account.__typename === 'Spot' && feature === 'booking') {
    return <SpotBookingCalendar />;
  }

  if (account.__typename === 'Spot' && feature === 'event') {
    return <SpotEventCalendar />;
  }
}

export default React.memo(Calendar);
