import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

interface LogoProps {
  variant?: 'letter' | 'full';
  size?: 'small' | 'medium' | 'large' | 'huge' | number;
  clickable?: boolean;
}

export function Logo(props: LogoProps) {
  const { variant = 'full', size = 'medium', clickable = true } = props;

  const SquareWrapper = (props: React.PropsWithChildren<{}>) => {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: 1,
          border: `1px solid ${alpha('#FFF', 0.5)}`,
          height: 'inherit',
          width: 'auto',
          aspectRatio: '1 / 1',
        }}
      >
        {props.children}
      </Stack>
    );
  };

  const Festayre = (props: LogoProps) => {
    const { variant = 'full', size = 'medium' } = props;

    const fontSizes = {
      small: '1rem',
      medium: '1.3rem',
      large: '2rem',
      huge: '3rem',
    };

    return (
      <Typography
        component="span"
        variant="body1"
        noWrap
        lineHeight={1}
        fontSize={typeof size === 'number' ? `${size}rem` : fontSizes[size]}
        color="textPrimary"
        fontStyle="normal"
        fontWeight="400"
        fontFamily="Major Mono Display, monospace"
      >
        {variant === 'letter' ? 'Y' : 'festaYre'}
      </Typography>
    );
  };

  if (!clickable) {
    return variant === 'letter' ? (
      <SquareWrapper>
        <Festayre {...props} />
      </SquareWrapper>
    ) : (
      <Festayre {...props} />
    );
  }

  if (variant === 'letter') {
    return (
      <SquareWrapper>
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
          <Festayre {...props} />
        </Link>
      </SquareWrapper>
    );
  }

  return (
    <Link to="/" style={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}>
      <Festayre {...props} />
    </Link>
  );
}
