import { gql } from "@apollo/client";
import { eventFragment, categoryFragment, addressFragment, guestFragment } from '../fragments';

export const LIST_EVENTS = gql`
  query events($input: EventsInput!) {
    events(input: $input) {
      id
      ...EventFragment
      category {
        ...CategoryFragment
      }
      address {
        ...AddressFragment
      }
      makers {
        ...GuestFragment
      }
    }
  }
  ${eventFragment}
  ${categoryFragment}
  ${addressFragment}
  ${guestFragment}
`;

export const GET_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      id
      ...EventFragment
      category {
        ...CategoryFragment
      }
      address {
        ...AddressFragment
      }
      makers {
        ...GuestFragment
      }
    }
  }
  ${eventFragment}
  ${categoryFragment}
  ${addressFragment}
  ${guestFragment}
`;
