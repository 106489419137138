import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';

import {
  useGetConversationsQuery,
  useOnConversationUpdateSubscription,
  GetConversationsQuery,
} from '@graphql';
import { useSession, useToastError } from '@hooks';

interface ConversationButtonProps {
  handleOpenDrawer: (event: React.MouseEvent<HTMLElement>) => void;
}

export function ConversationButton(props: ConversationButtonProps) {
  const { handleOpenDrawer } = props;
  const { account } = useSession();
  const [conversations, setConversations] = React.useState<GetConversationsQuery['conversations']>([]);
  const [unreadConversations, setUnreadConversations] = React.useState(0);
  const toastError = useToastError();
  const theme = useTheme();

  useGetConversationsQuery({
    skip: !account,
    onError: toastError,
    onCompleted({ conversations }) {
      setConversations(conversations);
      const unreadCount = conversations.reduce((count, conversation) => {
        return count + (conversation.unreadCount > 0 ? 1 : 0);
      }, 0);
      setUnreadConversations(unreadCount);
    },
  });

  useOnConversationUpdateSubscription({
    skip: !account,
    onError: toastError,
    onData: ({ data }) => {
      if (data.data?.conversationUpdate) {
        const { conversationUpdate } = data.data;
        const exists = conversations.findIndex((conversation) => conversation.id === conversationUpdate.id);
        if (exists !== -1) {
          setConversations((prevConversations) => {
            return prevConversations.map((conversation) => {
              return conversation.id === conversationUpdate.id ? conversationUpdate : conversation;
            });
          });
        } else {
          setConversations([...conversations, conversationUpdate]);
        }
      }
    },
  });

  React.useEffect(() => {
    const unreadCount = conversations.reduce((count, conversation) => {
      return count + (conversation.unreadCount > 0 ? 1 : 0);
    }, 0);
    setUnreadConversations(unreadCount);
  }, [conversations]);

  return (
    <Tooltip title="Messages">
      <IconButton data-id="inbox" onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={unreadConversations}>
          <theme.icons.telegram
            sx={{
              color: theme.palette.text.secondary,
              '&:hover': { color: theme.palette.text.primary },
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
