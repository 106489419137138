import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { Logo, AccountHeader, AuthenticationDialog } from '@components';
import ConsumerHeader from './ConsumerHeader';
import AppBar from './AppBar';
import BurgerMenu from './BurgerMenu';
import Drawer from './Drawer';
import DrawerHeader from './DrawerHeader';
import useStyles from './styles';

export default function DesktopApp() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user, account, isLoggedIn } = useSession();
  const [isDrawer, setIsDrawer] = React.useState(false);
  const appBarRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(theme, open);
  const { t } = useTranslation('account');

  React.useEffect(() => {
    const isEnabled =
      (Device.isMobileOnly && isLoggedIn) || ['Spot', 'Maker'].includes(account?.__typename ?? '');
    setIsDrawer(isEnabled);
  }, [account?.__typename, Device.isMobileOnly, isLoggedIn]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar ref={appBarRef} position="fixed" open={open}>
        <Toolbar>
          <Logo variant="full" size="large" />

          <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ ml: isDrawer ? 0 : 3 }}>{Device.isDesktop && <ConsumerHeader />}</Box>
            {user && isLoggedIn ? (
              <AccountHeader />
            ) : (
              <AuthenticationDialog
                button={
                  <Button variant="contained" size="small">
                    {t('connection', { ns: 'cta' })}
                  </Button>
                }
              />
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {isDrawer && <Drawer open={open} handleDrawerClose={handleDrawerClose} />}
      <Box component="main" sx={{ ...styles.main, p: isDrawer ? (Device.isMobile ? 1 : 3) : null }}>
        <DrawerHeader />
        <Outlet />
        <Stack mb={2}>
          <DrawerHeader />
        </Stack>
      </Box>
    </Box>
  );
}
