import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useTheme } from '@mui/material/styles';

import { useSession } from '@hooks';
import { useAppContext } from '@context';

interface ListMenuProps {
  setSelected: (value: string) => void;
}

export default function ListMenu(props: ListMenuProps) {
  const { setSelected } = props;
  const { account } = useSession();
  const { state } = useAppContext();
  const theme = useTheme();
  const [selectedMenu, setSelectedMenu] = React.useState<string>('events-created');
  const [open, setOpen] = React.useState({
    events: true,
    spots: false,
    idoles: false,
    friends: false,
  });

  const handleClickCollapse = (event: React.MouseEvent<HTMLElement>) => {
    const dataId = event.currentTarget.getAttribute('data-id') as keyof typeof open;

    if (dataId && open[dataId] === false) {
      setOpen({
        events: false,
        spots: false,
        idoles: false,
        friends: false,
        [dataId]: true,
      });
    }
  };

  const handleClickItem = (event: React.MouseEvent<HTMLElement>) => {
    const dataId = event.currentTarget.getAttribute('data-id') as keyof typeof open;
    setSelected(dataId);
    setSelectedMenu(dataId);
  };

  if (!state.consumer) {
    return null;
  }

  const isOwner = account?.id && account?.id === state.consumer?.id;

  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        width: '20%',
      }}
      component="nav"
      dense
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton
        data-id="events"
        onClick={handleClickCollapse}
        sx={{ cursor: open.events ? 'default' : 'pointer' }}
      >
        <ListItemText primary="Events" />
        {open.events ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.events} timeout="auto" unmountOnExit>
        {isOwner ? (
          <List component="div" dense disablePadding>
            <ListItemButton sx={{ pl: 3 }} data-id="events-favorite" onClick={handleClickItem}>
              <ListItemText
                secondary="favoris"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-favorite' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
            <ListItemButton sx={{ pl: 3 }} data-id="events-accepted" onClick={handleClickItem}>
              <ListItemText
                secondary="acceptés"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-accepted' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-pending" onClick={handleClickItem}>
              <ListItemText
                secondary="en attente"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-pending' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-refused" onClick={handleClickItem}>
              <ListItemText
                secondary="refusés"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-refused' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
            <ListItemButton sx={{ pl: 3 }} data-id="events-created" onClick={handleClickItem}>
              <ListItemText
                secondary="créés"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-created' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-draft" onClick={handleClickItem}>
              <ListItemText
                secondary="brouillons"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-draft' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-deleted" onClick={handleClickItem}>
              <ListItemText
                secondary="supprimés"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-deleted' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
          </List>
        ) : (
          <List component="div" dense disablePadding>
            <ListItemButton sx={{ pl: 3 }} data-id="events-upcoming" onClick={handleClickItem}>
              <ListItemText
                secondary="à venir"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-upcoming' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-passed" onClick={handleClickItem}>
              <ListItemText
                secondary="passés"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-passed' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
            <ListItemButton sx={{ pl: 3 }} data-id="events-common" onClick={handleClickItem}>
              <ListItemText
                secondary="en commun"
                secondaryTypographyProps={{
                  color: selectedMenu === 'events-common' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
          </List>
        )}
      </Collapse>

      <ListItemButton
        data-id="spots"
        onClick={handleClickCollapse}
        sx={{ cursor: open.spots ? 'default' : 'pointer' }}
      >
        <ListItemText primary="Spots" />
        {open.spots ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.spots} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          <ListItemButton sx={{ pl: 3 }} data-id="spots-followed" onClick={handleClickItem}>
            <ListItemText
              secondary="suivis"
              secondaryTypographyProps={{
                color: selectedMenu === 'spots-followed' ? 'text.primary' : 'text.secondary',
              }}
            />
          </ListItemButton>
          <ListItemButton sx={{ pl: 3 }} data-id="spots-booked" onClick={handleClickItem}>
            <ListItemText
              secondary="déjà réservés"
              secondaryTypographyProps={{
                color: selectedMenu === 'spots-booked' ? 'text.primary' : 'text.secondary',
              }}
            />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton
        data-id="idoles"
        onClick={handleClickCollapse}
        sx={{ cursor: open.idoles ? 'default' : 'pointer' }}
      >
        <ListItemText primary="Idoles" />
        {open.idoles ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.idoles} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          <ListItemButton sx={{ pl: 3 }} data-id="idoles-followed" onClick={handleClickItem}>
            <ListItemText
              secondary="suivis"
              secondaryTypographyProps={{
                color: selectedMenu === 'idoles-followed' ? 'text.primary' : 'text.secondary',
              }}
            />
          </ListItemButton>
          <ListItemButton sx={{ pl: 3 }} data-id="idoles-seen" onClick={handleClickItem}>
            <ListItemText
              secondary="déjà vues"
              secondaryTypographyProps={{
                color: selectedMenu === 'idoles-seen' ? 'text.primary' : 'text.secondary',
              }}
            />
          </ListItemButton>
        </List>
      </Collapse>

      <ListItemButton
        data-id="friends"
        onClick={handleClickCollapse}
        sx={{ cursor: open.friends ? 'default' : 'pointer' }}
      >
        <ListItemText primary="Amis" />
        {open.friends ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open.friends} timeout="auto" unmountOnExit>
        <List component="div" dense disablePadding>
          <ListItemButton sx={{ pl: 3 }} data-id="friends-accepted" onClick={handleClickItem}>
            <ListItemText
              secondary="tous"
              secondaryTypographyProps={{
                color: selectedMenu === 'friends-accepted' ? 'text.primary' : 'text.secondary',
              }}
            />
          </ListItemButton>
          {isOwner ? (
            <>
              <ListItemButton sx={{ pl: 3 }} data-id="friends-pending" onClick={handleClickItem}>
                <ListItemText
                  secondary="en attente"
                  secondaryTypographyProps={{
                    color: selectedMenu === 'friends-pending' ? 'text.primary' : 'text.secondary',
                  }}
                />
              </ListItemButton>
              <ListItemButton sx={{ pl: 3 }} data-id="friends-sent" onClick={handleClickItem}>
                <ListItemText
                  secondary="demandes envoyées"
                  secondaryTypographyProps={{
                    color: selectedMenu === 'friends-sent' ? 'text.primary' : 'text.secondary',
                  }}
                />
              </ListItemButton>
            </>
          ) : (
            <ListItemButton sx={{ pl: 3 }} data-id="friends-common" onClick={handleClickItem}>
              <ListItemText
                secondary="en commun"
                secondaryTypographyProps={{
                  color: selectedMenu === 'friends-common' ? 'text.primary' : 'text.secondary',
                }}
              />
            </ListItemButton>
          )}
        </List>
      </Collapse>
    </List>
  );
}
