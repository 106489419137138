// APP TEXT
export const APP_TITLE = "Festayre";
export const FOOTER_TEXT = `${new Date().getFullYear()} Built with ♡ by Festayre`;
// METNU TITLE
export const MENU_TITLE_HOME = "Accueil";
export const MENU_TITLE_NEWS = "Actualités";
export const MENU_TITLE_ACCOUNT = "Compte";
export const MENU_TITLE_REGISTER = "Inscription";
export const MENU_TITLE_EVENTS = "Évènements";
export const MENU_TITLE_FERIA = "Férias";
export const MENU_TITLE_CASETAS = "Casetas";
export const MENU_TITLE_FAIR = "Foires";
export const MENU_TITLE_GARAGE_SCALE = "Vide-greniers";
export const MENU_TITLE_PARTY = "Fêtes";
export const MENU_TITLE_MARKET = "Marchés";
export const MENU_TITLE_OUTING = "Sorties";
export const MENU_TITLE_PRESTATION = "Prestations";
export const MENU_TITLE_SHOW = "Spectacles";
export const MENU_TITLE_SPORT = "Sports";
export const MENU_TITLE_FAQ = "FAQ";
export const MENU_TITLE_DASHBOARD = "Gérer";
// PAGES TITLE
export const PAGE_TITLE_ACCOUNT = "Mon compte";
export const PAGE_TITLE_DASHBOARD = "Tableau de bord";
export const PAGE_TITLE_EVENTS = "Évènements";
export const PAGE_TITLE_LOGIN = "Connextion";
export const PAGE_TITLE_PLACE = "Lieux";
export const PAGE_TITLE_HOME = "Évènements à venir - Festayre";
export const PAGE_TITLE_REGISTER = "Inscription";
export const PAGE_TITLE_SEARCH = "Recherche";
export const PAGE_TITLE_SETTINGS = "Réglages";
export const PAGE_TITLE_NOT_FOUND = "Lost in space";
// DASHBOARD
export const DISPLAY_FEW_ITEMS = 8;
// UI CONSTANTS
export const FOOTER_HEIGHT = 30;
export const HEADER_HEIGHT = 60;
export const DRAWER_WIDTH = 250;
export const BASE_URL = "http://localhost:64001";
// TIMES
export const SECOND = 1000;
export const MINUTE = SECOND * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;
// LOGIN
export const DEFAULT_PASSWORD = "******";
export const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 Mo for upload

// COVERS
export const HEADER_COVER_FERIA = "https://images.unsplash.com/photo-1533508680267-2f36a7de5527?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=927&q=80";
export const HEADER_COVER_CASETAS = "https://images.unsplash.com/photo-1640251314219-be6eb294446b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2408&q=80";
export const HEADER_COVER_FAIR = "https://images.unsplash.com/photo-1623788452350-4c8596ff40bb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80";
export const HEADER_COVER_GARAGE_SCALE = "https://images.unsplash.com/photo-1464059728276-d877187d61a9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80";
export const HEADER_COVER_PARTY = "https://images.unsplash.com/photo-1597668900045-b9283c0de174?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80";
export const HEADER_COVER_MARKET = "https://images.unsplash.com/photo-1533900298318-6b8da08a523e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2340&q=80";
export const HEADER_COVER_OUTING = "https://images.unsplash.com/photo-1455906876003-298dd8c44ec8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3476&q=80";
export const HEADER_COVER_PRESTATION = "https://images.unsplash.com/photo-1528605105345-5344ea20e269?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2370&q=80";
export const HEADER_COVER_SHOW = "https://images.unsplash.com/photo-1470229722913-7c0e2dbbafd3?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2370&q=80";
export const HEADER_COVER_SPORT = "https://images.unsplash.com/photo-1524646349956-1590eacfa324?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2370&q=80";