import * as React from 'react';
import Stack from '@mui/material/Stack';

import ListMenu from './ListMenu';
import ListEvents from './ListEvents';
import ListFriends from './ListFriends';
import ListSpots from './ListSpots';

export default function Stats() {
  const [selected, setSelected] = React.useState('events-created');

  const listing = {
    events: <ListEvents type={selected.split('-')[1]} />,
    friends: <ListFriends type={selected.split('-')[1] as 'accepted' | 'pending' | 'sent'} />,
    spots: <ListSpots type={selected.split('-')[1]} />,
  };

  return (
    <Stack direction="row" spacing={2}>
      <ListMenu setSelected={setSelected} />
      <Stack spacing={1} width="75%">
        {listing[selected.split('-')[0] as keyof typeof listing]}
      </Stack>
    </Stack>
  );
}
