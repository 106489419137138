import { useTheme, useMediaQuery } from "@mui/material";
import { TImageSizeType } from "types";

const useScreenSize = (): TImageSizeType => {
  const theme = useTheme();
  const isScreenVerySmall = useMediaQuery(theme.breakpoints.up("xs"));
  const isScreenSmall = useMediaQuery(theme.breakpoints.up("sm"));
  const isScreenMedium = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenLarge = useMediaQuery(theme.breakpoints.up("xl"));

  if (isScreenLarge) {
    return "xl";
  } else if (isScreenMedium) {
    return "md";
  } else if (isScreenSmall) {
    return "sm";
  } else if (isScreenVerySmall) {
    return "xs";
  } else {
    return "thumb";
  }
};

export default useScreenSize;
