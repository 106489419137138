import { Theme } from "@mui/material";

const useStyles = (theme: Theme) => ({
  root: {
    width: "100%",
  },
  visibilityIcon: {
    cursor: "pointer",
    color: "gray",
  },
});

export default useStyles;
