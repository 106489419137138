import * as React from 'react';
import { useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import { useSession } from '@hooks';
import { Pause, Request, Booking, Event, Feedback } from './components';

interface handleCloseDialogrops {
  handleCloseDialog: () => void;
}

export function NotificationSettingsDialog(props: handleCloseDialogrops) {
  const { handleCloseDialog } = props;
  const theme = useTheme();
  const { account } = useSession();

  if (!account) {
    return null;
  }

  return (
    <Stack p={3}>
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="middle">
          {<theme.icons.notificationEdit fontSize="large" />}
          <Typography variant="h5" fontWeight="bold" width="100%">
            Réglages des notifications
          </Typography>
          <theme.icons.close onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} color="disabled" />
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ backgroundColor: theme.palette.grey[800] }} />
      <DialogContent sx={{ mt: 3 }}>
        <Stack spacing={5}>
          <Pause />
          <Divider variant="middle" sx={{ backgroundColor: theme.palette.grey[800] }} />
          <Request />
          <Booking />
          <Event />
          <Feedback />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ p: theme.spacing(10, 3, 3, 0) }}>
        <Button
          fullWidth
          onClick={handleCloseDialog}
          color="uncolored"
          variant="contained"
          startIcon={<theme.icons.close />}
        >
          Fermer
        </Button>
      </DialogActions>
    </Stack>
  );
}
