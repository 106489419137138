import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

export default function EmptyState() {
  const theme = useTheme();

  return (
    <Stack
      width="50%"
      height="100%"
      alignItems="center"
      justifyContent="center"
      p={2}
      sx={{ backgroundColor: theme.palette.grey[800], borderRadius: theme.borderRadius }}
    >
      <Typography variant="body2" fontSize={{ xs: '.6rem', sm: '1rem' }} align="center">
        Auncun nouveau compte sur cette période
      </Typography>
    </Stack>
  );
}
