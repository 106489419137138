import Stack from '@mui/material/Stack';

import { AccountTypeCounts } from './accountTypes';
import { LastTen } from './lastTen';

export function SupportDashboardPage() {
  return (
    <Stack spacing={1} maxWidth="100%">
      <AccountTypeCounts />
      <LastTen />
    </Stack>
  );
}
