import * as React from 'react';
import { Theme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactiveVar } from '@apollo/client';

import { showAppLoaderVar } from '@context';

export default function AppLoader() {
  const showAppLoader = useReactiveVar(showAppLoaderVar);

  return (
    <Backdrop
      sx={{
        zIndex: (theme: Theme) => theme.zIndex.modal + 1,
        backgroundColor: 'rgba(0, 0, 0, .8)',
      }}
      open={showAppLoader}
      transitionDuration={300}
    >
      <CircularProgress />
    </Backdrop>
  );
}
