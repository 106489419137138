import { Navigate } from 'react-router-dom';

interface RequireAuthProps {
  children: JSX.Element;
  isAuthenticated: boolean;
}

interface RequireRoleProps {
  children: JSX.Element;
  role: null | 'Consumer' | 'Spot' | 'Maker' | 'Support';
  allowedRoles: Array<string | null>;
  redirection?: string;
}

export function RequireRole(props: RequireRoleProps) {
  const { children, role, allowedRoles, redirection } = props;

  // handle not connected user or Consumer account
  if (!allowedRoles.includes(role) && [null, 'Consumer'].includes(role)) {
    return <Navigate to="/spots" replace />;
  }

  // handle Spot connected account
  if (!allowedRoles.includes(role) && role === 'Spot') {
    return <Navigate to="/spot/booking" replace />;
  }

  if (redirection) {
    return <Navigate to={redirection} replace />;
  }

  return children;
}

interface RequireMeRoleProps {
  children: JSX.Element;
  role: null | 'Consumer' | 'Spot' | 'Maker' | 'Support';
  allowedRoles: Array<string | null>;
  redirection?: string;
}

export function RequireMeRole(props: RequireMeRoleProps) {
  const { children, role, allowedRoles, redirection } = props;

  if (role === null) {
    return <Navigate to="/spots" replace />;
  }

  // handle not connected user or Consumer account
  if (!allowedRoles.includes(role) && [null, 'Consumer'].includes(role)) {
    return <Navigate to="/spots" replace />;
  }

  // handle Spot connected account
  if (!allowedRoles.includes(role) && role === 'Spot') {
    return <Navigate to="/spot/booking" replace />;
  }

  if (redirection) {
    return <Navigate to={redirection} replace />;
  }

  return children;
}
