import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useSession } from '@hooks';
import Title from './Title';
import Action from './Action';

export function Event() {
  const theme = useTheme();
  const { user, account } = useSession();

  return (
    <Stack width="100%">
      <Title icon={<theme.icons.booking />} title={'Évènements'} />
      <Action type="event" subtype="invitation" title="Invitations" />
      <Action type="event" subtype="full" title="Jauge pleine (complet)" />
      <Action type="event" subtype="upcoming" title="Évènements à venir" />
      <Action type="event" subtype="cancel" title="Évènements annulés" />
    </Stack>
  );
}
