import React from "react";
import { Helmet } from "react-helmet-async";
import { useTheme } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

import { APP_TITLE, PAGE_TITLE_NOT_FOUND } from "@utils";

export default function LostPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  const styles = {
    paper: {
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.borderRadius,
      boxShadow: theme.boxShadow,
    },
  };

  function CountdownTimer() {
    const [seconds, setSeconds] = React.useState(5);

    React.useEffect(() => {
      if (seconds > 0) {
        const timer = setInterval(() => {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }, 1000);

        return () => {
          clearInterval(timer);
        };
      } else {
        navigate("/", { replace: true });
      }
    }, [seconds]);

    return <div>{seconds > 1 ? <span>{seconds} secondes</span> : <span>redirection... </span>}</div>;
  }

  return (
    <>
      <Helmet>
        <title>
          {PAGE_TITLE_NOT_FOUND} | {APP_TITLE}
        </title>
      </Helmet>
      <Stack height="95vh" alignItems={'center'} justifyContent={'center'} p={5}>
        <Paper sx={styles.paper} elevation={theme.elevation}>
          <Typography color="primary" variant="h3">
            OOps 404
          </Typography>
          <Typography variant="h6">Impossible de trouver la page demandée</Typography>
          <Divider variant="middle" />
          <Typography mt={3} variant="body2">
            Tu vas être redirigé vers la page d'accueil dans
          </Typography>
          <Stack variant="body2">{CountdownTimer()}</Stack>
        </Paper>
      </Stack>
    </>
  );
}
