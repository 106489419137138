import { gql } from '@apollo/client';

export const IS_SLUG_AVAILABLE = gql`
  query isSlugAvailable($slug: String!) {
    isSlugAvailable(slug: $slug)
  }
`;

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      ... on Consumer {
        id
        name
        slug
      }
      ... on Spot {
        id
        name
        slug
      }
      ... on Maker {
        id
        name
        slug
      }
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation UpdateSpotNotification($input: UpdateSpotNotificationInput!) {
    updateSpotNotification(input: $input)
  }
`;

export const UPDATE_ACCOUNT_DESCRIPTION = gql`
  mutation UpdateAccountDescription($input: UpdateAccountDescriptionInput!) {
    updateAccountDescription(input: $input)
  }
`;

export const UPSERT_SPOT_MENU = gql`
  mutation upsertSpotMenu($input: UpsertSpotMenuInput!) {
    upsertSpotMenu(input: $input)
  }
`;