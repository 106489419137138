import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useSession } from '@hooks';
import { useAppContext } from '@context';
import { GetConsumerDetailedQuery, EventStatusEnum, GetSpotDetailedQuery } from '@graphql';

interface ListEventsProps {
  type: string;
}
interface ListEventSlotProps {
  event: GetSpotDetailedQuery['spot']['events'][0];
}

export default function ListEvents(props: ListEventsProps) {
  const { type } = props;
  const { account } = useSession();
  const { state } = useAppContext();
  const theme = useTheme();
  const [events, setEvents] = React.useState<GetConsumerDetailedQuery['consumer']['events']>([]);

  React.useEffect(() => {
    if (state.consumer?.events?.length ?? 0 > 0) {
      if (state.consumer && type === 'created') {
        setEvents(
          state.consumer.events.filter(({ status }) =>
            [EventStatusEnum.Published, EventStatusEnum.Postponed, EventStatusEnum.Canceled].includes(status)
          )
        );
      }
      if (state.consumer && type === 'draft') {
        setEvents(state.consumer?.events?.filter(({ status }) => status === EventStatusEnum.Draft));
      }
      if (state.consumer && type === 'deleted') {
        setEvents(state.consumer.events.filter(({ status }) => status === EventStatusEnum.Deleted));
      }
    }
  }, [type]);

  const emptyStateByType = {
    favorite: 'Aucun évènement favoris',
    created: 'Aucun évènement créé',
    draft: 'Aucun brouillon',
    deleted: 'Aucun évènement supprimé',
    accepted: 'Aucun évènement',
    pending: 'Aucun évènement en attente',
    upcoming: 'Aucun évènement à venir',
  };

  return events.length === 0 ? (
    <Stack width="100%">
      <Typography>{emptyStateByType[type as keyof typeof emptyStateByType]}</Typography>
    </Stack>
  ) : (
    <Stack width="100%" spacing={1}>
      {events.map((event) => (
        <ListEventSlot key={event.id} event={event} />
      ))}
    </Stack>
  );
}

function ListEventSlot(props: ListEventSlotProps) {
  const { event } = props;
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation('event');

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSearchParams({ ...searchParams, type: 'event', q: event.id }, { preventScrollReset: true });
  };

  return (
    <Link to={`?type=event&q=${event.id}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        spacing={3}
        p={1}
        direction="row"
        sx={{
          width: '100%',
          color: theme.palette.text.primary,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.palette.grey['900'],
          border: `1px solid ${theme.palette.grey['900']}`,
        }}
      >
        <Stack width="20%">
          <img
            src={event.cover || ''}
            alt="img"
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio: '1/1',
              borderRadius: theme.borderRadius * 2,
            }}
          />
        </Stack>
        <Stack spacing={1} p={1} justifyContent="space-between">
          <Typography variant="h6" fontWeight="bolder">
            {event.title}
          </Typography>
          <Typography variant="body2">{moment(event.from).format('dddd DD MMMM YYYY [à] hh:mm')}</Typography>
        </Stack>
      </Stack>
    </Link>
  );
}