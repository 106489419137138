import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useReactiveVar } from '@apollo/client';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import * as Device from 'react-device-detect';

import { loginStatusVar } from '@context';
import Login from './login';
import HeroSection from './HeroSection';
import Signup from './signup';

export interface ILoginDialogProps {
  open?: boolean;
  button?: React.ReactElement<ListItemButtonProps>;
}

export function AuthenticationDialog(props: ILoginDialogProps) {
  const theme = useTheme();
  const loginStatus = useReactiveVar(loginStatusVar);
  const { hasAccessToken, isSessionExpired, shouldLogFirst } = loginStatus;
  const [open, setOpen] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(false);
  const [loginVisible, setLoginVisible] = React.useState(true);
  const { isLandscape } = Device.useMobileOrientation();

  const isFullScreenDisplay = Device.isDesktop && !isSessionExpired;
  const isLandscapeMobile = Device.isMobileOnly && isLandscape;

  React.useEffect(() => {
    setOpen((isSessionExpired || shouldLogFirst) && !hasAccessToken);
  }, [hasAccessToken, isSessionExpired, shouldLogFirst]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = (event: React.MouseEvent, reason: string) => {
    loginStatusVar({
      ...loginStatus,
      isSessionExpired: false,
      shouldLogFirst: false,
      redirection: null,
    });

    if (!isSessionExpired && !shouldLogFirst && !hasAccessToken) {
      setOpen(false);
    }

    setTimeout(() => {
      setShowRegister(false);
      setLoginVisible(true);
    }, 1000);
  };

  return (
    <>
      {props.button && React.cloneElement(props.button, { onClick: handleClickOpen })}
      <Dialog
        scroll="body"
        fullScreen={isLandscapeMobile}
        fullWidth
        open={open}
        maxWidth={Device.isDesktop ? 'xs' : 'xl'}
        onClose={handleClickClose}
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        PaperProps={{
          sx: {
            border: '1px solid rgba(255, 255, 255, 0.2)',
          },
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        }}
      >
        <Stack p={isLandscapeMobile ? 2 : 5} height="100%" sx={{ position: 'relative' }}>
          <IconButton onClick={handleClickClose} sx={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
            <theme.icons.close sx={{ color: theme.palette.text.secondary }} />
          </IconButton>
          <Login />
        </Stack>
      </Dialog>
    </>
  );
}
