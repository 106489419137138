import * as React from 'react';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useSearchParams } from 'react-router-dom';

import { useAppContext } from '@context';
import { Consumer, useGetConsumerDetailedLazyQuery, GetConsumerDetailedQuery } from '@graphql';
import Header from './Header';
import Preview from './preview';
import Calendar from './calendar';
import Rex from './rex';
import Memories from './memories';

interface ConsumerDialogProps {
  handleClose: () => void;
  handleBack: () => void;
}

const validTabs = ['preview', 'menu', 'calendar', 'memories'];

function ConsumerDialog(props: ConsumerDialogProps) {
  const { handleClose } = props;
  const { dispatch } = useAppContext();
  const [activeTab, setActiveTab] = React.useState(0);
  const [consumer, setConsummer] = React.useState<GetConsumerDetailedQuery['consumer'] | null>(null);
  const swiperRef = React.useRef<SwiperClass>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [getConsummer] = useGetConsumerDetailedLazyQuery({
    onCompleted: ({ consumer }) => {
      dispatch({ type: 'SET_CONSUMER', payload: consumer as Consumer });
      setConsummer(consumer);
    },
  });

  React.useEffect(() => {
    const type = searchParams.get('type');
    const slug = searchParams.get('q');

    if (type === 'consumer' && slug && slug !== consumer?.slug) {
      getConsummer({ variables: { slug } });
    }
  }, [searchParams, consumer, getConsummer]);

  React.useEffect(() => {
    const slug = searchParams.get('q');
    const tab = searchParams.get('tab');
    const tabIndex = tab !== null ? validTabs.indexOf(tab) : 0;

    if (slug && (!tab || !validTabs.includes(tab as string))) {
      setSearchParams(
        {
          q: slug,
          type: 'consumer',
          tab: 'preview',
        },
        { preventScrollReset: true, replace: true }
      );
    } else if (Number.isInteger(tabIndex)) {
      setActiveTab(tabIndex);
    }
  }, [consumer, searchParams]);

  if (!consumer) {
    return null;
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    const slug = searchParams.get('q');
    setActiveTab(newTab);
    setSearchParams(
      {
        q: consumer.slug,
        type: 'consumer',
        tab: validTabs[newTab],
      },
      { preventScrollReset: true, replace: true }
    );

    if (swiperRef.current) {
      swiperRef.current.slideTo(newTab);
    }
  };

  const handleSwiperSlideChange = (swiper: any) => {
    setActiveTab(swiper.activeIndex);
  };

  const { covers } = consumer;

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack sx={{ position: 'relative' }}>
        <Header
          handleBack={props.handleBack}
          covers={covers}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
      </Stack>
      <DialogContent sx={{ p: 0 }}>
        <Stack spacing={5}>
          <Swiper
            onSlideChange={handleSwiperSlideChange}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            slidesPerView={1}
            style={{ marginTop: 0 }}
            initialSlide={activeTab}
          >
            <SwiperSlide>{activeTab === 0 && <Preview consumer={consumer} />}</SwiperSlide>
            <SwiperSlide>{activeTab === 1 && <Calendar events={consumer.events} />}</SwiperSlide>
            <SwiperSlide>{activeTab === 2 && <Memories consumer={consumer} />}</SwiperSlide>
            <SwiperSlide>{activeTab === 3 && <Rex consumer={consumer} />}</SwiperSlide>
          </Swiper>
        </Stack>
      </DialogContent>
    </Stack>
  );
}

export default React.memo(ConsumerDialog);
