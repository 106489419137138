import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { useUpdateUserMutation, GetSessionDocument } from '@graphql';
import { useToastError } from '@hooks';

export function Password() {
  const theme = useTheme();
  const { user, accounts, refetch } = useSession();
  const [account] = accounts;
  const { t } = useTranslation('cta');
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();

  if (!user) {
    return null;
  }

  const [updateUser] = useUpdateUserMutation();

  const formik = useFormik({
    initialValues: { password: '*******' },
    validationSchema: yup.object({
      password: yup.string(),
    }),
    onSubmit: (values) => {
      updateUser({
        variables: {
          input: values,
        },
        onCompleted() {
          formik.resetForm({ values: { password: '*******' } });
          refetch({ fetchPolicy: 'network-only' });
          setIsEditing(false);
        },
        onError: toastError,
      });
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={1}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography fontWeight="bold">{t('password.label', { ns: 'field' })}</Typography>
          <Stack spacing={1} direction="row">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack>
          {isEditing ? (
            <CssTextField
              required
              fullWidth
              size="small"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            ></CssTextField>
          ) : (
            <Typography color="text.secondary">*********</Typography>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

const CssTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: '#999999',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#434343',
    },
    '&:hover fieldset': {
      borderColor: '#666666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#999999',
    },
  },
}));