import { alpha, Theme } from '@mui/material';

const useStyles = (theme: Theme, coverUrl?: string) => ({
  highlight: {
    position: 'absolute',
    width: '100%',
    height: '100vh',
    backgroundImage: `
    linear-gradient(
      to bottom,
      rgba(20, 20, 20, 0) 80%,
      rgba(20, 20, 20, 1) 100%
    ),
    url('${coverUrl?.replace('900', '1790')?.replace('500', '975')}')`,
    // backgroundImage: `
    //   linear-gradient(
    //     to right,
    //     rgba(20, 20, 20, .7) 20%,
    //     rgba(20, 20, 20, .5) 50%,
    //     rgba(20, 20, 20, 0) 100%
    //   ),
    //   linear-gradient(
    //     to bottom,
    //     rgba(20, 20, 20, 1) 0%,
    //     rgba(20, 20, 20, 0) 15%,
    //     rgba(20, 20, 20, 0) 50%,
    //     rgba(20, 20, 20, 0) 85%,
    //     rgba(20, 20, 20, 1) 100%
    //   ),
    //   url('${coverUrl?.replace('900', '1790')?.replace('500', '975')}')
    // `,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
});

export default useStyles;
