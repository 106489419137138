import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';

export function Address() {
  const theme = useTheme();
  const { user, accounts } = useSession();
  const [account] = accounts;
  const { t } = useTranslation('field');

  return (
    <Stack width="100%">
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Typography fontWeight="bold">{t('direction.label')}</Typography>
        <Button variant="contained" color="uncolored" size="small">
          {t('edit', { ns: 'cta' })}
        </Button>
      </Stack>
      <Stack>
        <Typography color="text.secondary">{null}</Typography>
      </Stack>
    </Stack>
  );
}
