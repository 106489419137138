import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar, useAppContext } from '@context';
import { useHandleFriendMutation, GetConsumerDetailedQuery } from '@graphql';
import { useSession, useToastError } from '@hooks';

interface FriendPendingButtonProps {
  action: 'accept' | 'reject';
  friend:
    | GetConsumerDetailedQuery['consumer']['friends']['accepted'][0]
    | GetConsumerDetailedQuery['consumer']['friends']['pending'][0];
}

export default function FriendPendingButton(props: FriendPendingButtonProps) {
  const { friend, action } = props;
  const { state, dispatch } = useAppContext();
  const { user } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  if (!state.consumer) {
    return null;
  }

  const [handleFriend] = useHandleFriendMutation({
    variables: { accountId: friend.id, action },
    onCompleted: ({ handleFriend }) => {
      if (handleFriend === true) {
        dispatch({
          type: `${action.toUpperCase()}_FRIEND` as 'ACCEPT_FRIEND' | 'REJECT_FRIEND',
          payload: { accountId: friend.id },
        });
      }
    },
    onError: useToastError(),
  });

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (!user) {
      return loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    }
    handleFriend();
  };

  return (
    <Button
      size="small"
      onClick={handleClick}
      color={action === 'accept' ? 'success' : 'error'}
      variant={action === 'accept' ? 'contained' : 'outlined'}
      startIcon={action === 'accept' ? <theme.icons.check /> : <theme.icons.close />}
    >
      {t(action)}
    </Button>
  );
}
