import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { useSpotSchedulesQuery, GetBookingQuery } from '@graphql';
import BookingDetail from './BookingDetail';
import EditBooking from '../forms/edit-booking-form';

interface BookingTabsProps {
  booking: GetBookingQuery['booking'];
}

export default function BookingTabs(props: BookingTabsProps) {
  const { booking } = props;
  const [isEdit, setIsEdit] = React.useState(false);
  const [value, setValue] = React.useState('1');
  const { t } = useTranslation('booking');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleEdit = () => {
    setIsEdit(!isEdit);
  };

  const { data, loading } = useSpotSchedulesQuery({
    variables: {
      slug: booking.spot.slug,
    },
  });

  if (loading) {
    return (
      <Stack justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  if (!data?.spotSchedules) {
    return null;
  }

  return (
    <Stack>
      {isEdit ? (
        <EditBooking booking={booking} spot={data.spotSchedules} handleEdit={handleEdit} />
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={handleChange}
              aria-label="booking tabs"
              textColor="inherit"
              indicatorColor="primary"
            >
              <Tab label="Booking" value="1" />
              <Tab label="Échanges" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <BookingDetail booking={booking} handleEdit={handleEdit} />
          </TabPanel>
          <TabPanel value="2">{t('exchanges.empty state')}</TabPanel>
        </TabContext>
      )}
    </Stack>
  );
}
