import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';

import { useConnection, useSession } from '@hooks';
import { useSignupMutation, GenderEnum, AccountTypeEnum } from 'graphql';
import initValues from './initValues';
import validationSchema from './validationSchemas';
import FormFields from './FormFields';
import Stepper from './Stepper';
import { getSteps } from './helpers';

interface SignupFormProps {
  type: 'consumer' | 'spot' | 'artist';
  handleBack: () => void;
}

export default function SignupForm(props: SignupFormProps) {
  const { handleBack, type } = props;
  const { user } = useSession();
  const theme = useTheme();
  const { login } = useConnection();
  const [isSignupSuccess, setIsSignupSuccess] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const { t } = useTranslation('field');

  const { steps, stepsCaptions, stepFieldValidations } = getSteps(type);

  const [signup] = useSignupMutation();

  const formik = useFormik({
    validateOnMount: true,
    initialValues: initValues(type),
    validationSchema: validationSchema(type, t),
    onSubmit: async (values) => {
      const validValues = {
        isSSO: false,
        type: type.toUpperCase() as AccountTypeEnum,
        email: values.email,
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
        mobilePhone: values.mobilePhone,
        gender: values.gender.toUpperCase() as GenderEnum,
        birthdate: moment(values.birthdate).toISOString(),
        siret: values.siret,
      };

      signup({
        variables: { input: validValues },
        onCompleted() {
          setIsSignupSuccess(true);
        },
        onError(error) {
          // @ts-expect-error networkError is not a property of ServerError
          const networkError = error?.networkError?.result?.errors?.[0]?.message;

          if (networkError === 'EMAIL_ALREADY_REGISTERED') {
            formik.setErrors({ email: 'Email déjà enregistré' });
          } else if (networkError === 'SIRET_ALREADY_REGISTRED') {
            formik.setErrors({ siret: 'Établissemnt déjà enregistré' });
          }
        },
      });
    },
  });

  React.useEffect(() => {
    if (activeStep !== steps.length) {
      // avoid checking the last step (summary)
      const isStepFieldValid = stepFieldValidations[activeStep].every(
        (field) => !formik.errors[field as keyof typeof formik.errors]
      );

      setCompleted({ ...completed, [activeStep]: isStepFieldValid });
    }
  }, [activeStep, formik.values, formik.errors]);

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleLogin = async () => {
    await login({
      variables: {
        input: {
          email: formik.values.email,
          password: formik.values.password,
        },
      },
    });
    formik.resetForm({ values: initValues(type) });
  };

  return !isSignupSuccess ? (
    <form
      onSubmit={formik.handleSubmit}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack
        width="100%"
        maxWidth={{ sx: '400px', sm: '500px', md: '600px' }}
        spacing={10}
        justifyContent="center"
        alignItems="center"
      >
        {activeStep < steps.length && (
          <Stepper
            steps={steps}
            stepsCaptions={stepsCaptions}
            activeStep={activeStep}
            completed={completed}
            handleStep={handleStep}
          />
        )}
        <FormFields type={type} activeStep={activeStep} formik={formik} />
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Button
            variant="contained"
            color="uncolored"
            type="button"
            disabled={activeStep === 0}
            onClick={handleBackStep}
            sx={{ display: activeStep === 0 ? 'none' : 'block' }}
          >
            {t('back', { ns: 'cta' })}
          </Button>
          {activeStep < steps.length ? (
            <Button
              variant="contained"
              type="button"
              onClick={handleNext}
              disabled={activeStep !== steps.length ? !completed[activeStep] : !formik.isValid}
              color="success"
              sx={{ ml: 'auto' }}
              startIcon={activeStep === steps.length - 1 ? <theme.icons.summary /> : null}
            >
              {activeStep === steps.length - 1 ? t('summary', { ns: 'cta' }) : t('next', { ns: 'cta' })}
            </Button>
          ) : (
            <Button
              variant="contained"
              type="button"
              onClick={() => {
                if (activeStep === steps.length) {
                  formik.handleSubmit();
                }
              }}
              disabled={!formik.isValid}
              color="success"
              sx={{ ml: 'auto' }}
              startIcon={<theme.icons.rocket />}
            >
              {t("let's go", { ns: 'cta' })}
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  ) : (
    <Stack width="100%" height="100%" justifyContent="center" alignItems="center" spacing={2}>
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Typography variant="h3" textAlign="center">
          {t('success.Congratulations', { ns: 'authentication', firstname: formik.values.firstname })} 🎉
        </Typography>
        <Typography textAlign="center">
          {t('success.Your account has been created. An email has been sent to', { ns: 'authentication' })}
        </Typography>
        <Alert icon={<theme.icons.check fontSize="inherit" />} severity="success">
          {formik.values.email}
        </Alert>
        <Typography textAlign="center">
          {t('success.You should receive it soon, it will be used to validate your account.', {
            ns: 'authentication',
          })}
        </Typography>
        <Button variant="contained" onClick={handleLogin}>
          {t('success.Close and login', { ns: 'authentication' })}
        </Button>
      </Stack>
    </Stack>
  );
}
