import * as React from 'react';
import { useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { AvatarCustom } from '@components';

interface AccountButtonProps {
  handleOpenDrawer: (event: React.MouseEvent<HTMLElement>) => void;
}

export function AccountButton(props: AccountButtonProps) {
  const { handleOpenDrawer } = props;
  const { account } = useSession();
  const theme = useTheme();

  return (
    <Tooltip title="Menu">
      <IconButton data-id="account" onClick={handleOpenDrawer}>
        {account === undefined ? (
          <CircularProgress size={25} color="uncolored" />
        ) : Device.isMobile ? (
          <theme.icons.burgerMenu />
        ) : (
          <AvatarCustom
            ressource={account?.avatar?.url || null}
            id={account?.id || ''}
            type="account"
            sx={{
              width: 30,
              height: 30,
            }}
          />
        )}
      </IconButton>
    </Tooltip>
  );
}
