import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import moment from 'moment';

export default function MobileCalendar() {
  return (
    <DateCalendar
      displayWeekNumber
      sx={{ width: '100%' }}
      defaultValue={moment()}
      views={['year', 'month', 'day']}
    />
  );
}
