import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useSession } from '@hooks';
import ConsumerDataGrid from './consumer/DataGrid';
import SpotBookingDataGrid from './spot/BookingDataGrid';
import SpotEventDataGrid from './spot/EventDataGrid';

function Table() {
  const { account } = useSession();
  const { pathname } = useLocation();
  const [feature, setFeature] = React.useState<string | null>(null);

  React.useEffect(() => {
    const [foundFeature = null] = pathname.replace('/me', '').split('/').filter(Boolean);
    setFeature(foundFeature);
  }, [pathname]);

  if (!account) {
    return null;
  }

  if (account.__typename === 'Consumer') {
    return <ConsumerDataGrid />;
  }

  if (account.__typename === 'Spot' && feature === 'booking') {
    return <SpotBookingDataGrid />;
  }

  if (account.__typename === 'Spot' && feature === 'event') {
    return <SpotEventDataGrid />;
  }
}

export default Table;
