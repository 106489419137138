import { gql } from '@apollo/client';
import { accountImageFragment } from "./image";

// export const ACCOUNT_INTERFACE_FRAGMENT = gql`
//   fragment AccountInterfaceFragment on AccountInterface {
//     __typename
//     id
//     name
//     slug
//     avatar {
//       ...AccountImageFragment
//     }
//   }
//   ${accountImageFragment}
// `;

export const guestFragment = gql`
  fragment GuestFragment on Maker {
    __typename
    id
    name
    avatar {
      ...AccountImageFragment
    }
    covers {
      ...AccountImageFragment
    }
  }
  ${accountImageFragment}
`;

export const CONVERSATION_MEMBER_FIELDS = gql`
  fragment ConversationMemberFields on Account {
    __typename
    ... on Spot {
      id
      name
      slug
      avatar {
        id
        url
        fileId
        fileName
        size
        mimeType
        createdAt
      }
    }
    ... on Consumer {
      id
      name
      slug
      avatar {
        id
        url
        fileId
        fileName
        size
        mimeType
        createdAt
      }
    }
    ... on Maker {
      id
      name
      slug
      avatar {
        id
        url
        fileId
        fileName
        size
        mimeType
        createdAt
      }
    }
  }
`;
