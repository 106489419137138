import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ThemeProvider } from '@mui/system';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import { register } from 'swiper/element/bundle';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import 'moment/dist/locale/fr';
import 'moment/dist/locale/es';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './index.css';
import './i18n';

import { AppProvider } from '@context';
import { useSession } from '@hooks';
import { lightTheme, darkTheme } from './theme/Theme';
import { buildRouter } from './routes';

export default function App() {
  const session = useSession();
  const [loading, setLoading] = React.useState(true);
  const [useDefaultTheme, toggle] = React.useReducer((theme) => !theme, true);
  let theme = createTheme(useDefaultTheme ? darkTheme : lightTheme);
  theme = responsiveFontSizes(theme);
  register();

  const helmetContext = {};

  // let time to the session to load at app initialisation
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  if (loading) {
    return (
      <Stack width="100vw" height="100vh" justifyContent="center" alignItems="center">
        <CircularProgress color="primary" />
      </Stack>
    );
  }

  return (
    <HelmetProvider context={helmetContext}>
      <AppProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <ThemeProvider theme={theme}>
            <RouterProvider future={{ v7_startTransition: true }} router={buildRouter(session)} />
          </ThemeProvider>
        </LocalizationProvider>
      </AppProvider>
    </HelmetProvider>
  );
}
