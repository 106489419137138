import * as React from 'react';
import { Theme } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { useReactiveVar } from '@apollo/client';

import { showDialogBackDropVar } from '@context';

export default function ShowDialogBackDrop({ force }: { force?: boolean }) {
  const showDialogBackDrop = useReactiveVar(showDialogBackDropVar);

  return (
    <div>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme: Theme) => theme.zIndex.appBar + Infinity,
          backgroundColor: 'rgba(0, 0, 0, .8)',
        }}
        open={force !== undefined ? force : showDialogBackDrop}
        transitionDuration={300}
      />
    </div>
  );
}
