import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { useFormik } from 'formik';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { toast } from 'react-toastify';

import 'react-quill/dist/quill.snow.css';
import './quill.css';

import { useAppContext } from '@context';
import { useCreateEventMutation, useUpdateEventMutation, MyEventsDocument } from '@graphql';
import initialValues from './initValues';
import validationSchema from './validationSchema';

export default function CreateBookingFormDialog() {
  const theme = useTheme();
  const { state, dispatch } = useAppContext();
  const { eventForm } = state;
  const { t } = useTranslation('field');
  const [open, setOpen] = React.useState(false);

  const [updateEvent] = useUpdateEventMutation({
    onCompleted: ({ updateEvent }) => {
      handleClose();
      toast(t('event.toast.update.success', { ns: 'field' }), { type: 'success' });
    },
    onError() {
      toast(t('event.toast.update.error', { ns: 'field' }), { type: 'error' });
    },
  });

  const [createEvent] = useCreateEventMutation({
    onCompleted: ({ createEvent }) => {
      handleClose();
      toast(t('event.toast.create.success', { ns: 'field' }), { type: 'success' });
    },
    onError() {
      toast(t('event.toast.create.error', { ns: 'field' }), { type: 'error' });
    },
    update: (cache, { data }) => {
      const existingEvents = cache.readQuery({ query: MyEventsDocument });
      const newEvent = data?.createEvent;

      cache.writeQuery({
        query: MyEventsDocument,
        data: {
          myEvents: [...(existingEvents as { myEvents: any[] }).myEvents, newEvent],
        },
      });
    },
  });

  const formik = useFormik({
    initialValues: initialValues(eventForm),
    validationSchema: validationSchema(t),
    onSubmit: ({ images, ...values }) => {
      // Sanitize description
      const sanitizedDescription = DOMPurify.sanitize(values.description || '');
      Object.assign(values, {
        description: sanitizedDescription.length ? sanitizedDescription : null,
      });

      if (eventForm?.id) {
        updateEvent({ variables: { input: { id: eventForm.id, ...values } } });
      } else {
        createEvent({ variables: { input: values } });
      }
    },
  });

  React.useEffect(() => {
    // seek for the h3 button
    const h3Button = document.querySelector('.ql-picker-item[data-value="3"]');

    // Verify if the element exists before modifying it
    if (h3Button) {
      h3Button.setAttribute('data-label', 'Titre'); // Modifier l'étiquette
      h3Button.innerHTML = '';
    }
  }, []);

  React.useEffect(() => {
    // refresh initialized default dates when opening the dialog
    if (eventForm !== undefined && !open) {
      formik.resetForm({ values: initialValues(eventForm) });
    }

    setOpen(eventForm !== undefined);
  }, [eventForm, formik, open]);

  const handleOpen = () => {
    dispatch({ type: 'SET_EVENT_FORM', payload: null });
  };

  const handleClose = () => {
    dispatch({ type: 'UNSET_EVENT_FORM' });
    formik.resetForm({ values: initialValues() });
  };

  return (
    <>
      <Button variant="contained" startIcon={<theme.icons.add />} onClick={handleOpen}>
        {t('create', { ns: 'cta' })}
      </Button>
      <Dialog open={open} scroll="body" onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle id="event-form-dialog-title">
          {t(`event.title.${eventForm?.id ? 'edit' : 'create'}`)}
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                required
                variant="filled"
                label={t('event.label.title')}
                name="title"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                fullWidth
              />

              <Typography>{t('event.label.description')}</Typography>
              <ReactQuill
                theme="snow"
                value={formik.values.description}
                onChange={(content) => {
                  formik.setFieldValue('description', content);
                  formik.setFieldTouched('description', content !== formik.values.description);
                }}
                placeholder="Description de l'évènement..."
                modules={{
                  toolbar: [
                    [{ header: [false, 3] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['clean'],
                  ],
                }}
              />
              <DateTimePicker
                label={t('event.label.from')}
                value={formik.values.from}
                onChange={(value) => {
                  formik.setFieldValue('from', value);
                  formik.setFieldTouched('from', !moment(value).isSame(formik.values.from));
                }}
                disablePast
                slotProps={{
                  textField: {
                    name: 'from',
                    variant: 'filled',
                    error: formik.touched.from && Boolean(formik.errors.from),
                    helperText:
                      formik.touched.from && formik.errors.from ? String(formik.errors.from) : undefined,
                    fullWidth: true,
                  },
                }}
              />
              <DateTimePicker
                label={t('event.label.to')}
                value={formik.values.to}
                onChange={(value) => {
                  formik.setFieldValue('to', value);
                  formik.setFieldTouched('to', !moment(value).isSame(formik.values.to));
                }}
                disablePast
                slotProps={{
                  textField: {
                    name: 'to',
                    variant: 'filled',
                    error: formik.touched.to && Boolean(formik.errors.to),
                    helperText: formik.touched.to && formik.errors.to ? String(formik.errors.to) : undefined,
                    fullWidth: true,
                  },
                }}
              />
              <TextField
                label={t('event.label.cover')}
                type="file"
                variant="filled"
                onChange={(event) => {
                  const target = event.currentTarget as HTMLInputElement;
                  const [file] = Array.from(target.files || []);
                  formik.setFieldValue('cover', file);
                }}
                error={formik.touched.cover && Boolean(formik.errors.cover)}
                helperText={formik.touched.cover && formik.errors.cover}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label={t('event.label.images')}
                type="file"
                variant="filled"
                inputProps={{ multiple: true }}
                onChange={(event) => {
                  const target = event.currentTarget as HTMLInputElement;
                  const files = Array.from(target.files || []);
                  formik.setFieldValue('images', files);
                }}
                error={formik.touched.images && Boolean(formik.errors.images)}
                helperText={formik.touched.images && formik.errors.images}
                fullWidth
                InputLabelProps={{ shrink: true }}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" width="100%" justifyContent="space-between" p={3}>
              <Button onClick={handleClose} color="uncolored" variant="contained">
                {t('close', { ns: 'cta' })}
              </Button>
              <Stack direction="row" spacing={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {t('save', { ns: 'cta' })}
                </Button>
              </Stack>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
