import * as React from 'react';
import { useTheme } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

import { useConnection } from '@hooks';

export default function LogoutButton() {
  const theme = useTheme();
  const { logout } = useConnection();
  const { t } = useTranslation('account');

  const handleLogout = () => () => {
    logout();
  };

  return (
    <ListItem disablePadding>
      <ListItemButton onClick={handleLogout()}>
        <ListItemIcon>
          <theme.icons.logout fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={t('menu.logout')} />
      </ListItemButton>
    </ListItem>
  );
}
