import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import Switch from '@mui/material/Switch';
import * as yup from 'yup';

import { useSession, useToastError } from '@hooks';
import { useUpdateSpotNotificationMutation } from '@graphql';

interface ActionProps {
  type: 'standby' | 'booking' | 'event' | 'feedback' | 'request';
  subtype:
    | 'all'
    | 'message'
    | 'mention'
    | 'new'
    | 'edit'
    | 'late'
    | 'invitation'
    | 'full'
    | 'upcoming'
    | 'cancel';
  title: string;
}

function Action(props: ActionProps) {
  const { type, subtype, title = 'title' } = props;
  const { account, refetch } = useSession();
  const toastError = useToastError();

  const [updateNotification] = useUpdateSpotNotificationMutation();

  // @ts-ignore
  // if (!account.notifications[type]?.[subtype]) {
  //   return null;
  // }

  const initialValues = React.useMemo(
    () => ({
      [subtype]: {
        // @ts-ignore
        email: account.notifications[type][subtype].email,
        // @ts-ignore
        push: account.notifications[type][subtype].push,
      },
    }),
    [account?.notifications, subtype, type]
  );

  const validationSchema = React.useMemo(
    () =>
      yup.object({
        [subtype]: yup.object({
          email: yup.boolean(),
          push: yup.boolean(),
        }),
      }),
    [subtype]
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateNotification({
        variables: {
          input: {
            [type]: values,
          },
        },
        onCompleted() {
          formik.resetForm({ values });
        },
        onError: toastError,
      });
    },
  });

  const handleSwitchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      formik.handleChange(event);
      formik.submitForm();
    },
    [formik]
  );

  if (!account || !['Consumer', 'Spot'].includes(account?.__typename ?? '') || !account.notifications) {
    return null;
  }

  return (
    <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center">
      <Stack direction="row" width="70%" justifyContent="flex-start" alignItems="center">
        <Typography variant="caption" color="text.secondary">
          {title}
        </Typography>
      </Stack>
      <Switch
        name={subtype + '.email'}
        disabled={type !== 'standby' && account.notifications.standby.all.email}
        checked={formik.values[subtype].email}
        onChange={handleSwitchChange}
        color={'success'}
        sx={{
          opacity: type !== 'standby' && account.notifications.standby.all.email ? 0.5 : 1,
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Switch
        name={subtype + '.push'}
        disabled={type !== 'standby' && account.notifications.standby.all.push}
        checked={formik.values[subtype].push}
        onChange={handleSwitchChange}
        color={'success'}
        sx={{
          opacity: type !== 'standby' && account.notifications.standby.all.push ? 0.5 : 1,
        }}
        inputProps={{ 'aria-label': 'controlled' }}
      />
    </Stack>
  );
}

export default Action;
