import * as React from 'react';
import moment, { Moment } from 'moment';
import { useTheme } from '@mui/material/styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

// import { useMyBookingsQuery, MyBookingsQuery } from '@graphql';
import CalendarRenderDay from './CalendarRenderDay';

interface WeekPickerProps {
  handleDateRangeChange: (dateRange: { from: string; to: string }) => void;
}

export default function WeekPicker(props: WeekPickerProps) {
  const { handleDateRangeChange } = props;
  const theme = useTheme();
  const [hoveredDay, setHoveredDay] = React.useState<Moment | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment().startOf('isoWeek'));
  // const [weekBookings, setWeekBookings] = React.useState<MyBookingsQuery['myBookings'] | []>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showCalendar = Boolean(anchorEl);
  const { t } = useTranslation('cta');

  // Set the date range based on the selected date
  const dateRange = {
    from: selectedDate?.startOf('isoWeek').toISOString(),
    to: selectedDate?.endOf('isoWeek').toISOString(),
  };

  // const { data, loading } = useMyBookingsQuery({
  //   variables: { dateRange },
  //   fetchPolicy: 'cache-and-network',
  // });

  // React.useEffect(() => {
  //   if (data && data.myBookings) {
  //     setWeekBookings(data.myBookings);
  //   }
  // }, [data]);

  React.useEffect(() => {
    if (selectedDate) {
      const dateRange = {
        from: selectedDate.startOf('isoWeek').toISOString(),
        to: selectedDate.endOf('isoWeek').toISOString(),
      };
      handleDateRangeChange(dateRange);
    }
  }, [selectedDate]);

  const handleOpenAgenda = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAgenda = () => {
    setAnchorEl(null);
  };

  const handleGoToCurrentWeek = () => {
    setSelectedDate(moment().startOf('isoWeek'));
  };

  const handlePreviousWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedDate((prev) => prev?.clone().subtract(1, 'week') || moment());
  };

  const handleNextWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedDate((prev) => prev?.clone().add(1, 'week') || moment());
  };

  const weekNumber = selectedDate?.isoWeek() || moment().isoWeek();
  const weekStart = selectedDate?.startOf('isoWeek').format('DD MMM') || '';
  const weekEnd = selectedDate?.endOf('isoWeek').format('DD MMM YYYY') || '';

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center">
        <Stack direction="row" spacing={2}>
          <>
            <Button
              variant="contained"
              color="uncolored"
              id="basic-button"
              aria-controls={showCalendar ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={showCalendar ? 'true' : undefined}
              onClick={handleOpenAgenda}
              startIcon={<theme.icons.calendar />}
            >
              {t('calendar')}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={showCalendar}
              onClose={handleCloseAgenda}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => {
                  setAnchorEl(null);
                  if (newValue) {
                    // Ensure to start at the beginning of the week
                    setSelectedDate(newValue.startOf('isoWeek'));
                  }
                }}
                showDaysOutsideCurrentMonth
                displayWeekNumber
                slots={{ day: CalendarRenderDay }}
                slotProps={{
                  day: (ownerState) => ({
                    selectedDay: selectedDate,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                  }),
                }}
              />
            </Menu>
          </>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGoToCurrentWeek}
            startIcon={<theme.icons.week />}
            disabled={selectedDate?.isSame(moment().startOf('isoWeek'), 'week')}
          >
            {t('this week')}
          </Button>
        </Stack>
      </Stack>

      <Paper
        elevation={3}
        sx={{
          p: 3,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            color="uncolored"
            startIcon={<theme.icons.back />}
            onClick={handlePreviousWeek}
          >
            {t('previous week')}
          </Button>
          <Typography variant="h6">
            S#{weekNumber} | du {weekStart} au {weekEnd}
          </Typography>
          <Button
            variant="contained"
            color="uncolored"
            endIcon={<theme.icons.chevron />}
            onClick={handleNextWeek}
          >
            {t('next week')}
          </Button>
        </Stack>
      </Paper>
    </Stack>
  );
}
