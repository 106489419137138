import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';

import { useSession } from '@hooks';
import Title from './Title';
import Action from './Action';

export function Feedback() {
  const theme = useTheme();
  const { user, account } = useSession();

  return (
    <Stack width="100%">
      <Title icon={<theme.icons.feedback />} title={'Avis'} />
      <Action type="feedback" subtype="new" title="Nouvel avis" />
    </Stack>
  );
}
