import * as React from 'react';
import { Stack, Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListHomeMakersQuery, Maker } from '@graphql';
import { useAppContext } from '@context';
import { truncateText } from '@utils';

interface MakerSlideProps {
  maker: ListHomeMakersQuery['homeMakers'][0]['makers'][0];
}

const MakerSlide = function (props: MakerSlideProps) {
  const { maker } = props;
  const theme = useTheme();
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('spot');

  const transition = {
    transitionDuration: '.3s',
    transitionTimingFunction: 'ease-in-out',
  };

  const randNum = Math.floor(Math.random() * 101);
  const { id, slug, name, covers } = maker;
  const defaultCover = `https://picsum.photos/600/400?random=${randNum}`;
  const address = maker.address?.label || 'adresse inconnue';
  const city = maker.address?.city || 'Ville inconnue';

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch({ type: 'SET_MAKER', payload: maker as Maker });
    setSearchParams({ type: 'spot', q: slug, tab: 'preview' }, { preventScrollReset: true });
  };

  return (
    <Link to={`?type=spot&q=${slug}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        alignItems="center"
        sx={{
          position: 'relative',
          minWidth: { xs: '300px', sm: '350px' },
          height: 'auto',
          aspectRatio: '4/3',
          borderRadius: theme.spacing(1),
          color: theme.palette.text.primary,
          '&:hover': {
            cursor: 'pointer',
            '.container': {
              zIndex: 1,
              transformOrigin: 'center',
              transform: 'scale(1.4)',
              borderRadius: theme.borderRadius,
              boxShadow: '0px 0px 20px rgba(0, 0, 0, 1)',
              ...transition,
            },
            '.top-infos': {
              height: 40,
              ...transition,
            },
            '.top-infos__title ': {
              fontSize: '.9rem',
              ...transition,
            },
            '.top-extras-infos': {
              opacity: 1,
              ...transition,
            },
            '.img': {
              width: '95%',
              boxShadow: '0px 5px 10px rgba(0, 0, 0, .5)',
              borderRadius: theme.borderRadius,
              ...transition,
            },
            '.bottom-infos': {
              height: 40,
              ...transition,
            },
            '.bottom-extras-infos': {
              span: {
                fontSize: '.6rem',
              },
              opacity: 1,
              ...transition,
            },
          },
        }}
      >
        <Stack
          className="container"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            zIndex: 0,
            position: 'absolute',
            width: '100%',
            borderRadius: theme.borderRadius,
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, .3)',
            ...transition,
          }}
        >
          <Stack
            className="top-infos"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '90%',
              height: 30,
              ...transition,
              mb: 1,
              mt: 1.5,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography className="top-infos__title" fontSize=".9rem" fontWeight="bold" sx={transition}>
                {truncateText(name)}
              </Typography>
            </Stack>
            <Stack
              className="top-extras-infos"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                width: '100%',
                bottom: 0,
                opacity: 0,
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem" sx={transition}>
                {t('word.open', { ns: 'common' })}
              </Typography>
              <Stack direction="row" justifyContent="flex-start" alignItems="center">
                {<theme.icons.price fontSize="small" />}
                <Typography
                  variant="body 2"
                  color="text.secondary"
                  fontSize=".6rem"
                  fontWeight="bold"
                  sx={transition}
                >
                  {/* {t('price.' + priceRange)} */}
                  price
                </Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '100%',
              aspectRatio: '16/9',
              ...transition,
            }}
          >
            <Box
              className="img"
              component="img"
              src={covers?.medias?.[0].originalUrl || defaultCover}
              alt={name + ' cover'}
              sx={{
                width: '100%',
                aspectRatio: '16/9',
                objectFit: 'cover',
                objectPosition: 'center',
                ...transition,
              }}
            />
          </Stack>
          <Stack
            className="bottom-infos"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '90%',
              height: 30,
              ...transition,
              mb: 1,
              mt: 1,
            }}
          >
            <Stack
              className="bottom-extras-infos"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                width: '100%',
                top: 0,
                opacity: 0,
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem">
                {/* {atmosphere} */}
                atmosphere
              </Typography>
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem">
                {address.split(',')[0]}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography
                className="bottom-infos__subtitle"
                variant="body 2"
                color="text.secondary"
                fontSize=".7rem"
                fontWeight="bold"
                sx={transition}
              >
                {/* {truncateText(types)} */}
                type
              </Typography>
              <Typography
                className="bottom-infos__subtitle"
                variant="body 2"
                color="text.secondary"
                fontSize=".7rem"
                sx={transition}
              >
                {city}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
};

export default MakerSlide;
