import * as React from 'react';
import Stack from '@mui/material/Stack';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useGetEventDetailedLazyQuery } from '@graphql';
import { Description, FAQ, FindUs, Tags } from '../common';
import Header from './Header';
import Preview from './preview';
import Tickets from './Tickets';

interface EventDialogProps {
  handleClose: () => void;
  handleBack: () => void;
}

const EventDialog = (props: EventDialogProps) => {
  const [searchParams] = useSearchParams();
  const ticketsRef = React.useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const padding = isMobile ? 1 : 3;

  const [getEvent, { data }] = useGetEventDetailedLazyQuery({
    fetchPolicy: 'cache-first',
  });

  const scrollToTickets = () => {
    if (ticketsRef.current) {
      ticketsRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    const type = searchParams.get('type');
    const id = searchParams.get('q');

    if (type === 'event' && id) {
      getEvent({ variables: { id } });
    }
  }, [searchParams, getEvent]);

  if (!data?.event) {
    return null;
  }

  const { covers } = data.event;

  return (
    <Stack sx={{ position: 'relative' }}>
      <Stack sx={{ position: 'relative' }}>
        <Header handleBack={props.handleBack} covers={covers} />
      </Stack>
      <Stack spacing={5} p={padding}>
        <Preview event={data.event} handleClose={props.handleClose} scrollToTickets={scrollToTickets} />
      </Stack>
      <Stack spacing={5} p={padding}>
        <Description description={data.event.description} />
        <Tickets tickets={data.event.tickets} ticketsRef={ticketsRef} />
        <FindUs lng={data.event.address?.longitude} lat={data.event.address?.latitude} />
        <FAQ FAQs={data.event.FAQs} />
        <Tags tags={data.event.tags} />
      </Stack>
    </Stack>
  );
};

export default EventDialog;
