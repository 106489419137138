import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material';
import Badge from '@mui/material/Badge';

import { useSession, useToastError } from '@hooks';
import {
  useListNotificationsQuery,
  ListNotificationsQuery,
  useOnNotificationUpdateSubscription,
} from '@graphql';

interface NotificationButtonProps {
  handleOpenDrawer: (event: React.MouseEvent<HTMLElement>) => void;
}

export function NotificationButton(props: NotificationButtonProps) {
  const { handleOpenDrawer } = props;
  const [notifications, setNotifications] = React.useState<ListNotificationsQuery['notifications']>([]);
  const [unreadNotifiations, setUnreadNotifiations] = React.useState(0);
  const { account } = useSession();
  const theme = useTheme();
  const toastError = useToastError();

  useListNotificationsQuery({
    skip: !account,
    onError: toastError,
    onCompleted: ({ notifications }) => {
      setNotifications(notifications);
      const unread = notifications.filter((n) => !n.isRead).length;
      setUnreadNotifiations(unread);
    },
  });

  useOnNotificationUpdateSubscription({
    skip: !account,
    shouldResubscribe(options) {
      return false;
    },
    onError: toastError,
    onData: ({ data }) => {
      if (data.data?.notificationUpdate) {
        const { notificationUpdate } = data.data;
        const exists = notifications.findIndex((n) => n.id === notificationUpdate.id);
        if (exists !== -1) {
          setNotifications((prev) => {
            return prev.map((n) => {
              return n.id === notificationUpdate.id ? notificationUpdate : n;
            });
          });
        } else {
          setNotifications([...notifications, notificationUpdate]);
          setUnreadNotifiations((prev) => prev + 1);
        }
      }
    },
  });

  if (!account) {
    return null;
  }

  return (
    <Tooltip title="Notiications">
      <IconButton data-id="notiication" onClick={handleOpenDrawer}>
        <Badge color="error" badgeContent={unreadNotifiations}>
          <theme.icons.notification
            sx={{
              color: theme.palette.text.secondary,
              '&:hover': { color: theme.palette.text.primary },
            }}
          />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}
