import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { useReactiveVar } from '@apollo/client';

import { loginStatusVar, conversationIdVar } from '@context';
import { useGetAccountConversationLazyQuery, useCreateConversationMutation } from '@graphql';
import { useSession } from '@hooks';

interface WriteButtonProps {
  recipientId: string;
}

export default function WriteButton(props: WriteButtonProps) {
  const { recipientId } = props;
  const { user } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();
  const { t } = useTranslation('cta');

  const [createConversation] = useCreateConversationMutation({
    variables: { participants: [recipientId] },
    onCompleted: ({ createConversation }) => {
      if (createConversation?.id) {
        conversationIdVar(createConversation.id);
      }
    },
  });

  const [getConversation] = useGetAccountConversationLazyQuery({
    variables: { accountId: recipientId },
    onCompleted: ({ accountConversation }) => {
      if (accountConversation?.id) {
        conversationIdVar(accountConversation.id);
      } else {
        createConversation();
      }
    },
  });

  const handleWrite = () => {
    if (!user) {
      loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    } else {
      getConversation();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        variant="contained"
        color="uncolored"
        fullWidth
        startIcon={<theme.icons.send />}
        onClick={handleWrite}
      >
        {t('write')}
      </Button>
    </Box>
  );
}
