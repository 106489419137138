import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@context';
import { GetConsumerDetailedQuery, Consumer } from '@graphql';
import FriendPendingButton from '../buttons/FriendPendingButton';

interface ListFriendsProps {
  type: 'accepted' | 'pending' | 'sent';
}
interface ListFriendSlotProps {
  isPending: boolean;
  friend:
    | GetConsumerDetailedQuery['consumer']['friends']['accepted'][0]
    | GetConsumerDetailedQuery['consumer']['friends']['pending'][0]
    | GetConsumerDetailedQuery['consumer']['friends']['sent'][0]
    | GetConsumerDetailedQuery['consumer'];
}

export default function ListFriends(props: ListFriendsProps) {
  const { type } = props;
  const { state } = useAppContext();
  const [friends, setFriends] = React.useState<
    | GetConsumerDetailedQuery['consumer']['friends']['accepted']
    | GetConsumerDetailedQuery['consumer']['friends']['pending']
    | GetConsumerDetailedQuery['consumer']['friends']['sent']
  >([]);

  React.useEffect(() => {
    setFriends(state.consumer?.friends?.[type] || []);
  }, [state.consumer?.friends?.[type]]);

  const emptyStateByType = {
    accepted: 'Aucun(e) ami(e)',
    pending: 'Aucune demande en attente',
    sent: 'Aucune demande envoyée',
  };

  return friends.length === 0 ? (
    <Stack width="100%">
      <Typography>{emptyStateByType[type as keyof typeof emptyStateByType]}</Typography>
    </Stack>
  ) : (
    <Stack width="100%" spacing={1}>
      {friends.map((friend) => (
        <ListFriendSlot key={friend.id} friend={friend} isPending={type === 'pending'} />
      ))}
    </Stack>
  );
}

function ListFriendSlot(props: ListFriendSlotProps) {
  const { friend, isPending } = props;
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    dispatch({ type: 'SET_CONSUMER', payload: friend as Consumer });
    setSearchParams({ ...searchParams, type: 'consumer', q: friend.slug }, { preventScrollReset: true });
  };

  return (
    <Link
      to={`?type=consumer&q=${friend.slug}&tab=preview`}
      style={{ textDecoration: 'none', color: theme.palette.text.primary }}
      onClick={handleOnClick}
    >
      <Stack
        spacing={3}
        p={1}
        direction="row"
        sx={{
          width: '100%',
          color: theme.palette.text.primary,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.palette.grey['900'],
          border: `1px solid ${theme.palette.grey['900']}`,
        }}
      >
        <Stack width="20%">
          <img
            src={friend.avatar || ''}
            alt="img"
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio: '1/1',
              borderRadius: theme.borderRadius * 2,
            }}
          />
        </Stack>
        <Stack spacing={1} p={1} justifyContent="space-between">
          <Typography variant="h6" fontWeight="bolder">
            {friend.name}
          </Typography>
          <Typography variant="body2">{friend.slug}</Typography>
          {isPending && (
            <Stack direction="row" spacing={1}>
              <FriendPendingButton friend={friend} action="accept" />
              <FriendPendingButton friend={friend} action="reject" />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Link>
  );
}
