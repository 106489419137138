import * as React from 'react';
import { useTheme } from '@mui/material';
import { useSearchParams, Link } from 'react-router-dom';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import { useSession } from '@hooks';
import { AvatarCustom } from '@components';
import { truncateText } from '@utils';

export default function AccountMenu() {
  const theme = useTheme();
  const { account } = useSession();
  const [searchParams, setSearchParams] = useSearchParams();

  if (!account) {
    return null;
  }

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSearchParams(
      {
        type: account.__typename.toLowerCase(),
        q: account.slug,
        tab: 'preview',
      },
      { preventScrollReset: true }
    );
  };

  return (
    <Link to={`?type=spot&q=${account.slug}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemIcon>
            <AvatarCustom
              ressource={account.avatar?.url || null}
              id={account.id}
              type="account"
              sx={{ width: 30, height: 30 }}
            />
          </ListItemIcon>
          <ListItemText
            primary={truncateText(account.name, 20)}
            secondary={'@' + truncateText(account.slug, 20)}
            primaryTypographyProps={{
              fontWeight: 'bold',
              color: theme.palette.text.primary,
            }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
