import { makeVar } from "@apollo/client";

import { GetSessionQuery, Account } from '@graphql';

type User = (GetSessionQuery['session'] & { accounts: Account[] }) | null;
type Accounts = {
  current: Account | null;
  all: Account[] | [];
};
// type Accounts = {
//   current: GetSessionQuery['session']['accounts'][0] | null;
//   all: GetSessionQuery['session']['accounts'] | [];
// };
type loginStatusVar = {
  hasAccessToken: boolean;
  isSessionExpired: boolean;
  shouldLogFirst: boolean;
  redirection: string | null;
};

const defaultSessionAccounts: Accounts = {
  current: null,
  all: [],
};

const defaultLoginStatus: loginStatusVar = {
  hasAccessToken: false, // will be refreshed in local-fields
  isSessionExpired: false,
  shouldLogFirst: false,
  redirection: null,
};

export const loginStatusVar = makeVar<loginStatusVar>(defaultLoginStatus);
export const languageVar = makeVar("fr");
export const tabNameVar = makeVar('');
export const isDrawerOpenVar = makeVar(false);
export const showBackDropVar = makeVar(false);
export const showDialogBackDropVar = makeVar(false);
export const showAppLoaderVar = makeVar(false);
export const userVar = makeVar<User>(null);
export const accountsVar = makeVar<Accounts>(defaultSessionAccounts);
export const conversationIdVar = makeVar<string | null>(null);
export const previewBookingVar = makeVar<string | null>(null);