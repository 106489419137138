import * as React from 'react';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useSession } from '@hooks';
import Title from './Title';
import Action from './Action';

export function Booking() {
  const theme = useTheme();
  const { user, account } = useSession();

  return (
    <Stack width="100%">
      <Title icon={<theme.icons.booking />} title={'Réservations'} />
      <Action type="booking" subtype="new" title="Nouvelles réserations" />
      <Action type="booking" subtype="edit" title="Modifications (heure d'arrivée, nombre de convives...)" />
      <Action type="booking" subtype="late" title="Retard annoncé" />
      <Action type="booking" subtype="cancel" title="Annulation" />
    </Stack>
  );
}
