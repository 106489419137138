import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useSearchParams, Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@context';
import { Event, GetSpotDetailedQuery } from '@graphql';

interface CalendarSlotProps {
  direction?: 'row' | 'column';
  event: GetSpotDetailedQuery['spot']['events'][0];
}

function EventSlot(props: CalendarSlotProps) {
  const { event, direction = 'row' } = props;
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const theme = useTheme();
  const { t } = useTranslation('event');

  const [lowestTicket] = event.tickets;
  const currMap = {
    EUR: '€',
    USD: '$',
    GBP: '£',
  };

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSearchParams({ ...searchParams, type: 'event', q: event.id }, { preventScrollReset: true });
  };

  return (
    <Link to={`?type=event&q=${event.id}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        spacing={3}
        p={1}
        direction={direction}
        sx={{
          color: theme.palette.text.primary,
          borderRadius: theme.borderRadius,
          backgroundColor: theme.palette.grey['900'],
          border: `1px solid ${theme.palette.grey['900']}`,
        }}
      >
        <Stack width={direction === 'row' ? '20%' : '100%'}>
          <img
            src={event.cover || ''}
            alt="img"
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: 'center',
              aspectRatio: direction === 'row' ? '1/1' : '16/9',
              borderRadius: theme.borderRadius * 2,
            }}
          />
        </Stack>
        <Stack spacing={1} p={1} justifyContent="space-between">
          <Typography variant="h6" fontWeight="bolder">
            {event.title}
          </Typography>
          <Typography variant="body2">{moment(event.from).format('dddd DD MMMM YYYY [à] hh:mm')}</Typography>
          <Typography variant="body2">
            {event.tickets.length === 0 || lowestTicket.price === 0
              ? t('price.free')
              : t('price.from', {
                  price: lowestTicket.price,
                  currency: currMap[lowestTicket.currency as 'EUR' | 'USD' | 'GBP'],
                })}
          </Typography>
          <Stack direction="row" flexWrap="wrap" gap=".5rem">
            {event.tags?.length > 0 &&
              event.tags.map(({ id, label }) => <Chip key={id} label={'#' + label} variant="outlined" />)}
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
}

export default React.memo(EventSlot);
