import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

import { loginStatusVar } from '@context';
import { useSession, useToastError, useConnection } from '@hooks';
import { SignupSSOForm } from './SignupSSOForm';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function OAuthPage() {
  const { refetch, user, account } = useSession();
  const { logout } = useConnection();
  const loginStatus = useReactiveVar(loginStatusVar);
  const [open, setOpen] = React.useState(false);
  const [hasBeenRefetched, setHasBeenRefetched] = React.useState(false);
  const { t } = useTranslation('cta');
  const navigate = useNavigate();
  const toastError = useToastError();

  // ensure session is up to date
  React.useEffect(() => {
    refetch()
      .then(() => {
        setHasBeenRefetched(true);
      })
      .catch((error) => {
        toastError(error);
        navigate('/spots');
      });
  });

  // handle finalization of the signup process
  React.useEffect(() => {
    // console.log('[SESSION]:', { user, account });
    const { birthdate, firstname, lastname, gender } = user || {};

    if (hasBeenRefetched) {
      if (!user || user.ssoProvider === null || user.isVerified) {
        // case already verified or not SSO user
        navigate('/spots');
      } else if (!birthdate || !firstname || !lastname || !gender) {
        // case only first step of the SSO signup process is completed
        setOpen(true);
      }
    }
  }, [hasBeenRefetched, user, account, loginStatus, refetch]);

  React.useEffect(() => {
    const { birthdate, firstname, lastname, gender } = user || {};

    // if user is not SSO user or already verified
    if (user?.ssoProvider === null || user?.isVerified || (birthdate && firstname && lastname && gender)) {
      navigate('/spots');
    }
  }, []);

  const handleCancel = () => {
    handleClose();
    logout();
  };

  const handleClose = () => {
    setOpen(false);
    navigate('/spots');
  };

  return (
    <React.Fragment>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{ backgroundColor: 'background.default' }}>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Finalisation de l'inscription
            </Typography>
            <Button variant="outlined" color="uncolored" onClick={handleCancel}>
              {t('cancel')}
            </Button>
          </Toolbar>
        </AppBar>
        <Stack height="100%" p={3} justifyContent="center" alignItems="center">
          <SignupSSOForm handleClose={handleClose} />
        </Stack>
      </Dialog>
    </React.Fragment>
  );
}
