import * as React from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';
import moment from 'moment';

import {
  useListHomeSpotsLazyQuery,
  ListHomeSpotsQuery,
  useListHomeEventsLazyQuery,
  ListHomeEventsQuery,
} from '@graphql';
import useStyles from './styles';

interface Item {
  id: string;
  title: string;
  description: string;
  slug?: string;
  cover: string;
}

export function Highlight() {
  const theme = useTheme();
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();
  const { isLandscape } = Device.useMobileOrientation();
  const [item, setItem] = React.useState<Item | null>(null);
  const styles = useStyles(theme, item?.cover ?? '');
  const { t } = useTranslation('spot');

  const [getEvents] = useListHomeEventsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted({ eventCategories }) {
      const item = getRandomEventItem(eventCategories);
      setItem(item);
    },
  });

  const [getSpots] = useListHomeSpotsLazyQuery({
    fetchPolicy: 'cache-first',
    onCompleted({ homeSpots }) {
      const item = getRandomSpotItem(homeSpots);
      setItem(item);
    },
  });

  React.useEffect(() => {
    if (pathname === '/events') {
      getEvents();
    } else if (pathname === '/spots') {
      getSpots();
    }
  }, [pathname]);

  function getSpotTypes(obj: Record<string, boolean | string>): string {
    return Object.keys(obj)
      .filter((key) => obj[key] === true)
      .map((type) => t(`type.${type}`))
      .filter((_type, idx) => idx < 3)
      .join(', ');
  }

  function getRandomSpotItem(categories: ListHomeSpotsQuery['homeSpots']) {
    const category = getRandomItem(categories);
    const spot = getRandomItem(category.spots);
    const cover = getRandomItem(spot?.covers || []);

    if (!cover) {
      return getRandomSpotItem(categories);
    }

    const types = getSpotTypes(spot.type || {});

    return {
      id: spot.id,
      title: spot.name,
      description: types,
      slug: spot.slug,
      cover: cover.url,
    };
  }

  function getRandomEventItem(categories: ListHomeEventsQuery['eventCategories']) {
    const category = getRandomItem(categories);
    const event = getRandomItem(category.events);
    const cover = getRandomItem(event?.covers || []);

    if (!cover) {
      return getRandomEventItem(categories);
    }

    return {
      id: event.id,
      title: event.title,
      description: moment(event.from).format('dddd MMMM YYYY'),
      cover: cover.url,
    };
  }

  function getRandomItem(list: any[]) {
    return list[Math.floor(Math.random() * list.length)];
  }

  const TopTen = () => {
    const size = isScreenMedium ? theme.spacing(6) : theme.spacing(10);

    return (
      <Stack
        width={size}
        height={size}
        alignItems="center"
        justifyContent="center"
        bgcolor="primary.main"
        borderRadius={theme.spacing(1)}
      >
        <Typography fontSize={isScreenMedium ? '.5rem' : '1rem'} fontWeight={800}>
          Top
        </Typography>
        <Typography fontSize={isScreenMedium ? '.8rem' : '1.5rem'} fontWeight={800}>
          10
        </Typography>
      </Stack>
    );
  };

  const handleOpen = () => {
    if (item?.slug) {
      setSearchParams({ type: 'spot', q: item.slug, tab: 'preview' }, { preventScrollReset: true });
    } else if (item) {
      setSearchParams({ type: 'event', q: item.id }, { preventScrollReset: true });
    }
  };

  if (Device.isMobileOnly && isLandscape) {
    return null;
  }

  if (Device.isMobileOnly) {
    if (!item) {
      return (
        <Stack justifyContent="center" alignItems="center" width="100%" height="60vh" p={2}>
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Stack>
      );
    }

    return (
      <Stack justifyContent="center" alignItems="center" width="100%" height="60vh" p={2}>
        <Stack
          height="100%"
          sx={{
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            borderRadius: 2,
            boxShadow: `0px 0px 30px ${alpha('#000', 1)}`,
            border: `1px solid  ${alpha('#fff', 0.1)}`,
            borderStyle: 'inset',
            backgroundImage: `url('${item.cover}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <Stack
            spacing={2}
            p={2}
            width="100%"
            height="100%"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backdropFilter: 'blur(15px)',
              backgroundColor: alpha(theme.palette.background.paper, 0.7),
              borderRadius: 2,
              boxShadow: `0px 0px 30px ${alpha('#000', 1)}`,
              border: `1px solid  ${alpha('#fff', 0.1)}`,
              borderStyle: 'inset',
            }}
          >
            <Box
              className="img"
              component="img"
              src={item.cover}
              alt={'name' + ' cover'}
              width="100%"
              height="inherit"
              sx={{
                aspectRatio: '6/3',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 3,
                border: `1px solid  ${alpha('#fff', 0.1)}`,
                borderStyle: 'inset',
                boxShadow: `0px 0px 30px ${alpha('#000', 0.5)}`,
              }}
            />
            <Stack spacing={1} width="100%" alignItems="center">
              <Stack width="100%">
                <Typography
                  textAlign="center"
                  variant="h6"
                  fontWeight={800}
                  sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
                >
                  {item.title}
                </Typography>
                <Typography textAlign="center" variant="body2">
                  {item.description}
                </Typography>
              </Stack>
              <Button size="small" variant="contained" startIcon={<theme.icons.see />} onClick={handleOpen}>
                {t(`See the ${pathname === '/events' ? 'event' : 'spot'}`, { ns: 'cta' })}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  if (!item) {
    return null;
  }

  return (
    <Stack justifyContent="center" px={{ xs: 2, sm: 5, md: 10 }} sx={styles.highlight}>
      <Stack width="100%" spacing={{ xs: 2, md: 5 }}>
        <Stack
          p={3}
          spacing={isScreenMedium ? 2 : 5}
          width={isScreenMedium ? '100%' : '50%'}
          sx={{
            borderRadius: theme.borderRadius,
            backgroundColor: alpha(theme.palette.background.default, 0.8),
          }}
        >
          <Typography variant="h3" fontWeight={800} sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}>
            {item.title}
          </Typography>

          <Stack direction="row" spacing={2} alignItems={'center'}>
            <TopTen />
            <Stack>
              <Typography
                fontSize="1.3rem"
                component="h2"
                fontWeight={800}
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                N°7 des spots
              </Typography>
              <Typography
                fontSize="1.3rem"
                component="h3"
                fontWeight={800}
                color="GrayText"
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                {item.description}
              </Typography>
            </Stack>
          </Stack>

          {!isScreenMedium && (
            <Typography
              fontSize="1.1rem"
              sx={{ color: 'white', textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
            >
              {item.description}
            </Typography>
          )}
          <Box width={isScreenMedium ? '100%' : '50%'}>
            <Button
              onClick={handleOpen}
              startIcon={<theme.icons.see />}
              variant="contained"
              color="primary"
              size={isScreenMedium ? 'small' : 'large'}
            >
              {t('See the spot', { ns: 'cta' })}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
}
