import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { GetConsumerDetailedQuery } from '@graphql';

interface RexProps {
  consumer: GetConsumerDetailedQuery['consumer'];
}

function Rex(props: RexProps) {
  const { id, description } = props.consumer;

  return (
    <Stack spacing={5} p={3}>
      <Typography>REX</Typography>
    </Stack>
  );
}

export default React.memo(Rex);
