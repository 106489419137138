import * as Yup from 'yup';
import { TFunction } from 'i18next';

export default function validationSchema(t: TFunction) {
  return Yup.object({
    title: Yup.string().required().label(t('event.label.title')),
    description: Yup.string().label(t('event.label.description')).nullable(),
    from: Yup.date().required().label(t('event.label.from')),
    to: Yup.date().required().label(t('event.label.to')),
    cover: Yup.mixed().label(t('event.label.cover')).nullable(),
    images: Yup.array().of(Yup.mixed()).label(t('event.label.images')),
  });
}
