import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import MuiDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useAppContext } from '@context';
import Spot from './spot';
import Event from './event';
import Consumer from './consumer';

export function Dialog() {
  let [searchParams] = useSearchParams();
  let { pathname } = useLocation();
  const { state } = useAppContext();
  const navigate = useNavigate();
  const theme = useTheme();
  const [isOpen, setIsOpen] = React.useState(state.isOpenDialog);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const maxWidth = isMobile ? false : fullScreen ? 'xl' : 'md';

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleBack = () => {
    const q = searchParams.get('q');
    if (q !== null) {
      setIsOpen(false);
    }

    if (window.history.state.idx <= 1) {
      // prevent user to quit app if he went to the dialog from a direct link
      navigate(pathname, { preventScrollReset: true });
    } else {
      navigate(-1);
    }
  };

  React.useEffect(() => {
    setIsOpen(state.isOpenDialog);
  }, [state.isOpenDialog]);

  React.useEffect(() => {
    const q = searchParams.get('q');
    if (q !== null) {
      if (!isOpen) {
        setIsOpen(true);
      }
    } else {
      setIsOpen(false);
    }
  }, [searchParams]);

  return (
    <MuiDialog
      open={isOpen}
      TransitionComponent={Transition}
      onClose={handleBack}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      scroll="body"
      PaperProps={{
        sx: {
          backgroundColor: theme.palette.background.default,
          border: '1px solid rgba(255, 255, 255, 0.1)',
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backgroundColor: 'rgba(0, 0, 0, .7)',
          },
        },
      }}
    >
      {searchParams.get('type') === 'event' && <Event handleClose={handleClose} handleBack={handleBack} />}
      {searchParams.get('type') === 'spot' && <Spot handleClose={handleClose} handleBack={handleBack} />}
      {searchParams.get('type') === 'consumer' && (
        <Consumer handleClose={handleClose} handleBack={handleBack} />
      )}
    </MuiDialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
