import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

interface GoogleMapEmbedProps {
  width?: string;
  height?: string;
  placeId: string;
  zoom?: number;
}

const GoogleMapEmbed: React.FC<GoogleMapEmbedProps> = ({
  width = '100%',
  height = '450px',
  zoom = 17,
  placeId,
}) => {
  const googleMapUrl = `https://www.google.com/maps/embed/v1/place?key=${
    import.meta.env.VITE_GOOGLE_MAPS_EMBED_API_KEY
  }&q=place_id:${placeId}&zoom=${zoom}`;

  return (
    <Box
      component="iframe"
      src={googleMapUrl}
      width={width}
      height={height}
      loading="lazy"
      sx={{
        border: 0,
        borderRadius: 2,
        boxShadow: 3,
      }}
      allowFullScreen
    />
  );
};

function FindUs(props: { placeId?: string | null }) {
  const { placeId } = props;
  const { t } = useTranslation('spot');

  if (!placeId) {
    return null;
  }

  return (
    <div>
      <Typography variant="h6" mb={1}>
        {t('title.Find us')}
      </Typography>
      <Stack width="100%" sx={{ aspectRatio: '16/5' }}>
        <GoogleMapEmbed placeId={placeId} />
      </Stack>
    </div>
  );
}

export default React.memo(FindUs);
