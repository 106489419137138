import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import { useToastError } from '@hooks';
import { Spot, useUpdateSpotCloseSpacesMutation } from '@graphql';

const STEP = 1;
const MIN = 0;
const MAX = 100;

interface CloseSpacesProps {
  handleSwiperTouchMove: (isActive: boolean) => void;
  account: Spot;
}

export default function CloseSpaces(props: CloseSpacesProps) {
  const { handleSwiperTouchMove, account } = props;
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateCloseSpaces] = useUpdateSpotCloseSpacesMutation();

  const initialValues = { closeSpaces: account.configuration.closeSpaces };
  const validationSchema = yup.object({
    closeSpaces: yup.number().min(0).max(100).required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      updateCloseSpaces({
        variables: { value: values.closeSpaces },
        onCompleted({ updateSpotCloseSpaces }) {
          formik.resetForm({ values });
          toast(t('spotCloseSpaces.toast.success', { ns: 'field' }), { type: 'success' });
        },
        onError: toastError,
      });
    },
  });

  const handleEdit = () => {
    handleSwiperTouchMove(false);
    setIsEditing(true);
  };

  const handleCancel = () => {
    formik.resetForm({ values: initialValues });
    setIsEditing(false);
    handleSwiperTouchMove(true);
  };

  const handleSetVal = (closeSpaces: number) => {
    formik.setFieldValue('closeSpaces', closeSpaces);
    formik.setFieldTouched('closeSpaces', (account.configuration.closeSpaces as number) !== closeSpaces);
  };

  const handleValidate = () => {
    formik.submitForm();
    setIsEditing(false);
    handleSwiperTouchMove(false);
  };

  return (
    <Stack width="100%" spacing={1}>
      <Stack direction="row" width="100%" justifyContent="space-between">
        <Stack>
          <Typography fontWeight="bold">{t('spotCloseSpaces.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotCloseSpaces.caption', { ns: 'field' })}
          </Typography>
        </Stack>
        <Stack spacing={1} direction="row" alignItems="flex-start">
          {isEditing ? (
            <>
              <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                {t('cancel')}
              </Button>
              <Button
                disabled={!formik.dirty || !formik.isValid}
                onClick={handleValidate}
                variant="contained"
                color="success"
                size="small"
                type="submit"
              >
                {t('validate')}
              </Button>
            </>
          ) : (
            <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
              {t('edit')}
            </Button>
          )}
        </Stack>
      </Stack>
      <Stack>
        <Slider
          sx={{ mt: 5 }}
          name="closeSpaces"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.closeSpaces}
          defaultValue={formik.values.closeSpaces}
          valueLabelDisplay="on"
          step={STEP}
          min={MIN}
          max={MAX}
          disabled={!isEditing}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body2" onClick={() => handleSetVal(MIN)} sx={{ cursor: 'pointer' }}>
            {MIN}
          </Typography>
          <Typography variant="body2" onClick={() => handleSetVal(MAX)} sx={{ cursor: 'pointer' }}>
            +{MAX}
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
}
