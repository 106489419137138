import { gql } from "@apollo/client";
import { eventImageFragment } from "./image";

export const eventFragment = gql`
  fragment EventFragment on Event {
    id
    title
    description
    thumb
    flyer
    covers {
      ...EventImageFragment
    }
    from
    to
  }
  ${eventImageFragment}
`;

export const categoryFragment = gql`
  fragment CategoryFragment on EventCategory {
    id
    name
    description
  }
`;
