import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useReactiveVar } from '@apollo/client';
import Box from '@mui/material/Box';

import { loginStatusVar } from '@context';
import {
  useLikeEventMutation,
  useUnlikeEventMutation,
  GetEventDetailedQuery,
  GetEventDetailedDocument,
} from '@graphql';
import { useSession, useToastError } from '@hooks';

interface LikeButtonProps {
  eventId: string;
  isLiking: boolean;
}

export default function LikeButton(props: LikeButtonProps) {
  const { eventId, isLiking } = props;
  const { user, account } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();

  const [like] = useLikeEventMutation({
    variables: { eventId },
    onError: useToastError(),
    update: (cache, { data }) => {
      const cachedEvent = cache.readQuery<GetEventDetailedQuery | null>({
        query: GetEventDetailedDocument,
        variables: { id: eventId },
      });
      const likers = cachedEvent?.event.likers;

      if (!likers || !account) {
        return;
      }

      cache.modify({
        id: cache.identify({ __typename: 'Event', id: eventId }),
        fields: {
          isLiking() {
            return true;
          },
          likers(existingLikers = {}) {
            return {
              ...existingLikers,
              count: existingLikers.count + 1,
              accounts: [
                ...existingLikers.accounts,
                {
                  id: account.id,
                  name: account.name,
                  slug: account.slug,
                  avatar: account.avatar,
                  __typename: account.__typename,
                },
              ],
            };
          },
        },
      });
    },
  });

  const [unlike] = useUnlikeEventMutation({
    variables: { eventId },
    onError: useToastError(),
    update: (cache, { data }) => {
      const cachedEvent = cache.readQuery<GetEventDetailedQuery | null>({
        query: GetEventDetailedDocument,
        variables: { id: eventId },
      });
      const likers = cachedEvent?.event.likers;

      if (!likers || !account) {
        return;
      }

      cache.modify({
        id: cache.identify({ __typename: 'Event', id: eventId }),
        fields: {
          isLiking() {
            return false;
          },
          likers(existingLikers = {}) {
            return {
              ...existingLikers,
              count: existingLikers.count - 1 < 0 ? 0 : existingLikers.count - 1,
              accounts: likers.accounts.filter(({ id }) => id !== account.id),
            };
          },
        },
      });
    },
  });

  const handleLike = () => {
    if (!user) {
      loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    } else if (isLiking === false) {
      like();
    } else {
      unlike();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        variant={isLiking ? 'outlined' : 'contained'}
        color={isLiking ? 'primary' : 'uncolored'}
        fullWidth
        onClick={handleLike}
      >
        {isLiking ? <theme.icons.favorite /> : <theme.icons.favoriteOutlined />}
      </Button>
    </Box>
  );
}
