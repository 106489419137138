import * as React from 'react';
import { useTheme } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import * as Device from 'react-device-detect';

import { truncateText } from '@utils';
import { useListUsersLazyQuery, useListAccountsLazyQuery, ListUsersQuery, ListAccountsQuery } from '@graphql';
import { useTranslation } from 'react-i18next';

const SPACING = 2;

function UserSlot(user: ListUsersQuery['listUsers'][0]) {
  const theme = useTheme();
  const { t } = useTranslation('common');

  return (
    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
      <Stack flex={1.5} justifyContent="center">
        <Typography fontWeight="bold" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          {truncateText(user.firstname + ' ' + user.lastname, 25)}
        </Typography>
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>{truncateText(user.email ?? '', 25)}</Typography>
      </Stack>
      <Stack flex={1} justifyContent="center">
        <Typography fontWeight="bold" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          {moment(user.birthdate).fromNow(true)}
        </Typography>
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          {truncateText(t(`gender.${user.gender}`), 20)}
        </Typography>
      </Stack>
      <Stack justifyContent="center">
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>{moment(user.createdAt).fromNow()}</Typography>
      </Stack>
      <theme.icons.options fontSize={Device.isMobileOnly ? 'small' : 'medium'} />
    </Stack>
  );
}

function AccountSlot(account: ListAccountsQuery['listAccounts'][0]) {
  const theme = useTheme();

  return (
    <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
      <Stack flex={1.5} justifyContent="center">
        <Typography fontWeight="bold" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          {truncateText(account.name, 25)}
        </Typography>
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>{truncateText(account.slug, 25)}</Typography>
      </Stack>
      <Stack flex={0.5} justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          Type
        </Typography>
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>{account.__typename.toLowerCase()}</Typography>
      </Stack>
      <Stack flex={0.5} justifyContent="center" alignItems="center">
        <Typography fontWeight="bold" fontSize={{ xs: '.6rem', sm: '.7rem' }}>
          Vérifié
        </Typography>
        {account.isVerified ? (
          <theme.icons.check sx={{ width: 10, height: 10 }} />
        ) : (
          <theme.icons.close sx={{ width: 10, height: 10 }} />
        )}
      </Stack>
      <Stack justifyContent="center">
        <Typography fontSize={{ xs: '.6rem', sm: '.7rem' }}>{moment(account.createdAt).fromNow()}</Typography>
      </Stack>
      <theme.icons.options fontSize={Device.isMobileOnly ? 'small' : 'medium'} />
    </Stack>
  );
}

export function LastTen() {
  const [users, setUsers] = React.useState<ListUsersQuery['listUsers'] | null>(null);
  const [accounts, setAccounts] = React.useState<ListAccountsQuery['listAccounts'] | null>(null);
  const [tab, setTab] = React.useState(0);
  const theme = useTheme();

  const limit = 5;

  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setTab(newTab);
  };

  const [listUsers, { loading: loadingUsers }] = useListUsersLazyQuery({
    onCompleted({ listUsers }) {
      setUsers(listUsers);
    },
  });
  const [listAccounts, { loading: loadingAccounts }] = useListAccountsLazyQuery({
    onCompleted({ listAccounts }) {
      setAccounts(listAccounts);
    },
  });

  React.useEffect(() => {
    if (tab === 0) {
      listUsers({
        variables: {
          filters: {
            limit,
          },
        },
      });
    } else {
      listAccounts({
        variables: {
          filters: {
            limit,
          },
        },
      });
    }
  }, [tab]);

  return (
    <Stack
      width="100%"
      p={SPACING}
      spacing={SPACING}
      sx={{ backgroundColor: theme.palette.background.paper }}
    >
      <Stack
        spacing={SPACING}
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography fontWeight="bold">{limit} derniers</Typography>
        <Tabs
          value={tab}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
        >
          <Tab label="Utilisateurs" />
          <Tab label="Comptes" />
        </Tabs>
      </Stack>

      <Stack width="100%" spacing={1} divider={<Divider sx={{ backgroundColor: theme.palette.grey[500] }} />}>
        {loadingUsers || loadingAccounts ? (
          <Stack sidth="100%" alignItems="center" justifyContent="center" p={3}>
            <CircularProgress />
          </Stack>
        ) : tab === 0 ? (
          (users || []).map((user) => <UserSlot key={user.id} {...user} />)
        ) : (
          (accounts || []).map((account) => <AccountSlot key={account.id} {...account} />)
        )}
      </Stack>
    </Stack>
  );
}
