import { gql } from "@apollo/client";

export const addressFragment = gql`
  fragment AddressFragment on Address {
    id
    city
    label
    latitude
    longitude
    placeId
  }
`;
