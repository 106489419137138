import * as React from 'react';
import { useTheme, alpha } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { GetEventDetailedQuery } from '@graphql';
import LikeButton from './buttons/LikeButton';
import WriteButton from '../../common/WriteButton';

interface IDCardProps {
  event: GetEventDetailedQuery['event'];
  scrollToTickets: () => void;
}

function IDCard(props: IDCardProps) {
  const { event, scrollToTickets } = props;
  const { title, category, likers, tickets } = event;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('cta');

  const [ticketType, setTicketType] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (!tickets.length) {
      setTicketType('VOID');
    } else if (tickets.length === 1 && tickets[0].price === 0) {
      setTicketType('FREE');
    } else if (tickets.length > 1) {
      setTicketType('MANY');
    }
  }, [tickets]);

  return (
    <Stack spacing={3} direction={isMobile ? 'column-reverse' : 'row'} width="100%">
      <Stack spacing={isMobile ? 0 : 1} width="100%" justifyContent="flex start" alignItems="flex-start">
        <Typography variant="h5" color="textPrimary" fontWeight="bold">
          {title}
        </Typography>
        <Stack
          spacing={isMobile ? 0 : 1}
          direction="row"
          width="100%"
          justifyContent="flex start"
          alignItems="flex-end"
        >
          <Typography variant="body1" color="textSecondary" fontWeight="bold">
            {category?.name ? t(`category.${category?.name}`, { ns: 'event' }) : null}
          </Typography>
          {likers.count > 0 && (
            <Typography variant="body2" color="textSecondary">
              {`${getCount(likers.count)} ${
                likers.count > 1 ? 'personnes intéressées' : 'personne intéressée'
              }`}
            </Typography>
          )}
        </Stack>
      </Stack>
      <Stack spacing={1} width="100%">
        {ticketType === 'VOID' ? null : ticketType === 'FREE' ? (
          <Stack
            p={1}
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: alpha(theme.palette.success.dark, 0.4), borderRadius: 1 }}
          >
            <Typography variant="body1" color="textSecondary" fontWeight="bold" textAlign="center">
              {t('free')}
            </Typography>
          </Stack>
        ) : ticketType === 'MANY' ? (
          <Button variant="contained" color="primary" fullWidth onClick={scrollToTickets}>
            {t('tickets')}
          </Button>
        ) : null}
        <Stack spacing={1} direction="row" width="100%">
          <LikeButton eventId={event.id} isLiking={event.isLiking} />
          <WriteButton recipientId={event.creator.id} />
        </Stack>
      </Stack>
    </Stack>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}

export default IDCard;
