import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';
import { Theme } from '@mui/material';

function Description(props: { description?: string | null }) {
  const { description } = props;
  const { t } = useTranslation('spot');

  if (!description) {
    return null;
  }

  const cleanDescription = DOMPurify.sanitize(description);

  return (
    <Stack>
      <Typography variant="h6" mb={1}>
        {t('title.Description')}
      </Typography>
      <Stack
        p={3}
        backgroundColor="background.paper"
        sx={{ borderRadius: (theme: Theme) => theme.borderRadius }}
      >
        <Typography
          color="text.secondary"
          component="div"
          fontSize={{ xs: '.9em', md: '1rem' }}
          dangerouslySetInnerHTML={{ __html: cleanDescription }}
        />
      </Stack>
    </Stack>
  );
}

export default React.memo(Description);
