import React from "react";
import { useReactiveVar } from '@apollo/client';

import { GetSessionQuery, useGetSessionLazyQuery, useGetSessionQuery } from '@graphql';
import { loginStatusVar } from '@context';

export default function useSession() {
  const loginStatus = useReactiveVar(loginStatusVar);
  const { hasAccessToken, shouldLogFirst, isSessionExpired } = loginStatus;

  const { data, refetch, loading, called } = useGetSessionQuery({
    onCompleted({ session }) {
      // update hasAccessToken, necessary when app is initialized
      if (hasAccessToken && session === null) {
        loginStatusVar({
          ...loginStatus,
          hasAccessToken: false,
        });
      }
      if (!hasAccessToken && session !== null) {
        loginStatusVar({
          ...loginStatus,
          hasAccessToken: true,
        });
      }
    },
    onError(error) {
      // @ts-ignore
      const message = error?.networkError?.result?.errors?.[0]?.message;
      if (message === 'UNAUTHENTICATED') {
        loginStatusVar({
          ...loginStatus,
          shouldLogFirst: true,
        });
      } else if (message === 'SESSION_EXPIRED') {
        loginStatusVar({
          ...loginStatus,
          isSessionExpired: true,
        });
      }
    },
    fetchPolicy: 'cache-first',
  });

  const getSelectedAccount = (session: GetSessionQuery['session']) => {
    if (!session || !session.accounts) return null;

    const selectedAccount = session.accounts.find(({ isCurrent }) => isCurrent);

    return selectedAccount || null;
  };

  return React.useMemo(
    () => ({
      isLoggedIn: hasAccessToken,
      user: hasAccessToken ? data?.session ?? null : null,
      account: hasAccessToken ? getSelectedAccount(data?.session) : null,
      loading,
      refetch,
      sessionCalled: called,
    }),
    [hasAccessToken, data?.session, loading, called]
  );
}