import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export function LoadingFallback() {
  return (
    <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
      <CircularProgress color="primary" />
    </Stack>
  );
}
