import * as React from "react";
import { useTheme } from "@mui/material/styles";
import BottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useNavigate, useLocation } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { styled } from '@mui/material/styles';
import { useSearchParams, Link } from 'react-router-dom';

import { useSession } from '@hooks';
import { loginStatusVar } from '@context';
import { AvatarCustom } from '@components';

const validPaths = ['/events', '/spots', '/search', '/me', '/account'];

const defaultNavigation: Record<string, number> = {
  '/': 0,
  '/events': 0,
  '/spots': 1,
  '/search': 2,
};

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  color: gray;
  min-width: unset;
  max-width: unset;
  &.Mui-selected {
    color: white;
  }
`);

export default function CustomBottomNavigation() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { user, account } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const path = pathname.match(/\/\w*/)?.[0] || '/';
  const [value, setValue] = React.useState(defaultNavigation[path]);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    const isValidePath = validPaths.includes(pathname);
    const isUnmatch = value !== defaultNavigation[pathname];

    if (isValidePath && isUnmatch) {
      setValue(defaultNavigation[pathname]);
    }
  }, [pathname]);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, newValue: number) => {
    const { id } = event.currentTarget;

    if (id === 'account' && !user) {
      event.preventDefault();
    } else {
      setValue(newValue);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id } = event.currentTarget;
    navigate(`/${id}`, { preventScrollReset: true });
  };

  const handleClickSupport = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { id } = event.currentTarget;
    navigate(`/support/${id}`, { preventScrollReset: true });
  };

  const handleClickAccount = () => {
    if (account?.__typename) {
      const type = account.__typename.toLowerCase();
      const { slug } = account;
      const link = `?type=${type}&q=${slug}&tab=preview`;
      setSearchParams(link, { preventScrollReset: true });
    } else {
      loginStatusVar({ ...loginStatus, shouldLogFirst: true });
    }
  };

  const BottomNavSx = {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    zIndex: theme.zIndex.appBar,
    backgroundColor: 'rgba(14, 14, 14, .8)',
    backdropFilter: 'blur(10px)',
    borderTop: `1px solid rgba(100, 100, 100, .5)`,
  };

  switch (account?.__typename) {
    case 'Support':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction id="" icon={<theme.icons.dashboard />} onClick={handleClickSupport} />
          <BottomNavigationAction id="users" icon={<theme.icons.people />} onClick={handleClickSupport} />
          <BottomNavigationAction
            id="accounts"
            icon={<theme.icons.accounts />}
            onClick={handleClickSupport}
          />
          <BottomNavigationAction id="events" icon={<theme.icons.event />} onClick={handleClickSupport} />
          <BottomNavigationAction id="report" icon={<theme.icons.report />} onClick={handleClickSupport} />
          <BottomNavigationAction
            icon={
              account?.avatar?.url ? (
                <AvatarCustom
                  ressource={account.avatar.url}
                  id={account.id}
                  type="account"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <theme.icons.account />
              )
            }
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
    case 'Consumer':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction id="events" icon={<theme.icons.ticket />} onClick={handleClick} />
          <BottomNavigationAction id="spots" icon={<theme.icons.explore />} onClick={handleClick} />
          <BottomNavigationAction id="me" icon={<theme.icons.event />} onClick={handleClick} />
          <BottomNavigationAction
            icon={
              account?.avatar?.url ? (
                <AvatarCustom
                  ressource={account.avatar.url}
                  id={account.id}
                  type="account"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <theme.icons.account />
              )
            }
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
    case 'Spot':
      return (
        <BottomNavigation showLabels={false} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction id="events" icon={<theme.icons.ticket />} onClick={handleClick} />
          <BottomNavigationAction id="bookings" icon={<theme.icons.explore />} onClick={handleClick} />
          <BottomNavigationAction id="restaurant" icon={<theme.icons.event />} onClick={handleClick} />
          <BottomNavigationAction
            icon={
              account?.avatar?.url ? (
                <AvatarCustom
                  ressource={account.avatar.url}
                  id={account.id}
                  type="account"
                  sx={{
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <theme.icons.account />
              )
            }
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
    default:
      // Guest
      return (
        <BottomNavigation showLabels={true} value={value} onChange={handleChange} sx={BottomNavSx}>
          <BottomNavigationAction
            label="events"
            id="events"
            icon={<theme.icons.ticket />}
            onClick={handleClick}
          />
          <BottomNavigationAction
            label="spots"
            id="spots"
            icon={<theme.icons.explore />}
            onClick={handleClick}
          />
          <BottomNavigationAction
            label="recherche"
            id="search"
            icon={<theme.icons.search />}
            onClick={handleClick}
          />
          <BottomNavigationAction
            label="compte"
            icon={<theme.icons.account />}
            onClick={handleClickAccount}
          />
        </BottomNavigation>
      );
  }
}
