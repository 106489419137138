import * as React from 'react';
import { useTheme } from '@mui/material';
import Cropper from 'react-easy-crop';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { showAppLoaderVar } from '@context';
import { useSession, useToastError } from '@hooks';
import { useUpsertAvatarMutation, useRemoveAvatarMutation } from '@graphql';
import { MAX_FILE_SIZE } from '@utils';

const MAX_WIDTH = 300;
const MAX_HEIGHT = 300;

export function AvatarUploadButton() {
  const { account, refetch } = useSession();
  const theme = useTheme();
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null);
  const [imageSrc, setImageSrc] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [isResized, setIsResized] = React.useState(false);
  const [confirmDeletion, setConfirmDeletion] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const { t } = useTranslation('cta');
  const toastError = useToastError();

  const [upsertAvatar] = useUpsertAvatarMutation();
  const [removeAvatar] = useRemoveAvatarMutation();

  const initialValues = {
    avatar: account?.avatar?.url || null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      avatar: Yup.mixed().nullable(),
    }),

    onSubmit: async (values) => {
      if (!account?.__typename) {
        return null;
      }

      showAppLoaderVar(true);

      if (values.avatar !== null) {
        await upsertAvatar({
          variables: { avatar: values.avatar },
          onError: toastError,
          onCompleted: ({ upsertAvatar }) => {
            initialValues.avatar = upsertAvatar.url;
          },
        });
      } else {
        await removeAvatar({
          onError: toastError,
        });
      }

      await refetch(); // refetch account to update avatar
      handleReset();
    },
  });

  const handleImageSelected = async (files: File[]) => {
    const [file] = files;

    if (file) {
      const reader = new FileReader();

      if (file.size > MAX_FILE_SIZE) {
        const errorMessage = t('avatar.entity too large', {
          ns: 'account',
          size: MAX_FILE_SIZE / 1024 / 1024 + ' Mo',
        });
        setError(errorMessage);
        return;
      }

      reader.onload = () => {
        const image = new Image();
        image.src = reader.result as string;

        image.onload = () => {
          if (image.width >= 300 && image.height >= 300) {
            setImageSrc(reader.result as string);
            setOpen(true);
            formik.setFieldValue('avatar', file);
          } else {
            const errorMessage = t('avatar.entity too small', {
              ns: 'account',
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              selectedWidth: image.width,
              selectedHeight: image.height,
            });
            setError(errorMessage);
            return;
          }
        };
      };

      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = React.useCallback((_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (!imageSrc || !croppedAreaPixels) {
      import.meta.env.MODE !== 'production' && console.warn('No image or cropping area defined');
      return;
    }

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    await image.decode();

    const { width, height, x, y } = croppedAreaPixels;
    canvas.width = width;
    canvas.height = height;
    ctx?.drawImage(image, x, y, width, height, 0, 0, width, height);

    canvas.toBlob(
      async (blob) => {
        if (blob) {
          const webpBlob = await new Promise<Blob | null>(
            (resolve) => canvas.toBlob(resolve, 'image/webp', 0.8) // quality at 80%
          );

          if (webpBlob) {
            const webpFile = new File([webpBlob], 'croppedImage.webp', { type: 'image/webp' });
            await formik.setFieldValue('avatar', webpFile);
            setTimeout(() => {
              formik.submitForm();
            }, 100);
          } else {
            import.meta.env.MODE !== 'production' && console.error('Error: WebP Blob not created');
          }
        } else {
          import.meta.env.MODE !== 'production' && console.error('Error: Blob not created');
        }
      },
      'image/jpeg', // Initial type for the blob cropping
      1.0 // Initial quality of the cropped image
    );
  };

  const handleRemoveImage = async () => {
    if (account?.avatar?.url) {
      await formik.setFieldValue('avatar', null);
      formik.submitForm();
    } else {
      handleReset();
    }
  };

  const handleReset = () => {
    setImageSrc(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setError(null);
    setIsResized(false);
    showAppLoaderVar(false);
    setOpen(false);
    formik.resetForm({ values: initialValues });
  };

  const handleOpen = () => {
    if (account?.avatar?.url) {
      setImageSrc(account.avatar.url);
      setOpen(true);
    }
  };

  if (!account) {
    return null;
  }

  return (
    <Box textAlign="center" width="100%">
      {/* UPLOAD BUTTON OR PREVIEW  */}
      <Stack width="100%" alignItems="center" justifyContent="center">
        <Button
          component="label"
          variant="contained"
          color="uncolored"
          size="small"
          sx={{ marginTop: 2 }}
          startIcon={<theme.icons.upload />}
          onClick={handleOpen}
        >
          {t('edit avatar')}
          {/* When no avatar exist, trigger directly file selection */}
          {!account.avatar?.url && (
            <input
              type="file"
              accept=".png, .jpg, .jpeg, .webp"
              hidden
              onChange={(event) => {
                handleImageSelected(Array.from(event.target.files || []));
                event.target.value = ''; // allow to select the same file again
              }}
            />
          )}
        </Button>
      </Stack>

      {/* CROP MODAL */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>{t('avatar.Crop the image', { ns: 'account' })}</DialogTitle>

        <DialogContent>
          <Alert severity="info" sx={{ mb: 2, p: 2 }}>
            {t('avatar.requirements', {
              ns: 'account',
              width: MAX_WIDTH,
              height: MAX_HEIGHT,
              size: MAX_FILE_SIZE / 1024 / 1024 + ' Mo',
            })}
          </Alert>
          {imageSrc && (
            <Box position="relative" width="100%" height="400px" bgcolor="grey.900">
              <Cropper
                image={imageSrc}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                style={{
                  containerStyle: { width: '100%', height: '100%' },
                  mediaStyle: { maxWidth: '100%', maxHeight: '100%' },
                }}
              />
            </Box>
          )}
          <Stack mt={5}>
            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}
            <Typography>{t('avatar.Zoom', { ns: 'account' })}</Typography>
            <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={(_e: any, newZoom: number | number[]) => {
                setIsResized(newZoom !== 1);
                setZoom(newZoom as number);
              }}
              aria-labelledby="Zoom"
            />
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 3, justifyContent: 'flex-start' }}>
          <Button onClick={handleReset} variant="contained" color="uncolored" sx={{ mr: 'auto' }}>
            {t('cancel')}
          </Button>
          <Stack direction="row" spacing={2}>
            {formik.values.avatar && !confirmDeletion && (
              <Button onClick={() => setConfirmDeletion(true)} variant="outlined" color="uncolored">
                {t('remove')}
              </Button>
            )}
            {formik.values.avatar && confirmDeletion && (
              <Button onClick={handleRemoveImage} variant="contained" color="error">
                {t('confirm deletion')}
              </Button>
            )}
            <Button
              component="label"
              variant="contained"
              color="uncolored"
              size="small"
              sx={{ marginTop: 2 }}
              onClick={handleOpen}
            >
              {t('edit')}
              <input
                type="file"
                accept=".png, .jpg, .jpeg, .webp"
                hidden
                onChange={(event) => {
                  handleImageSelected(Array.from(event.target.files || []));
                  event.target.value = ''; // allow to select the same file again
                }}
              />
            </Button>
            <Button
              disabled={!(formik.dirty || isResized) || !formik.isValid}
              onClick={handleCrop}
              variant="contained"
              color="success"
            >
              {t('crop & save')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
