import { State, Action } from '..';

export default function eventFormReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_EVENT_FORM': {
      return {
        ...state,
        eventForm: action.payload === null ? null : { ...action.payload },
      };
    }
    case 'UNSET_EVENT_FORM': {
      return {
        ...state,
        eventForm: undefined,
      };
    }
    default:
      return state;
  }
}
