import * as React from 'react';
import { Theme, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Swiper as SwiperClass } from 'swiper/types';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Spot } from '@graphql';
import { useSession } from '@hooks';
import { Identity } from './components/common/identity';
import {
  SpotAtmosphere,
  SpotBringable,
  SpotConfiguration,
  SpotEquipment,
  SpotPayment,
  SpotService,
  SpotSuitable,
  SpotType,
} from './components/spot';

interface handleCloseDialogrops {
  handleCloseDialog: () => void;
}

export function AccountSettingsDialog(props: handleCloseDialogrops) {
  const { handleCloseDialog } = props;
  const theme = useTheme();
  const { account } = useSession();
  const { t } = useTranslation('account');
  const [activeTab, setActiveTab] = React.useState(0);
  const swiperRef = React.useRef<SwiperClass>();

  if (!account) {
    return null;
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
    if (swiperRef.current) {
      swiperRef.current.slideTo(newTab);
    }
  };

  const handleSwiperSlideChange = (swiper: any) => {
    setActiveTab(swiper.activeIndex);
  };

  const handleSwiperTouchMove = (isActive: boolean) => {
    if (swiperRef.current) {
      swiperRef.current.allowTouchMove = isActive;
    }
  };

  const renderConsumer = () => {
    return (
      <Stack spacing={10}>
        <Identity />
      </Stack>
    );
  };

  const tabSx = {
    color: (theme: Theme) => theme.palette.grey[500],
    '&.Mui-selected': {
      color: '#fff',
    },
  };

  const renderSpot = () => {
    if (!account || account.__typename !== 'Spot') {
      return null;
    }

    return (
      <>
        <Swiper
          autoHeight
          onSlideChange={handleSwiperSlideChange}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          slidesPerView={1}
          style={{ marginTop: 0, position: 'relative' }}
          initialSlide={activeTab}
        >
          {/* IDENTITY */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <Identity />
            </Stack>
          </SwiperSlide>
          {/* CONFIGURATION */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotConfiguration handleSwiperTouchMove={handleSwiperTouchMove} />
            </Stack>
          </SwiperSlide>
          {/* TYPOLOGY */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotType isActive={activeTab === 2} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* ATMOSPHERE */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotAtmosphere isActive={activeTab === 3} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* EQUIPMENT */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotEquipment isActive={activeTab === 4} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* SERVICE */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotService isActive={activeTab === 5} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* SUITABLE */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotSuitable isActive={activeTab === 6} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* PAYMENTS */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotPayment isActive={activeTab === 7} account={account as Spot} />
            </Stack>
          </SwiperSlide>
          {/* BRINGABLE */}
          <SwiperSlide>
            <Stack spacing={10} p={3}>
              <SpotBringable isActive={activeTab === 8} account={account as Spot} />
            </Stack>
          </SwiperSlide>
        </Swiper>
      </>
    );
  };

  return (
    <Stack p={3} sx={{ minHeight: 'calc(100vh - 70px)' }}>
      <DialogTitle>
        <Stack direction="row" spacing={1} alignItems="middle">
          <theme.icons.settings fontSize="large" />
          <Typography variant="h5" fontWeight="bold" width="100%">
            {t('menu.account settings')}
          </Typography>
          <theme.icons.close onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} color="disabled" />
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ backgroundColor: theme.palette.grey[800] }} />
      {account.__typename === 'Spot' && (
        <Tabs
          variant="scrollable"
          value={activeTab}
          onChange={handleTabChange}
          sx={{
            borderBottom: 1,
            borderColor: (theme: Theme) => theme.palette.grey[800],
            m: (theme: Theme) => theme.spacing(0, 2, 0, 2),
          }}
        >
          <Tab label="Identité" sx={tabSx} />
          <Tab label="Configuration" sx={tabSx} />
          <Tab label="Typologie" sx={tabSx} />
          <Tab label="Ambiance" sx={tabSx} />
          <Tab label="Equipements" sx={tabSx} />
          <Tab label="Services" sx={tabSx} />
          <Tab label="Occasions" sx={tabSx} />
          <Tab label="Paiements" sx={tabSx} />
          <Tab label="Apportable" sx={tabSx} />
          <Tab label="Horaires" sx={tabSx} />
        </Tabs>
      )}
      <DialogContent sx={{ m: 0, p: 0, overflow: 'auto' }}>
        {account.__typename === 'Consumer' && renderConsumer()}
        {account.__typename === 'Spot' && renderSpot()}
      </DialogContent>
      <DialogActions sx={{ pb: theme.spacing(3, 3, 0, 3) }}>
        <Button
          fullWidth
          onClick={handleCloseDialog}
          color="uncolored"
          variant="contained"
          startIcon={<theme.icons.close />}
        >
          {t('word.close', { ns: 'common' })}
        </Button>
      </DialogActions>
    </Stack>
  );
}
