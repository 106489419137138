import * as React from 'react';
import { Theme, alpha } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import Divider from '@mui/material/Divider';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useUpdateSpotEquipmentMutation, Spot } from '@graphql';
import { useToastError } from '@hooks';

interface RenderSwitchProps {
  equipment: keyof Omit<Spot['equipment'], '__typename' | 'id' | 'updatedAt'>;
}
interface SpotEquipmentProps {
  isActive: boolean;
  account: Spot;
}

export function SpotEquipment(props: SpotEquipmentProps) {
  const { isActive, account } = props;
  const toastError = useToastError();
  const { t } = useTranslation('cta');

  const [updateSpotTypeMutation] = useUpdateSpotEquipmentMutation();

  const initialValues = React.useMemo(() => {
    return Object.keys(account.equipment)
      .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
      .reduce((acc, key) => {
        acc[key as RenderSwitchProps['equipment']] = account.equipment[key as RenderSwitchProps['equipment']];
        return acc;
      }, {} as Record<RenderSwitchProps['equipment'], boolean>);
  }, [account.equipment]);

  const validationSchema = React.useMemo(() => {
    return yup.object(
      Object.keys(account.equipment)
        .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
        .reduce((acc, key) => {
          acc[key as RenderSwitchProps['equipment']] = yup.boolean().required();
          return acc;
        }, {} as Record<RenderSwitchProps['equipment'], yup.BooleanSchema>)
    );
  }, [account.equipment]);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (formik.dirty) {
        updateSpotTypeMutation({
          variables: { input: values },
          onCompleted: ({ updateSpotEquipment }) => {
            formik.resetForm({ values });
            toast(t('spotEquipment.toast.success', { ns: 'field' }), { type: 'success' });
          },
          onError: toastError,
        });
      }
    },
  });

  React.useEffect(() => {
    return () => {
      // trigger the mutation when the component is not anymore in view and the form is dirty
      if (isActive && formik.dirty) {
        formik.submitForm();
      }
    };
  }, [isActive, formik.dirty]);

  if (account?.__typename !== 'Spot') {
    return null;
  }

  return (
    <Stack width="100%" spacing={3}>
      <Stack
        direction="row"
        // width="100%"
        justifyContent="space-between"
        sx={{
          p: 3,
          borderRadius: (theme: Theme) => theme.borderRadius,
          backgroundColor: alpha('#fff', 0.1),
        }}
      >
        <Stack>
          <Typography fontWeight="bold">{t('spotEquipment.label', { ns: 'field' })}</Typography>
          <Typography variant="caption" color="text.secondary">
            {t('spotEquipment.caption', { ns: 'field' })}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        spacing={2}
        divider={<Divider sx={{ backgroundColor: (theme: Theme) => theme.palette.grey[800] }} />}
      >
        {Object.keys(account.equipment)
          .filter((key) => !['__typename', 'id', 'updatedAt'].includes(key))
          .map((equipment) => (
            <Stack
              key={equipment}
              spacing={2}
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="text.secondary">
                {t(`equipment.${equipment}`, { ns: 'spot' })}
              </Typography>
              <RenderSwitch equipment={equipment as RenderSwitchProps['equipment']} formik={formik} />
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
}

function RenderSwitch(props: RenderSwitchProps & { formik: any }) {
  const { equipment, formik } = props;

  return (
    <Switch
      name={equipment}
      checked={formik.values[equipment]}
      onChange={formik.handleChange}
      color={'success'}
    />
  );
}
