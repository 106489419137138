import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { loginStatusVar, useAppContext } from '@context';
import { useHandleFriendMutation } from '@graphql';
import { useSession, useToastError } from '@hooks';

export default function FriendRequestButton() {
  const { state, dispatch } = useAppContext();
  const { user, accounts } = useSession();
  const loginStatus = useReactiveVar(loginStatusVar);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuWidth, setMenuWidth] = React.useState<number | null>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation('cta');
  const [account] = accounts;

  if (!state.consumer || account.__typename !== 'Consumer') {
    return null;
  }

  const { id: accountId } = state.consumer;
  const isPending = account ? !!account.friends.sent.find(({ id }) => id === accountId) : false;
  const isFriend = account ? !!account.friends.accepted.find(({ id }) => id === accountId) : false;

  const [handleFriend] = useHandleFriendMutation({
    // variables: { accountId, action },
    onCompleted: ({ handleFriend }, clientOptions) => {
      const { action } = clientOptions?.variables || ({} as { action: 'add' | 'cancel' | 'reject' });

      if (handleFriend === true) {
        if (action === 'add') {
          dispatch({
            type: 'ADD_FRIEND',
            payload: {
              userAccountId: account.id,
              friendAccountId: accountId,
              name: state.consumer?.name as string,
              slug: state.consumer?.slug as string,
              avatar: state.consumer?.avatar as string,
            },
          });
        } else {
          dispatch({
            type: action === 'cancel' ? 'CANCEL_FRIEND' : 'REMOVE_FRIEND',
            payload: { accountId },
          });
        }
      }
    },
    onError: useToastError(),
  });

  const handleTryUnfriend = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuWidth(buttonRef.current ? buttonRef.current.offsetWidth : null);
  };

  const handleCloseUnfollow = () => {
    setAnchorEl(null);
  };

  const handleAddFriend = () => {
    if (!user) {
      return loginStatusVar({
        ...loginStatus,
        shouldLogFirst: true,
      });
    }
    handleFriend({ variables: { accountId, action: 'add' } });
  };

  const handleUnfriend = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleFriend({ variables: { accountId, action: isPending ? 'cancel' : 'remove' } });
    handleCloseUnfollow();
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Button
        ref={buttonRef}
        variant="contained"
        color={isFriend || isPending ? 'uncolored' : 'primary'}
        fullWidth
        onClick={isFriend || isPending ? handleTryUnfriend : handleAddFriend}
        endIcon={isFriend || isPending ? <theme.icons.expandMore /> : null}
      >
        {isFriend ? t('friend') : isPending ? t('awaiting acceptance') : t('ask as friend')}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        elevation={3}
        open={open}
        sx={{ width: '100%', mt: 1 }}
        onClose={handleCloseUnfollow}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          style: { width: menuWidth || '100%' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            padding: '8px 16px',
            whiteSpace: 'normal',
          }}
          color="uncolored"
          onClick={handleUnfriend}
        >
          {isPending ? t('cancel friend request') : t('remove from friends')}
        </MenuItem>
      </Menu>
    </Box>
  );
}
