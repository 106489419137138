import { alpha, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography, { TypographyProps } from '@mui/material/Typography';

interface CountSlotProps extends TypographyProps {
  title: string;
  count?: number;
  variation?: string | null;
}

interface CountSlotsProps {
  title: string;
  count?: {
    current?: number;
    previous?: number;
  };
  color: string;
}

const CountSlot = (props: CountSlotProps) => {
  const { title, count, variation } = props;
  const theme = useTheme();

  const [sign] = variation ?? '0';

  return (
    <Stack p={1} alignItems="center">
      {count !== undefined && (
        <Typography variant="body2" fontWeight="bold" fontSize={{ xs: '1rem', sm: '.8rem' }}>
          {count ?? '-'}
        </Typography>
      )}
      {variation !== undefined && (
        <Typography
          variant="body2"
          fontWeight={sign === '-' ? 'normal' : 'bold'}
          fontSize={{ xs: '.7rem', sm: '.8rem' }}
          color={
            sign === '+' ? theme.palette.success.light : sign === '-' ? theme.palette.error.main : 'inherit'
          }
        >
          {variation ?? '-'}
        </Typography>
      )}
      <Typography variant="body2" fontSize={{ xs: '.5rem', sm: '.8rem' }}>
        {title}
      </Typography>
    </Stack>
  );
};

export default function CountSlots(props: CountSlotsProps) {
  const { title, count, color } = props;
  const theme = useTheme();

  const percentageChange = count ? calculatePercentageChange(count.current ?? 0, count.previous ?? 0) : null;

  return (
    <Stack flex={1} justifyContent="space-between">
      <Stack
        p={1}
        width="100%"
        direction="row"
        justifyContent="space-between"
        sx={{ backgroundColor: alpha(color, 0.7) }}
      >
        <Typography variant="body2" fontWeight="bold" fontSize={{ xs: '.7rem', sm: '.8rem' }}>
          {title}
        </Typography>
      </Stack>
      <Stack p={1} width="100%" justifyContent="space-between" sx={{ backgroundColor: alpha(color, 0.1) }}>
        {title === 'Toujours' ? (
          <CountSlot title="courante" count={count?.current} />
        ) : (
          <>
            <CountSlot title="courante" count={count?.current} />
            <Divider sx={{ backgroundColor: theme.palette.grey[500] }} />
            <CountSlot title="précédente" count={count?.previous} />
            <Divider sx={{ backgroundColor: theme.palette.grey[500] }} />
            <CountSlot title="variation" variation={percentageChange} />
          </>
        )}
      </Stack>
    </Stack>
  );
}

function calculatePercentageChange(current: number, previous: number) {
  if (previous === 0) {
    if (current > 0) {
      // positive variation
      return `+${(current * 100).toFixed(1)}%`;
    } else if (current < 0) {
      // negative variation
      return `-${(Math.abs(current) * 100).toFixed(1)}%`;
    } else {
      return null; // no change
    }
  }

  if (current === 0 && previous > 0) {
    // negative variation
    return `-${(previous * 100).toFixed(1)}%`;
  }

  const change = ((current - previous) / previous) * 100;
  return `${change > 0 ? '+' : ''}${change.toFixed(1)}%`;
}
