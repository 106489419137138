import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check';
import Backend, { HttpBackendOptions } from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import * as yup from 'yup';
import { en, fr, es } from 'yup-locales';
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

import resources from 'locales';

const locales: Record<'fr' | 'es' | 'en', any> = { en, fr, es };

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .use(i18nextPlugin)
  // .init<HttpBackendOptions>({
  .init<LanguageDetector>({
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage'],
    },
    resources,
    fallbackLng: 'fr',
    debug: import.meta.env.MODE !== 'production',
    defaultNS: 'common',
    supportedLngs: ['en', 'es', 'fr'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

const setLocaleForYupAndMoment = (lng: string) => {
  const language = lng as 'fr' | 'en' | 'es';

  // Définir la locale de Yup
  yup.setLocale(locales[language]);

  // Définir la locale de Moment.js
  moment.locale(language);

  // Optionnel : mettre à jour l'attribut de langue du document HTML
  document.documentElement.lang = language;
};

// Définir les locales par défaut pour Yup et Moment au démarrage
setLocaleForYupAndMoment(i18n.language);

// Mettre à jour les locales lors des changements de langue
i18n.on('languageChanged', (lng) => {
  setLocaleForYupAndMoment(lng);
});

export default i18n;
