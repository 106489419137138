import * as React from 'react';
import { Typography, useTheme } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import _ from 'lodash';

import { useAppContext } from '@context';
import { useSession } from '@hooks';
import { AvatarCustom } from '@components';
import { GetConversationQuery } from '@graphql';
import { getConversationTitle } from '@utils';

interface HeaderProps {
  handleCloseDialog: () => void;
  conversation: GetConversationQuery['conversation'];
}

export function Header(props: HeaderProps) {
  const { handleCloseDialog, conversation } = props;
  const theme = useTheme();
  const { state } = useAppContext();
  const { user, account } = useSession();

  if (!user || !account || state.conversationId === null || !conversation) {
    return null;
  }

  return (
    <DialogTitle>
      <Stack direction="row" spacing={3} alignItems="center" justifyContent="space-between" mb={1}>
        {conversation.members.length > 2 ? (
          <theme.icons.people
            sx={{
              width: 40,
              height: 40,
            }}
          />
        ) : (
          <AvatarCustom
            ressource={conversation.members.find((m) => m.id !== account.id)?.avatar?.url || null}
            id={conversation.id}
            type="account"
            sx={{
              width: 40,
              height: 40,
            }}
          />
        )}
        <Stack spacing={0} alignItems="center" width="100%">
          <Typography variant="h5" fontWeight="bold" width="100%">
            {getConversationTitle(account.id, conversation)}
          </Typography>
          <Typography variant="body2" color="text.secondary" width="100%">
            {conversation.members.length === 2
              ? '@' + conversation.members.find((m) => m.id !== account.id)?.slug
              : conversation.members
                  .filter((m) => m.id !== account.id)
                  .map((m) => m.name)
                  .join(', ')}
          </Typography>
        </Stack>
        <theme.icons.close onClick={handleCloseDialog} sx={{ cursor: 'pointer' }} color="disabled" />
      </Stack>
    </DialogTitle>
  );
}
