import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { en, fr, es } from 'yup-locales';

import { useUpdateUserPreferencesMutation } from '@graphql';
import { useSession, useToastError } from '@hooks';
import { languageVar } from '@context';

const locales: Record<'fr' | 'es' | 'en', any> = { en, fr, es };

export function Language() {
  const theme = useTheme();
  const { user, refetch } = useSession();
  const { t, i18n } = useTranslation('cta');
  const [isEditing, setIsEditing] = React.useState(false);
  const toastError = useToastError();

  if (!user) {
    return null;
  }

  const languageValue = {
    fr: 'Français',
    en: 'English',
    es: 'Español',
  };

  const [updateUser] = useUpdateUserPreferencesMutation();

  const formik = useFormik({
    initialValues: { language: user.preferences.language as 'fr' | 'en' | 'es' },
    validationSchema: yup.object({
      language: yup.string().oneOf(['fr', 'en', 'es']).required(),
    }),
    onSubmit: (values) => {
      updateUser({
        variables: {
          input: values,
        },
        onCompleted() {
          formik.resetForm();
          refetch();
          setIsEditing(false);
          // languageVar(values.language);
          // yup.setLocale(locales[values.language]);
          i18n.changeLanguage(values.language);
        },
        onError: toastError,
      });
    },
  });

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  return (
    <form noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
      <Stack width="100%" spacing={3}>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Typography fontWeight="bold">{t('word.language', { ns: 'common' })}</Typography>
          <Stack spacing={1} direction="row">
            {isEditing ? (
              <>
                <Button variant="contained" color="uncolored" size="small" onClick={handleCancel}>
                  {t('cancel')}
                </Button>
                <Button
                  disabled={!formik.dirty || !formik.isValid}
                  variant="contained"
                  color="success"
                  size="small"
                  type="submit"
                >
                  {t('validate')}
                </Button>
              </>
            ) : (
              <Button variant="contained" color="uncolored" size="small" onClick={handleEdit}>
                {t('edit')}
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack>
          {isEditing ? (
            <CssFormControl fullWidth>
              <Select
                variant="filled"
                id="language"
                name="language"
                value={formik.values.language}
                onChange={formik.handleChange}
                error={formik.touched.language && Boolean(formik.errors.language)}
                MenuProps={{
                  elevation: 5,
                  sx: {
                    '& .MuiMenu-list': {
                      color: theme.palette.text.secondary,
                      backgroundColor: theme.palette.background.default,
                    },
                    '& .MuiMenu-list li:hover': {
                      color: 'white',
                      backgroundColor: alpha(theme.palette.text.secondary, 0.3),
                    },
                    '&& .Mui-selected': {
                      color: theme.palette.text.primary,
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    },
                    '&& .Mui-selected:hover': {
                      color: theme.palette.text.primary,
                      backgroundColor: alpha(theme.palette.primary.main, 0.5),
                    },
                  },
                }}
              >
                <MenuItem value={'fr'}>Français</MenuItem>
                <MenuItem value={'en'}>English</MenuItem>
                <MenuItem value={'es'}>Español</MenuItem>
              </Select>
              {formik.touched.language && formik.errors.language ? (
                <Typography variant="caption" color="error">
                  {formik.errors.language}
                </Typography>
              ) : null}
            </CssFormControl>
          ) : (
            <Typography color="text.secondary">{languageValue[formik.values.language]}</Typography>
          )}
        </Stack>
      </Stack>
    </form>
  );
}

const CssFormControl = styled(FormControl)(({ theme }) => ({
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.background.paper,
  '& label.Mui-focused, svg': {
    color: '#999999',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#434343',
    },
    '&:hover fieldset': {
      borderColor: '#666666',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#999999',
    },
  },
}));
