export function getSteps(type: 'consumer' | 'spot' | 'artist') {
  const STEP_SPOT_CHECK_IN = 'Ton spot';
  const STEP_ARTIST_CHECK_IN = "Ton profil";
  const STEP_CREATE_ACCOUNT = 'Ton compte';
  const STEP_PERSONAL_INFORMATION = 'Tes infos';

  const CAPTION_SPOT_CHECK_IN = 'Informations de base sur ton spot';
  const CAPTION_ARTIST_CHECK_IN = "Ton profil d'artiste";
  const CAPTION_CREATE_ACCOUNT = 'Renseigne les informations qui permettront de te connecter';
  const CAPTION_PERSONAL_INFORMATION = 'Dis nous en un peu plus sur toi';
  const CAPTION_SUMMARY = 'Vériﬁe les informations que tu as renseignées';

  switch (type) {
    case 'consumer':
      return {
        stepFieldValidations: [
          ["email", "password", "passwordConfirmation"],
          ["gender", "firstname", "lastname", "birthdate"]
        ],
        steps: [
          STEP_CREATE_ACCOUNT,
          STEP_PERSONAL_INFORMATION
        ],
        stepsCaptions: [
          CAPTION_CREATE_ACCOUNT,
          CAPTION_PERSONAL_INFORMATION,
          CAPTION_SUMMARY
        ],
      };
    case 'spot':
      return {
        stepFieldValidations: [
          ["email", "password", "passwordConfirmation"],
          ["gender", "firstname", "lastname", "birthdate"],
          ["siret", "accountName", "address"],
        ],
        steps: [
          STEP_CREATE_ACCOUNT,
          STEP_PERSONAL_INFORMATION,
          STEP_SPOT_CHECK_IN,
        ],
        stepsCaptions: [
          CAPTION_CREATE_ACCOUNT,
          CAPTION_PERSONAL_INFORMATION,
          CAPTION_SPOT_CHECK_IN,
          CAPTION_SUMMARY
        ],
      };
    case 'artist':
      return {
        stepFieldValidations: [
          ["email", "password", "passwordConfirmation"],
          ["gender", "firstname", "lastname", "birthdate"],
          ["accountName", "art"],
        ],
        steps: [
          STEP_CREATE_ACCOUNT,
          STEP_PERSONAL_INFORMATION,
          STEP_ARTIST_CHECK_IN,
        ],
        stepsCaptions: [
          CAPTION_CREATE_ACCOUNT,
          CAPTION_PERSONAL_INFORMATION,
          CAPTION_ARTIST_CHECK_IN,
          CAPTION_SUMMARY
        ],
      };
    default:
      throw new Error('Invalid type');
  }
}