import * as React from 'react';

import { useListHomeEventsLazyQuery, ListHomeEventsQuery } from '@graphql';
import { showBackDropVar } from '@context';
import { Sliders, Highlight } from '@components';

const FIVE_MINUTES = 1000 * 60 * 5;
const EVENTS_LAST_CALL_KEY = 'EVENTS_LAST_CALL';

export default function EventsPage() {
  const [events, setEvents] = React.useState<ListHomeEventsQuery['eventCategories'] | null>(null);

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(EVENTS_LAST_CALL_KEY);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };

  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getEvents, { loading }] = useListHomeEventsLazyQuery({
    onCompleted: ({ eventCategories }) => {
      setEvents(eventCategories ?? null);
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(EVENTS_LAST_CALL_KEY, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (loading) {
      showBackDropVar(true);
    }

    if (!events || timeSinceLastCall > FIVE_MINUTES) {
      getEvents({
        fetchPolicy: timeSinceLastCall > FIVE_MINUTES ? 'network-only' : 'cache-first',
      });
    }

    if (!loading && events) {
      showBackDropVar(false);
    }
  }, [events, lastCall]);

  if (!events) {
    return null;
  }

  return (
    <>
      <Highlight />
      <Sliders categories={events} />
    </>
  );
}
