import { State, Action } from '../';

const userReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_USER': {
      return {
        ...state,
        user: action.payload ? { ...action.payload } : null,
      };
    }
    case 'SET_CURRENT_USER_ACCOUNT':
      if (!state.user?.accounts) {
        return state;
      }

      const { accountId } = action.payload;
      const { accounts } = state.user;
      const currentAccountIdx = state.user.accounts.findIndex(({ id }) => id === accountId);
      // current account must be the first one in list
      const sortedAccounts = [
        accounts[currentAccountIdx],
        ...accounts.slice(0, currentAccountIdx),
        ...accounts.slice(currentAccountIdx + 1),
      ];
      const user = { ...state.user, accounts: sortedAccounts };
      return { ...state, user };
    case 'UNSET_USER':
      return { ...state, user: null };
    default:
      return state;
  }
};

export default userReducer;
